import {ActivityStatus} from '../../../models/activity';
import s from './index.module.scss';

type ListItemStatusProps = {
    status: ActivityStatus;
};

const ListItemStatus = ({status}: ListItemStatusProps) => {
    const statusVariants = () =>
        `${status === ActivityStatus.DRAFT && s.draft} ${
            status === ActivityStatus.COMPLETED && s.completed
        } ${status === ActivityStatus.ONGOING && s.ongoing}`;

    return <div className={`${s.status} ${statusVariants()} `}>{status}</div>;
};

export default ListItemStatus;
