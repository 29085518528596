import { useCallback, useEffect } from 'react';

export const useOnOutsideClick = (ref: any, condition: any, callback: any) => {
  const handleOutsideClick = useCallback(
    (e: { target?: any }) => {
      if (!ref.current || ref.current.contains(e.target)) return;
      callback(e);
    },
    [callback, ref]
  );

  useEffect(() => {
    if (condition) document.addEventListener('click', handleOutsideClick);

    return () => document.removeEventListener('click', handleOutsideClick);
  }, [condition, handleOutsideClick]);
};
