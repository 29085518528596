import {Modal} from '../../UI/Modal';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Input} from '../../UI/Input';

import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {initial, IWeatherConditionListTypeForm, validationSchema} from './formModel';
import {FIELD_LABELS} from '../../../constants';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {
    CreateWeatherConditionListTypeProps,
    newWeatherConditionListTypeOption,
    weatherConditionsError,
    weatherConditionsIsSubmitting,
} from '../../../store/weatherCondition/weatherCondition';

type WeatherConditionListTypeProps = {
    weatherId: string;
    closeModal: VoidFunction;
};

const WeatherConditionListTypeModal = ({weatherId, closeModal}: WeatherConditionListTypeProps) => {
    const dispatch = useAppDispatch();
    const formError = useAppSelector(weatherConditionsError);

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL} = FIELD_LABELS;

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IWeatherConditionListTypeForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: initial,
    });

    const isSubmitting = useAppSelector(weatherConditionsIsSubmitting);

    const onActionClick = (formValues: IWeatherConditionListTypeForm) => {
        const {title} = formValues;

        const payLoad: CreateWeatherConditionListTypeProps = {
            data: {title},
            weatherId,
        };

        dispatch(newWeatherConditionListTypeOption(payLoad))
            .unwrap()
            .then(() => closeModal())
            .catch(({message}) => console.log(message));
    };

    return (
        <Modal title={'New Option'}>
            <ModalBody>
                <Input
                    label="Name"
                    placeholder="Type here"
                    required
                    error={!!errors.title}
                    errorMessage={errors.title?.message}
                    {...register('title')}
                />

                {formError && <ErrorMessage message={formError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    type="submit"
                    onClick={handleSubmit(onActionClick)}
                    disabled={isSubmitting}
                    label={ADD_TO_LIST_LABEL}
                />
                <TextButton variant="Danger" label={CANCEL_LABEL} onClick={closeModal} />
            </ModalActions>
        </Modal>
    );
};

export default WeatherConditionListTypeModal;
