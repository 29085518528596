import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {useReduxData} from '../../hooks/useReduxData';
import {RootState} from '../store';
import {INoCleaningReason, INoCleaningReasonDTO} from '../../models/noCleaningReason';
import {
    createNoCleaningReason,
    getNoCleaningReasons,
    patchNoCleaningReason,
    removeNoCleaningReason,
} from '../../api/noCleaningReason';
import {getArrayIndex} from '../../utils/array_helpers';

interface INoCleaningReasonState {
    noCleaningReasons: INoCleaningReason[];
    error: string | null;
    loaded: boolean;
    isSubmitting: boolean;
}

const initialState: INoCleaningReasonState = {
    noCleaningReasons: [],
    error: null,
    loaded: false,
    isSubmitting: false,
};

export type UpdateNoCleaningReasonProps = {
    reasonId: string;
    data: INoCleaningReasonDTO;
};

export const loadNoCleaningReasons = createAsyncThunk(
    'noCleaningReason/loadΝoCleaningReasons',
    async () => await getNoCleaningReasons()
);

export const newNoCleaningReason = createAsyncThunk(
    'noCleaningReason/newΝoCleaningReasons',
    async (data: INoCleaningReasonDTO) => await createNoCleaningReason(data)
);

export const updateNoCleaningReason = createAsyncThunk(
    'noCleaningReason/updateΝoCleaningReasons',
    async ({reasonId, data}: UpdateNoCleaningReasonProps) =>
        await patchNoCleaningReason(reasonId, data)
);

export const deleteNoCleaningReason = createAsyncThunk(
    'noCleaningReason/deleteΝoCleaningReasons',
    async (reasonId: string) => await removeNoCleaningReason(reasonId)
);

export const noCleaningReasonsSlice = createSlice({
    name: 'noCleaningReason',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadNoCleaningReasons.fulfilled, (state, action) => {
                state.noCleaningReasons = action.payload;
                state.loaded = true;
                state.error = null;
            })
            .addCase(loadNoCleaningReasons.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
                state.loaded = true;
            })

            .addCase(newNoCleaningReason.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newNoCleaningReason.fulfilled, (state, action) => {
                state.isSubmitting = false;
                state.error = null;
                state.noCleaningReasons.push(action.payload);
            })
            .addCase(newNoCleaningReason.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(updateNoCleaningReason.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateNoCleaningReason.fulfilled, (state, action) => {
                const {id} = action.payload;
                const currentIndex = getArrayIndex(state.noCleaningReasons, id);

                state.isSubmitting = false;
                state.error = null;
                state.noCleaningReasons[currentIndex] = action.payload;
            })
            .addCase(updateNoCleaningReason.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(deleteNoCleaningReason.fulfilled, (state, action) => {
                const id = action.meta.arg;

                state.error = null;
                state.isSubmitting = false;
                state.noCleaningReasons = state.noCleaningReasons.filter((data) => data.id !== id);
            });
    },
});

export const {clearError} = noCleaningReasonsSlice.actions;

export const noCleaningReasons = (state: RootState) => state.noCleaningReasons.noCleaningReasons;
export const noCleaningReasonError = (state: RootState) => state.noCleaningReasons.error;
export const noCleaningReasonsLoaded = (state: RootState) => state.noCleaningReasons.loaded;
export const noCleaningReasonsIsSubmitting = (state: RootState) =>
    state.noCleaningReasons.isSubmitting;

export const useNoCleaningReasons = () =>
    useReduxData(noCleaningReasons, noCleaningReasonsLoaded, loadNoCleaningReasons);

export default noCleaningReasonsSlice.reducer;
