import {ButtonHTMLAttributes, FC, MouseEventHandler} from 'react';

interface IMenuItemProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label: string;
    closeMenuCb?: () => void;
}

export const MoreMenuItem: FC<IMenuItemProps> = ({label, closeMenuCb, onClick, ...rest}) => {
    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        closeMenuCb && closeMenuCb();
        onClick && onClick(e);
    };

    return (
        <li>
            <button {...rest} onClick={handleClick}>
                {label}
            </button>
        </li>
    );
};
