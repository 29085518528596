import {Modal} from '../../UI/Modal';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Input} from '../../UI/Input';
import {Dropdown} from '../../UI/Input/Dropdown';
import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {
    newCost,
    programsError,
    programsIsSubmitting,
    updateCost,
} from '../../../store/programs/programsSlice';
import {Controller, useForm} from 'react-hook-form';
import {ICostForm, initial, validationSchema} from './formModel';
import {yupResolver} from '@hookform/resolvers/yup';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {FIELD_LABELS} from '../../../constants';
import {IProgram} from '../../../models/program';

type CostModalProps = {
    program: IProgram;
    cost?: ICostForm;
    closeModal: (id?: string) => void;
};

const GENERAL_COST = 'general';

const CostModal = ({cost, closeModal, program}: CostModalProps) => {
    const programActivities = program.activities;
    const programId = program.id;

    const serverError = useAppSelector(programsError);

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

    const initialFormValues = cost
        ? {
              ...cost,
              activityId: !cost?.activityId ? GENERAL_COST : cost?.activityId,
          }
        : initial;

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<ICostForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialFormValues,
    });

    const dispatch = useAppDispatch();
    const isSubmitting = useAppSelector(programsIsSubmitting);

    const modalTitle = !cost ? 'New Cost' : 'Edit Cost';
    const actionButtonTitle = !cost ? ADD_TO_LIST_LABEL : SAVE_CHANGES_LABEL;

    const onActionClick = (formData: ICostForm) => {
        const {id: costId, date, activityId, name, amount} = formData;

        const costPayLoad = {
            data: {
                name,
                amount,
                date: new Date(date!),
                activityId: activityId === GENERAL_COST ? '' : activityId,
            },
            programId,
        };

        const action = !cost
            ? dispatch(newCost(costPayLoad))
            : dispatch(updateCost({...costPayLoad, costId}));

        action
            .unwrap()
            .then(({id}) => closeModal(id))
            .catch(({message}) => console.log(message));
    };

    const updatedTypes = [
        {
            id: GENERAL_COST,
            name: 'General Cost',
        },
        ...programActivities,
    ];

    const costTypesOptions = updatedTypes.map(({id, name}) => ({
        id,
        label: name,
    }));

    return (
        <Modal title={modalTitle}>
            <ModalBody>
                <Input
                    label="Description"
                    placeholder="Type here"
                    required
                    error={!!errors.name}
                    errorMessage={errors.name?.message}
                    {...register('name')}
                />
                <Input
                    label="Amount"
                    type="number"
                    placeholder="Type here"
                    required
                    error={!!errors.amount}
                    errorMessage={errors.amount?.message}
                    {...register('amount')}
                />
                <Controller
                    name="activityId"
                    control={control}
                    render={({field}) => (
                        <Dropdown
                            {...field}
                            value={initialFormValues.activityId}
                            error={!!errors.activityId}
                            errorMessage={errors.activityId?.message}
                            label="Type"
                            required
                            options={costTypesOptions}
                        />
                    )}
                />
                <Input
                    label="Date"
                    type="date"
                    placeholder="Type here"
                    required
                    error={!!errors.date}
                    errorMessage={errors.date?.message}
                    {...register('date')}
                />
                {serverError && <ErrorMessage message={serverError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleSubmit(onActionClick)}
                    label={actionButtonTitle}
                />
                <TextButton
                    type="button"
                    variant="Danger"
                    label={CANCEL_LABEL}
                    onClick={() => closeModal()}
                />
            </ModalActions>
        </Modal>
    );
};

export default CostModal;
