import {FC} from 'react';
import classes from './Spot.module.scss';
import {Icon} from '../../../UI/Icon';
import {ISpot} from '../../../../models/cleanUp';
import {Checkbox} from '../../../UI/Controls/Checkbox';
import {useAppDispatch} from '../../../../store/app-hooks';
import {spotCleanUpApprove, spotCleanUpUnApprove} from '../../../../store/cleanUp/cleanUpSlice';
import {useNotification} from '../../../../store/notification/NotificationContext';
import {FIELD_LABELS} from '../../../../constants';

interface IProps {
    spotName: string;
    cleanUpId: string;
    data: ISpot;
}

const Spot: FC<IProps> = ({spotName, cleanUpId, data}) => {
    const dispatch = useAppDispatch();
    const {addNotification} = useNotification();

    const handleApprovalStatus = () => {
        const action = data.approved ? spotCleanUpUnApprove : spotCleanUpApprove;
        const payLoad = {
            cleanUpId,
            spotId: data.id,
        };

        dispatch(action(payLoad))
            .unwrap()
            .then(() => addNotification(FIELD_LABELS.SUCCESSFUL_OPERATION, 'success'))
            .catch(() => addNotification(FIELD_LABELS.FAIL_OPERATION, 'error'));
    };

    return (
        <div className={classes.wrapperContainer}>
            <div>{spotName}</div>
            <Checkbox onClick={handleApprovalStatus} checked={data.approved} label="Approved" />
            <div className={classes.spotContainer}>
                <div className={classes.imageContainer}>
                    <p>Before</p>
                    {data.before.image_url ? (
                        <img
                            src={data.before.image_url}
                            alt="Before"
                            style={{width: 400, height: 'auto'}}
                        />
                    ) : (
                        <Icon name="ImagePlaceholder" viewbox="0 0 150 150" size="400" />
                    )}
                </div>
                <div className={classes.imageContainer}>
                    <p>After</p>
                    {data.after.image_url ? (
                        <img
                            src={data.after.image_url}
                            alt="After"
                            style={{width: 400, height: 'auto'}}
                        />
                    ) : (
                        <Icon name="ImagePlaceholder" viewbox="0 0 150 150" size="400" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Spot;
