import s from './index.module.scss';

type ListItemDetailsProps = {
    details: string[];
};

const ListItemDetails = ({details}: ListItemDetailsProps) => (
    <div className={s.details}>
        {details.map((detail) => (
            <div key={detail}>{detail}</div>
        ))}
    </div>
);

export default ListItemDetails;
