import s from '../index.module.scss';
import {GeneralResourcesConfig} from './GeneralResourcesConfig';
import {LitterBagsConfig} from './LitterBagsConfig';
import {useResources} from '../../../store/resource/resource';
import {ResourceType} from '../../../models/resource';

export const ResourcesConfigurationContent = () => {
    const [resources, resourcesLoaded] = useResources();

    const generalResources = resources?.filter(
        (resource) => resource.type === ResourceType.GENERAL
    );
    const litterBagResources = resources?.filter(
        (resource) => resource.type === ResourceType.LITTER_BAG
    );

    return (
        <div className={s.container}>
            {resourcesLoaded ? (
                <>
                    <GeneralResourcesConfig generalResources={generalResources} />
                    <LitterBagsConfig litterBagResources={litterBagResources} />
                </>
            ) : (
                <div>Fetching data...</div>
            )}
        </div>
    );
};
