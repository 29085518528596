import {IUsedResources} from '../models/cleanUp';
import {useCleanUpResources} from './useCleanUpResources';

export const useAggregateResources = (used_general_resources: IUsedResources[]) => {
    const aggregatedResources = used_general_resources.reduce((acc: IUsedResources[], val) => {
        const index = acc.findIndex((obj) => obj.resource === val.resource);

        if (index !== -1) {
            acc[index].amount += val.amount;
        } else {
            acc.push({
                resource: val.resource,
                amount: val.amount,
            });
        }

        return acc;
    }, []);

    return useCleanUpResources(aggregatedResources);
};
