import {useContext} from 'react';
import {ConfirmContext} from '../store/confirm/ConfirmationContext';

let resolveCallback: (v: boolean) => void;
const useConfirm = () => {
    const [confirmState, dispatch] = useContext(ConfirmContext);

    const onConfirm = () => {
        dispatch({
            type: 'HIDE',
        });

        resolveCallback(true);
    };

    const onCancel = () => {
        dispatch({
            type: 'HIDE',
        });

        resolveCallback(false);
    };

    const confirm = (title: string, message: string) => {
        dispatch({
            type: 'SHOW',
            value: {title, message},
        });

        return new Promise((res: (v: boolean) => void, _) => {
            resolveCallback = res;
        });
    };

    return {
        confirm,
        confirmState,
        onConfirm,
        onCancel,
    };
};

export default useConfirm;
