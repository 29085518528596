import {IUser, IUserDTO, Role} from '../../models/user';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    createUser,
    getUsers,
    patchUser,
    removeUser,
    setUserActive,
    setUserInActive,
} from '../../api/user';
import {RootState} from '../store';
import {useReduxData} from '../../hooks/useReduxData';
import {getArrayIndex} from '../../utils/array_helpers';
import {addUserToProgram, addUserToTeam} from '../programs/programsSlice';

export interface UpdateUserProps {
    userId: string;
    data: Omit<IUserDTO, 'email'>;
}

interface IUserState {
    usersData: IUser[];
    error: string | null;
    loaded: boolean;
    isSubmitting: boolean;
}

const initialState: IUserState = {
    usersData: [],
    error: null,
    loaded: false,
    isSubmitting: false,
};

export const loadUsers = createAsyncThunk('users/loadUsers', async () => await getUsers());

export const newUser = createAsyncThunk('users/newUser', async (data: IUserDTO, {dispatch}) => {
    const result = await createUser(data);
    if (data.programId && !data.teamId) {
        dispatch(addUserToProgram({programId: data.programId, userId: result.id}));
    }

    if (data.programId && data.teamId) {
        dispatch(
            addUserToTeam({programId: data.programId, teamId: data.teamId, userId: result.id})
        );
    }
    return result;
});

export const updateUser = createAsyncThunk(
    'users/updateUser',
    async ({userId, data}: UpdateUserProps) => await patchUser(userId, data)
);

export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async (userId: string) => await removeUser(userId)
);

export const activateUser = createAsyncThunk(
    'users/activateUser',
    async (userId: string) => await setUserActive(userId)
);

export const deActivateUser = createAsyncThunk(
    'users/deActivateUser',
    async (userId: string) => await setUserInActive(userId)
);

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadUsers.fulfilled, (state, action) => {
                state.usersData = action.payload;
                state.loaded = true;
                state.error = null;
            })
            .addCase(loadUsers.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
                state.loaded = true;
            })

            .addCase(newUser.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newUser.fulfilled, (state, action) => {
                state.usersData.push(action.payload);
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(newUser.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
                state.isSubmitting = false;
            })

            .addCase(updateUser.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                const {userId} = action.meta.arg;
                const currentIndex = getArrayIndex(state.usersData, userId);

                state.isSubmitting = false;
                state.error = null;
                state.usersData[currentIndex] = action.payload;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(deleteUser.fulfilled, (state, action) => {
                const id = action.meta.arg;

                state.error = null;
                state.isSubmitting = false;
                state.usersData = state.usersData.filter((data) => data.id !== id);
            })

            .addCase(activateUser.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(activateUser.fulfilled, (state, action) => {
                const id = action.meta.arg;
                const currentIndex = getArrayIndex(state.usersData, id);

                state.isSubmitting = false;
                state.error = null;
                state.usersData[currentIndex].is_active = true;
            })
            .addCase(activateUser.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(deActivateUser.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(deActivateUser.fulfilled, (state, action) => {
                const id = action.meta.arg;
                const currentIndex = getArrayIndex(state.usersData, id);

                state.isSubmitting = false;
                state.error = null;
                state.usersData[currentIndex].is_active = false;
            })
            .addCase(deActivateUser.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            });
    },
});

export const {clearError} = userSlice.actions;

export const users = (state: RootState) => state.users.usersData;
export const userError = (state: RootState) => state.users.error;
export const usersLoaded = (state: RootState) => state.users.loaded;
export const usersIsSubmitting = (state: RootState) => state.users.isSubmitting;

export const useUserData = () => useReduxData(users, usersLoaded, loadUsers);

export const useCollectorData = () => {
    const [users, loaded] = useUserData();

    const colectors = users.filter((user) => user.role === Role.collector);

    return [colectors, loaded] as const;
};

export default userSlice.reducer;
