import {FC} from 'react';
import {View, StyleSheet} from '@react-pdf/renderer';
import TableRow from './TableRow';

const styles = StyleSheet.create({
    infoRow: {display: 'flex', flexDirection: 'column'},
    infoGrid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
});

interface IProps {
    title: string;
    titleInfo?: string;
    rows: {title: string; info: string; smallFont?: true}[];
    mode: 'rows' | 'grid';
}

const Table: FC<IProps> = ({title, titleInfo, mode = 'rows', rows}) => {
    return (
        <>
            <TableRow title={title} subTitle={titleInfo} header />
            <View style={mode === 'rows' ? styles.infoRow : styles.infoGrid}>
                {rows.map((row) => (
                    <TableRow
                        key={title}
                        title={row.title}
                        subTitle={row.info}
                        half={mode === 'grid'}
                    />
                ))}
            </View>
        </>
    );
};

export default Table;
