import {Modal} from '../../UI/Modal';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Input} from '../../UI/Input';

import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {initial, INoCleaningReasonForm, validationSchema} from './formModel';
import {FIELD_LABELS} from '../../../constants';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {
    newNoCleaningReason,
    noCleaningReasonError,
    noCleaningReasonsIsSubmitting,
    updateNoCleaningReason,
} from '../../../store/noCleaningReason/noCleaningReasonSlice';

type NoCleaningReasonModalProps = {
    noCleaningReason?: INoCleaningReasonForm;
    closeModal: VoidFunction;
};

const NoCleaningReasonModal = ({noCleaningReason, closeModal}: NoCleaningReasonModalProps) => {
    const dispatch = useAppDispatch();
    const formError = useAppSelector(noCleaningReasonError);

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

    const initialFormValue: INoCleaningReasonForm = noCleaningReason ? noCleaningReason : initial;

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<INoCleaningReasonForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialFormValue,
    });

    const isSubmitting = useAppSelector(noCleaningReasonsIsSubmitting);

    const modalTitle = !noCleaningReason ? 'New Reason' : 'Edit Reason';
    const actionButtonTitle = !noCleaningReason ? ADD_TO_LIST_LABEL : SAVE_CHANGES_LABEL;

    const onActionClick = (formValues: INoCleaningReasonForm) => {
        const {name, id: reasonId} = formValues;

        const action = !noCleaningReason
            ? dispatch(newNoCleaningReason({name}))
            : dispatch(updateNoCleaningReason({data: {name}, reasonId}));

        action
            .unwrap()
            .then(() => closeModal())
            .catch(({message}) => console.log(message));
    };

    return (
        <Modal title={modalTitle}>
            <ModalBody>
                <Input
                    label="Name"
                    placeholder="Type here"
                    required
                    error={!!errors.name}
                    errorMessage={errors.name?.message}
                    {...register('name')}
                />
                {formError && <ErrorMessage message={formError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    type="submit"
                    onClick={handleSubmit(onActionClick)}
                    disabled={isSubmitting}
                    label={actionButtonTitle}
                />
                <TextButton variant="Danger" label={CANCEL_LABEL} onClick={closeModal} />
            </ModalActions>
        </Modal>
    );
};

export default NoCleaningReasonModal;
