import apiClient from './client';
import {
    IAddProgramToResourceDTO,
    IRemoveProgramFmResourceDTO,
    IResource,
    ICreateResourceDTO,
    IUpdateResourceDTO,
} from '../models/resource';

export const getResources = async (): Promise<IResource[]> => {
    try {
        const response = await apiClient.get<IResource[]>('/resource');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createNewResource = async (data: ICreateResourceDTO): Promise<IResource> => {
    try {
        const response = await apiClient.post<IResource>('/resource', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchResource = async (
    resourceId: string,
    data: IUpdateResourceDTO
): Promise<IResource> => {
    try {
        const response = await apiClient.patch<IResource>(`/resource/${resourceId}`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeResource = async (resourceId: string): Promise<void> => {
    try {
        await apiClient.delete<IResource>(`/resource/${resourceId}`);
    } catch (error) {
        throw error;
    }
};

export const addProgramToRersouce = async (
    id: string,
    data: IAddProgramToResourceDTO
): Promise<IResource> => {
    try {
        const result = await apiClient.post<IResource>(`/resource/${id}/add-program`, data);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const removeProgramFmRersouce = async (
    type_id: string,
    data: IRemoveProgramFmResourceDTO
): Promise<IResource> => {
    try {
        const result = await apiClient.post<IResource>(`/resource/${type_id}/remove-program`, data);
        return result.data;
    } catch (error) {
        throw error;
    }
};
