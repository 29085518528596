import apiClient from './client';
import {
    IWeatherCondition,
    IWeatherConditionDTO,
    IAddProgramToWeatherConditionDTO,
    IRemoveProgramToWeatherConditionDTO,
} from '../models/weatherCondition';

export const getWeatherConditions = async (): Promise<IWeatherCondition[]> => {
    try {
        const response = await apiClient.get<IWeatherCondition[]>('/weather-conditions');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createWeatherCondition = async (
    data: IWeatherConditionDTO
): Promise<IWeatherCondition> => {
    try {
        const response = await apiClient.post<IWeatherCondition>('/weather-conditions', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchWeatherCondition = async (
    weatherId: string,
    data: Pick<IWeatherConditionDTO, 'title' | 'unit'>
): Promise<IWeatherCondition> => {
    try {
        const response = await apiClient.patch<IWeatherCondition>(
            `/weather-conditions/${weatherId}`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeWeatherCondition = async (weatherId: string): Promise<void> => {
    try {
        await apiClient.delete(`/weather-conditions/${weatherId}`);
    } catch (error) {
        throw error;
    }
};

export const createWeatherConditionListTypeOption = async (
    weatherId: string,
    data: Pick<IWeatherConditionDTO, 'title'>
): Promise<IWeatherCondition> => {
    try {
        const response = await apiClient.post<IWeatherCondition>(
            `/weather-conditions/${weatherId}/option`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeWeatherConditionListTypeOption = async (
    weatherId: string,
    optionId: string
): Promise<void> => {
    try {
        await apiClient.delete(`/weather-conditions/${weatherId}/option/${optionId}`);
    } catch (error) {
        throw error;
    }
};

export const addProgramToWeatherCondition = async (
    condition_id: string,
    data: IAddProgramToWeatherConditionDTO
): Promise<IWeatherCondition> => {
    try {
        const result = await apiClient.post<IWeatherCondition>(
            `/weather-conditions/${condition_id}/add-program`,
            data
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const removeProgramFmWeatherCondition = async (
    condition_id: string,
    data: IRemoveProgramToWeatherConditionDTO
): Promise<IWeatherCondition> => {
    try {
        const result = await apiClient.post<IWeatherCondition>(
            `/weather-conditions/${condition_id}/remove-program`,
            data
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};
