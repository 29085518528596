import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {useReduxData} from '../../hooks/useReduxData';
import {RootState} from '../store';
import {
    IAddUserToProgramDto,
    IAddUserToTeamDto,
    IProgram,
    IProgramDto,
    IProgramWithUsers,
} from '../../models/program';
import {
    createNewActivity,
    createNewCost,
    createNewProgram,
    getProgramData,
    patchActivity,
    patchCost,
    patchProgram,
    removeActivity,
    removeCost,
    removeProgram,
    createTeam,
    patchTeam,
    removeTeam,
    addUsersToProgram as addUsersToProgramApi,
    removeUserFromProgram as removeUserFromProgramApi,
    addUsersToTeam as addUsersToTeamApi,
    removeUserFromTeam as removeUserFromTeamApi,
} from '../../api/programs';
import {getArrayIndex} from '../../utils/array_helpers';
import {IActivityDTO} from '../../models/activity';
import {ICostDTO} from '../../models/cost';
import {useUserData} from '../user/userSlice';
import {IUser} from '../../models/user';
import {ITeamDto} from '../../models/team';

interface IProgramsState {
    programsData: IProgram[];
    error: string | null;
    loaded: boolean;
    isSubmitting: boolean;
}

const initialState: IProgramsState = {
    programsData: [],
    error: null,
    loaded: false,
    isSubmitting: false,
};

type NewActivityPayload = {programId: string; data: IActivityDTO};
type UpdateActivityPayload = NewActivityPayload & {activityId: string};
type NewCostPayload = {programId: string; data: ICostDTO};
type UpdateCostPayload = {programId: string; costId: string; data: ICostDTO};
type NewTeamPaylod = {programId: string; data: ITeamDto};
type UpdateTeamPaylod = {programId: string; teamId: string; data: ITeamDto};
type AddUserToProgramPayload = {programId: string; userId: string};
type AddUserToTeamPayload = {programId: string; teamId: string; userId: string};
type AddUsersToProgramPayload = {programId: string; data: IAddUserToProgramDto};
type RemoveUserFmProgramPayload = {programId: string; userId: string};
type AddUsersToTeamPayload = {programId: string; teamId: string; data: IAddUserToTeamDto};
type RemoveUserFmTeamPayload = {programId: string; teamId: string; userId: string};

export const loadPrograms = createAsyncThunk(
    'programs/loadPrograms',
    async () => await getProgramData()
);

export const newProgram = createAsyncThunk(
    'programs/newProgram',
    async (data: IProgramDto) => await createNewProgram(data)
);

export const updateProgram = createAsyncThunk(
    'programs/updateProgram',
    async (data: IProgramDto) => await patchProgram(data)
);

export const deleteProgram = createAsyncThunk(
    'programs/deleteProgram',
    async (id: string) => await removeProgram(id)
);

export const newActivity = createAsyncThunk(
    'programs/newActivity',
    async ({programId, data}: NewActivityPayload) => await createNewActivity(programId, data)
);

export const updateActivity = createAsyncThunk(
    'programs/updateActivity',
    async ({programId, activityId, data}: UpdateActivityPayload) =>
        await patchActivity(programId, activityId, data)
);

export const deleteActivity = createAsyncThunk(
    'programs/deleteActivity',
    async ({programId, activityId}: Omit<UpdateActivityPayload, 'data'>) =>
        await removeActivity(programId, activityId)
);

export const newCost = createAsyncThunk(
    'programs/newCost',
    async ({programId, data}: NewCostPayload) => await createNewCost(programId, data)
);

export const updateCost = createAsyncThunk(
    'programs/updateCost',
    async ({programId, costId, data}: UpdateCostPayload) => await patchCost(programId, costId, data)
);

export const deleteCost = createAsyncThunk(
    'programs/deleteCost',
    async ({programId, costId}: Omit<UpdateCostPayload, 'data'>) =>
        await removeCost(programId, costId)
);

export const newTeam = createAsyncThunk(
    'programs/createTeam',
    async ({programId, data}: NewTeamPaylod) => await createTeam(programId, data)
);

export const updateTeam = createAsyncThunk(
    'programs/updateTeam',
    async ({programId, teamId, data}: UpdateTeamPaylod) => await patchTeam(programId, teamId, data)
);

export const deleteTeam = createAsyncThunk(
    'programs/deleteTeam',
    async ({programId, teamId}: Omit<UpdateTeamPaylod, 'data'>) =>
        await removeTeam(programId, teamId)
);

export const addUsersToProgram = createAsyncThunk(
    'programs/addUsersToProgram',
    async ({programId, data}: AddUsersToProgramPayload) =>
        await addUsersToProgramApi(programId, data)
);

export const removeUserFromProgram = createAsyncThunk(
    'programs/removeUsersToProgram',
    async ({programId, userId}: RemoveUserFmProgramPayload) =>
        await removeUserFromProgramApi(programId, userId)
);

export const addUsersToTeam = createAsyncThunk(
    'programs/addUsersToTeam',
    async ({programId, teamId, data}: AddUsersToTeamPayload) =>
        await addUsersToTeamApi(programId, teamId, data)
);

export const removeUserFromTeam = createAsyncThunk(
    'programs/removeUserTeam',
    async ({programId, teamId, userId}: RemoveUserFmTeamPayload) =>
        await removeUserFromTeamApi(programId, teamId, userId)
);

export const programsSlice = createSlice({
    name: 'programs',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
        addUserToProgram: (state, action: PayloadAction<AddUserToProgramPayload>) => {
            const {programId, userId} = action.payload;
            const programIndex = getArrayIndex(state.programsData, programId);
            state.programsData[programIndex].users.push(userId);
        },
        addUserToTeam: (state, action: PayloadAction<AddUserToTeamPayload>) => {
            const {programId, teamId, userId} = action.payload;
            const programIndex = getArrayIndex(state.programsData, programId);
            const teamIndex = getArrayIndex(state.programsData[programIndex].teams, teamId);

            state.programsData[programIndex].teams[teamIndex].users?.push(userId);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadPrograms.fulfilled, (state, action) => {
                state.programsData = action.payload;
                state.loaded = true;
                state.error = null;
            })
            .addCase(loadPrograms.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
                state.loaded = true;
            })
            .addCase(newProgram.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newProgram.fulfilled, (state, action) => {
                state.isSubmitting = false;
                state.error = null;
                state.programsData.push(action.payload);
            })
            .addCase(newProgram.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(updateProgram.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateProgram.fulfilled, (state, action) => {
                const {id} = action.payload;
                const currentIndex = getArrayIndex(state.programsData, id);

                state.isSubmitting = false;
                state.error = null;
                state.programsData[currentIndex] = action.payload;
            })
            .addCase(updateProgram.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(deleteProgram.fulfilled, (state, action) => {
                const id = action.meta.arg;

                state.error = null;
                state.isSubmitting = false;
                state.programsData = state.programsData.filter((data) => data.id !== id);
            })

            .addCase(newActivity.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newActivity.fulfilled, (state, action) => {
                const {programId} = action.meta.arg;
                const currentIndex = getArrayIndex(state.programsData, programId!);

                state.programsData[currentIndex].activities.push(action.payload);
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(newActivity.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(updateActivity.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateActivity.fulfilled, (state, action) => {
                const {programId, activityId} = action.meta.arg;

                const programIndex = getArrayIndex(state.programsData, programId!);
                const activityIndex = getArrayIndex(
                    state.programsData[programIndex].activities,
                    activityId!
                );

                state.programsData[programIndex].activities[activityIndex] = action.payload;
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(updateActivity.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(deleteActivity.fulfilled, (state, action) => {
                const {programId, activityId} = action.meta.arg;

                const programIndex = getArrayIndex(state.programsData, programId!);
                const activityIndex = getArrayIndex(
                    state.programsData[programIndex].activities,
                    activityId!
                );

                state.programsData[programIndex].activities.splice(activityIndex, 1);
                state.isSubmitting = false;
                state.error = null;
            })

            .addCase(newCost.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newCost.fulfilled, (state, action) => {
                const {programId} = action.meta.arg;
                const currentIndex = getArrayIndex(state.programsData, programId!);

                state.programsData[currentIndex].costs.push(action.payload);
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(newCost.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(updateCost.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateCost.fulfilled, (state, action) => {
                const {programId, costId} = action.meta.arg;

                const programIndex = getArrayIndex(state.programsData, programId!);
                const costIndex = getArrayIndex(state.programsData[programIndex].costs, costId);

                state.programsData[programIndex].costs[costIndex] = action.payload;
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(updateCost.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(deleteCost.fulfilled, (state, action) => {
                const {programId, costId} = action.meta.arg;

                const programIndex = getArrayIndex(state.programsData, programId!);
                const activityIndex = getArrayIndex(state.programsData[programIndex].costs, costId);

                state.programsData[programIndex].costs.splice(activityIndex, 1);
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(newTeam.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newTeam.fulfilled, (state, action) => {
                const {programId} = action.meta.arg;
                const currentIndex = getArrayIndex(state.programsData, programId!);

                state.programsData[currentIndex].teams.push(action.payload);
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(newTeam.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(updateTeam.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateTeam.fulfilled, (state, action) => {
                const {programId, teamId} = action.meta.arg;

                const programIndex = getArrayIndex(state.programsData, programId!);
                const teamIndex = getArrayIndex(state.programsData[programIndex].teams, teamId);

                state.programsData[programIndex].teams[teamIndex] = action.payload;
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(updateTeam.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(deleteTeam.fulfilled, (state, action) => {
                const {programId, teamId} = action.meta.arg;

                const programIndex = getArrayIndex(state.programsData, programId!);
                const teamIndex = getArrayIndex(state.programsData[programIndex].teams, teamId);

                state.programsData[programIndex].teams.splice(teamIndex, 1);
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(addUsersToProgram.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(addUsersToProgram.fulfilled, (state, action) => {
                const {programId} = action.meta.arg;

                const programIndex = getArrayIndex(state.programsData, programId);

                state.programsData[programIndex] = action.payload;
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(addUsersToProgram.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(removeUserFromProgram.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(removeUserFromProgram.fulfilled, (state, action) => {
                const {programId} = action.meta.arg;

                const programIndex = getArrayIndex(state.programsData, programId);

                state.programsData[programIndex] = action.payload;
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(removeUserFromProgram.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(addUsersToTeam.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(addUsersToTeam.fulfilled, (state, action) => {
                const {programId, teamId} = action.meta.arg;

                const programIndex = getArrayIndex(state.programsData, programId);
                const teamIndex = getArrayIndex(state.programsData[programIndex].teams, teamId);

                state.programsData[programIndex].teams[teamIndex] = action.payload;
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(addUsersToTeam.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(removeUserFromTeam.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(removeUserFromTeam.fulfilled, (state, action) => {
                const {programId, teamId} = action.meta.arg;

                const programIndex = getArrayIndex(state.programsData, programId);
                const teamIndex = getArrayIndex(state.programsData[programIndex].teams, teamId);

                state.programsData[programIndex].teams[teamIndex] = action.payload;
                state.isSubmitting = false;
                state.error = null;
            })
            .addCase(removeUserFromTeam.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            });
    },
});

export const {clearError, addUserToProgram, addUserToTeam} = programsSlice.actions;

export const programs = (state: RootState) => state.programs.programsData;
export const programsError = (state: RootState) => state.programs.error;
export const programsLoaded = (state: RootState) => state.programs.loaded;
export const programsIsSubmitting = (state: RootState) => state.programs.isSubmitting;

export const useProgramsData = () => useReduxData(programs, programsLoaded, loadPrograms);

export const useUserProgramsData = (userId: string) => {
    const [programs, loading] = useProgramsData();

    const userPrograms = programs.filter(
        (p) => p.users.includes(userId) || p.teams.some((t) => t.users?.includes(userId))
    );

    return [userPrograms, loading] as const;
};

export const useProgramWithUsers = (programId?: string) => {
    const [programs, programsLoading] = useProgramsData();
    const [users, usersLoading] = useUserData();

    const programIndex = getArrayIndex(programs, programId ?? '');

    const program: IProgramWithUsers | null = programs[programIndex]
        ? {
              ...programs[programIndex],
              users: programs[programIndex].users
                  .map((userId) => users.find((user) => user.id === userId))
                  .filter((user) => !!user) as IUser[],
              teams: programs[programIndex].teams.map((team) => ({
                  ...team,
                  users: team.users
                      ?.map((userId) => users.find((user) => user.id === userId))
                      .filter((user) => !!user) as IUser[],
              })),
          }
        : null;

    return [program, programsLoading || usersLoading] as const;
};

export default programsSlice.reducer;
