import {addMonths, addYears, subMonths, subYears} from 'date-fns';
import {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Calendar from '../../components/UI/Calendar/Calendar';
import {useCalendarItems} from '../../hooks/useCalendarItems';
import {PageHeader, PageWrapper} from '../../Layout';

import {dateToMonthString, dateToString} from '../../utils/date_helpers';

export const DailyReports = () => {
    const navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const monthString = params.get('date') ?? dateToMonthString(new Date());
    const selectedDate = new Date(monthString);

    const [calendarItems] = useCalendarItems(monthString);

    useEffect(() => {
        if (!params.get('date')) {
            setParams({date: dateToMonthString(new Date())});
        }
    });

    const setDate = (date: Date) => {
        setParams({date: dateToMonthString(date)});
    };

    const handleDayClicked = (day: Date) => {
        navigate(`/daily-reports/day?date=${dateToString(day)}`);
    };

    return (
        <PageWrapper>
            <PageHeader title="Daily Reports" />
            <Calendar
                items={calendarItems}
                selectedDate={selectedDate}
                onDaySelect={handleDayClicked}
                onNextMonth={() => setDate(addMonths(selectedDate, 1))}
                onPrevMonth={() => setDate(subMonths(selectedDate, 1))}
                onNextYear={() => setDate(addYears(selectedDate, 1))}
                onPrevYear={() => setDate(subYears(selectedDate, 1))}
            />
        </PageWrapper>
    );
};
