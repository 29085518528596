import {createBrowserRouter, Navigate, Outlet} from 'react-router-dom';
import PrivateRoute from '../components/Utils/PrivateRoute';
import {Navbar} from '../Navbar/Primary';
import {DataPageContent} from '../PageContent/DataPage';
import {LocationConfigurationContent} from '../PageContent/DataPage/LocationConfiguration';
import {NoActivitiesConfigurationContent} from '../PageContent/DataPage/NoActivitiesConfiguration';
import {ResourcesConfigurationContent} from '../PageContent/DataPage/ResourcesConfiguration';
import {WasteConfigurationContent} from '../PageContent/DataPage/WasteConfiguration';
import {WeatherConfigurationContent} from '../PageContent/DataPage/WeatherConfiguration';
import LoginPage from '../PageContent/Auth/LoginPage';
import {ProgramConfigurationContent} from '../PageContent/ProgramConfigurationPage';
import {ProgramActivitiesContent} from '../PageContent/ProgramConfigurationPage/ProgramActivities';
import {ProgramCosts} from '../PageContent/ProgramConfigurationPage/ProgramCosts';
import {ProgramDataConfigurationContent} from '../PageContent/ProgramConfigurationPage/ProgramDataConfiguration';
import {ProgramDetailsContent} from '../PageContent/ProgramConfigurationPage/ProgramDetails';
import {ProgramParticipantsContent} from '../PageContent/ProgramConfigurationPage/ProgramParticipants';
import {ProgramsPage} from '../PageContent/ProgramsPage';
import {UsersPage} from '../PageContent/UsersPage';
import ActionsPage from '../PageContent/Auth/ActionsPage';
import {Role} from '../models/user';
import {DailyReports} from '../PageContent/DailyReports/DailyReports';
import {DailyReportsDay} from '../PageContent/DailyReports/DailyReportsDay';
import {Index} from '../PageContent/DailyReports/DailyReportsCleanUp';
import PasswordResetPage from '../PageContent/Auth/PasswordReset';

export const router = createBrowserRouter([
    {index: true, element: <Navigate to="programs" replace />},
    {
        path: 'auth',
        element: <Outlet />,
        children: [
            {path: 'login', element: <LoginPage />},
            {path: 'password-reset', element: <PasswordResetPage />},
            {path: 'action', element: <ActionsPage />},
        ],
    },
    {
        path: '/',
        element: (
            <PrivateRoute roles={[Role.admin, Role.director]}>
                <Navbar />
                <Outlet />
            </PrivateRoute>
        ),
        children: [
            {
                path: 'programs',
                element: <ProgramsPage />,
            },
            {
                path: 'programs/:programId',
                element: <ProgramConfigurationContent />,
                children: [
                    {index: true, element: <Navigate to="general" replace />},
                    {path: 'general', element: <ProgramDetailsContent />},
                    {path: 'activities', element: <ProgramActivitiesContent />},
                    {path: 'participants', element: <ProgramParticipantsContent />},
                    {path: 'data-config', element: <ProgramDataConfigurationContent />},
                    {
                        path: 'costs',
                        element: (
                            <PrivateRoute roles={[Role.director]} redirectTo="../general">
                                <ProgramCosts />
                            </PrivateRoute>
                        ),
                    },
                ],
            },
            {path: 'users', element: <UsersPage />},
            {
                path: 'data-config',
                element: <DataPageContent />,
                children: [
                    {index: true, element: <Navigate to="waste" replace />},
                    {path: 'waste', element: <WasteConfigurationContent />},
                    {path: 'resources', element: <ResourcesConfigurationContent />},
                    {path: 'location', element: <LocationConfigurationContent />},
                    {path: 'weather-conditions', element: <WeatherConfigurationContent />},
                    {path: 'no-activities', element: <NoActivitiesConfigurationContent />},
                ],
            },
            {path: 'daily-reports', element: <DailyReports />},
            {path: 'daily-reports/day', element: <DailyReportsDay />},
            {path: 'daily-reports/clean-up/:cleanUpId', element: <Index />},
            // {path: 'test-page', element: <TestPage />},
        ],
    },
]);
