import {ButtonHTMLAttributes, FC} from 'react';
import {Icon} from '../../Icon';
import s from '../index.module.scss';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label: string;
    transparent?: boolean;
    readOnly?: boolean;
    customClassName?: any;
    clickable?: boolean;
    onChipClick?: VoidFunction;
}

export const RemovableChip: FC<IProps> = ({
    label,
    transparent = false,
    readOnly = false,
    clickable = false,
    customClassName,
    onChipClick,
    ...rest
}) => {
    return (
        <div
            onClick={onChipClick}
            className={`$ ${s.container} ${s.removable} ${transparent ? s.transparent : s.solid} ${
                readOnly && s.readOnly
            } ${clickable && s.clickable} `}
        >
            {label}
            <button {...rest}>
                {!readOnly ? <Icon name="Close" variant="onAccent" size="20" /> : null}
            </button>
        </div>
    );
};
