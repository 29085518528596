import {FC, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {useNotification} from '../../../store/notification/NotificationContext';

import s from './index.module.scss';

interface IModalProps {
    children: React.ReactNode;
    wrapperId: string;
}

export const ModalPortal: FC<IModalProps> = ({children, wrapperId}) => {
    const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

    const createWrapperAndAppendToBody = (elementId: string) => {
        const element = document.createElement('div');
        element.setAttribute('id', elementId);
        document.body.appendChild(element);
        return element;
    };

    useLayoutEffect(() => {
        let element = document.getElementById(wrapperId) as HTMLElement;
        let portalCreated = false;

        if (!element) {
            element = createWrapperAndAppendToBody(wrapperId);
            portalCreated = true;
        }

        setPortalElement(element);

        return () => {
            if (portalCreated && element.parentNode) {
                element.parentNode.removeChild(element);
            }
        };
    }, [wrapperId]);

    if (!portalElement) return null;

    return createPortal(children, portalElement);
};

interface IModalBodyProps {
    children: React.ReactNode;
}

export const ModalBody: FC<IModalBodyProps> = ({children}) => (
    <div className={s.modalBody}>{children}</div>
);

interface IModalActionsProps {
    children: React.ReactNode;
}

export const ModalActions: FC<IModalActionsProps> = ({children}) => (
    <div className={s.modalActions}>{children}</div>
);

export type NotificationVariant = 'error' | 'info' | 'success';

interface ISnackbarProps {
    id: number;
    variant: NotificationVariant;
    message: string;
}

export const NotificationSnackbar: FC<ISnackbarProps> = ({id, variant, message}) => {
    const {removeNotification} = useNotification();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const remove = useCallback(removeNotification, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            remove(id);
        }, 4000);

        return () => {
            clearTimeout(timer);
        };
    }, [id, remove]);

    const getNotificationVariant = () =>
        `${variant === 'error' && s.error} ${variant === 'info' && s.info} ${
            variant === 'success' && s.success
        }`;

    return <li className={`${s.snackbar} ${getNotificationVariant()}`}>{message}</li>;
};
