import s from './index.module.scss';
import Logo from '../../assets/images/logo.svg';
import {LINKS} from '../../assets/utilities/navigation';
import {Link, NavLink} from 'react-router-dom';
import {Icon} from '../../components/UI/Icon';

export const Navbar = () => {
    return (
        <>
            <nav className={s.container}>
                <Link to="/" className={s.logo}>
                    <img src={Logo} alt="App Logo" />
                </Link>
                <ul className={s.navLinks}>
                    {LINKS.map((link) => (
                        <li key={link.url} className={s.link}>
                            <NavLink
                                to={link.url}
                                className={({isActive}) => (isActive ? s.active : undefined)}
                            >
                                <Icon name={link.icon} variant="onAccent" />
                                {link.name}
                            </NavLink>
                        </li>
                    ))}
                </ul>
                <div className={s.link}>
                    <a
                        href={process.env.REACT_APP_SHINY_URL}
                        className={s.external}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Icon name="ShinyApp" variant="onAccent" size="20" />
                        Shiny App
                    </a>
                </div>
            </nav>
        </>
    );
};
