import {useCallback, useMemo} from 'react';
import {
    IPDFPCleanUp,
    IPDFReportData,
    IPDFUsedResources,
    IPDFWasteTypeWeight,
} from '../models/pdfReport';
import {useCleanUpByDate} from '../store/cleanUp/cleanUpSlice';
import useGroupCleanUpsByProgram from './useGroupCleanUpsByProgram';
import {useWasteTypesData} from '../store/wasteType/wasteTypeSlice';
import {ICleanUp, isResourceGeneral} from '../models/cleanUp';
import {useResources} from '../store/resource/resource';
import {useProgramsData} from '../store/programs/programsSlice';
import {useUserData} from '../store/user/userSlice';

export const usePdfReportData = (date: string) => {
    const [cleanUps, loadedCleanUps] = useCleanUpByDate(date);
    const [groupedCleanUps, groupedCleanUpsLoaded] = useGroupCleanUpsByProgram(cleanUps);
    const [programs, programsLoaded] = useProgramsData();
    const [users, usersLoaded] = useUserData();
    const [wasteTypes, wasteTypesLoaded] = useWasteTypesData();
    const [resources, resourcesDataLoaded] = useResources();

    const loaded = useMemo(
        () =>
            loadedCleanUps &&
            groupedCleanUpsLoaded &&
            wasteTypesLoaded &&
            resourcesDataLoaded &&
            programsLoaded &&
            usersLoaded,
        [
            loadedCleanUps,
            groupedCleanUpsLoaded,
            wasteTypesLoaded,
            resourcesDataLoaded,
            programsLoaded,
            usersLoaded,
        ]
    );

    const agregateCollectedWeight = useCallback(
        (clean_ups: ICleanUp[]) => {
            return clean_ups
                .flatMap((c) => c.collected_weights)
                .filter((cw) => wasteTypes.find((wt) => wt.id === cw.waste_type_id))
                .reduce((acc, cur) => (acc += cur.dry + cur.wet), 0);
        },
        [wasteTypes]
    );

    const agregateCollectedWeightByWasteType = useCallback(
        (clean_ups: ICleanUp[]) => {
            return clean_ups
                .flatMap((c) => c.collected_weights)
                .filter((cw) => wasteTypes.find((wt) => wt.id === cw.waste_type_id))
                .reduce((acc: IPDFWasteTypeWeight[], cur) => {
                    const weight = cur.dry + cur.wet;
                    const index = acc.findIndex((item) => item.id === cur.waste_type_id);

                    if (index === -1) {
                        acc.push({
                            id: cur.waste_type_id,
                            name: wasteTypes.find((wt) => wt.id === cur.waste_type_id)!.title,
                            weight,
                        });
                    } else {
                        acc[index].weight += weight;
                    }

                    return acc;
                }, [])
                .sort((a, b) => b.weight - a.weight);
        },
        [wasteTypes]
    );

    const aggregeteDistricts = useCallback(
        (clean_ups: ICleanUp[]) =>
            clean_ups
                .filter((c) => c.city_island)
                .map((c) => c.city_island!)
                .filter((disrict, index, arr) => arr.indexOf(disrict) === index),
        []
    );

    const agregateUsedResources = useCallback(
        (clean_ups: ICleanUp[]) => {
            return clean_ups
                .flatMap((c) => [...c.used_general_resources, ...c.used_liter_bags])
                .filter((used) => resources.find((r) => r.id === used.resource))
                .reduce((acc: IPDFUsedResources[], cur) => {
                    const index = acc.findIndex((item) => item.id === cur.resource);

                    if (index === -1) {
                        acc.push({
                            id: cur.resource,
                            name: resources.find((r) => r.id === cur.resource)!.title,
                            items: isResourceGeneral(cur) ? cur.amount : cur.full + cur.half,
                        });
                    } else {
                        acc[index].items += isResourceGeneral(cur)
                            ? cur.amount
                            : cur.full + cur.half;
                    }

                    return acc;
                }, [])
                .sort((a, b) => b.items - a.items);
        },
        [resources]
    );

    const getActivityName = useCallback(
        (clean_up: ICleanUp) => {
            return programs
                .find((p) => p.id === clean_up.program)
                ?.activities.find((a) => a.id === clean_up.activity)?.name;
        },
        [programs]
    );

    const getTeamName = useCallback(
        (clean_up: ICleanUp) => {
            return programs
                .find((p) => p.id === clean_up.program)
                ?.teams.find((t) => t.id === clean_up.team)?.name;
        },
        [programs]
    );

    const getCreatorName = useCallback(
        (clean_up: ICleanUp) => {
            const user = users.find((u) => u.id === clean_up.created_by);
            if (user) {
                return `${user.first_name} ${user.last_name}`;
            }
        },
        [users]
    );

    const mapCleanUp = useCallback(
        (clean_up: ICleanUp): IPDFPCleanUp => {
            return {
                ...clean_up,
                activityName: getActivityName(clean_up),
                teamName: getTeamName(clean_up),
                creatorName: getCreatorName(clean_up),
                collectedWeight: {
                    total: agregateCollectedWeight([clean_up]),
                    wasteTypes: agregateCollectedWeightByWasteType([clean_up]),
                },
                usedResources: agregateUsedResources([clean_up]),
            };
        },
        [
            agregateCollectedWeight,
            agregateCollectedWeightByWasteType,
            agregateUsedResources,
            getActivityName,
            getTeamName,
            getCreatorName,
        ]
    );

    const data: IPDFReportData = useMemo(() => {
        return {
            date: new Date(date),
            projects: Object.entries(groupedCleanUps).map(([program, clean_ups]) => ({
                name: program,
                districts: aggregeteDistricts(clean_ups),
                collectedWeight: {
                    total: agregateCollectedWeight(clean_ups),
                    wasteTypes: agregateCollectedWeightByWasteType(clean_ups),
                },
                usedResources: agregateUsedResources(clean_ups),
                cleanUps: clean_ups.map((c) => mapCleanUp(c)),
            })),
        };
    }, [
        date,
        groupedCleanUps,
        agregateCollectedWeight,
        agregateCollectedWeightByWasteType,
        agregateUsedResources,
        aggregeteDistricts,
        mapCleanUp,
    ]);

    return [data, loaded] as const;
};
