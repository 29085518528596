enum NavIcon {
    Programs = 'Programs',
    Placeholder = 'Placeholder',
    Users = 'Users',
    DataConfig = 'DataConfig',
    Calendar = 'Calendar',
}

export const LINKS = [
    {
        name: 'Programs',
        url: '/programs',
        icon: NavIcon.Programs,
    },
    {
        name: 'Users',
        url: '/users',
        icon: NavIcon.Users,
    },
    {
        name: 'Data',
        url: '/data-config',
        icon: NavIcon.DataConfig,
    },
    {
        name: 'Daily Reports',
        url: '/daily-reports',
        icon: NavIcon.Calendar,
    },
    // {
    //     name: 'Test Page',
    //     url: '/test-page',
    //     icon: NavIcon.Placeholder,
    // },
];

export const PROGRAM_CONFIGURATION_LINKS = [
    {title: 'GENERAL', url: 'general'},
    {title: 'ACTIVITIES', url: 'activities'},
    {title: 'PARTICIPANTS', url: 'participants'},
    {title: 'DATA', url: 'data-config'},
    {title: 'COSTS', url: 'costs'},
];

export const DATA_CONFIGURATION_LINKS = [
    {title: 'WASTE', url: 'waste'},
    {title: 'RESOURCES', url: 'resources'},
    {title: 'LOCATION', url: 'location'},
    {title: 'WEATHER CONDITIONS', url: 'weather-conditions'},
    {title: 'NO CLEANING ACTIVITIES', url: 'no-activities'},
];
