import apiClient from './client';
import {
    IAdditionalInfo,
    IAdditionalInfoDTO,
    IAddProgramToAddtionalInfoDTO,
    IRemoveProgramToAddtionalInfoDTO,
} from '../models/additionalInfo';

export const getAdditionalInfo = async (): Promise<IAdditionalInfo[]> => {
    try {
        const response = await apiClient.get<IAdditionalInfo[]>('/additional-options');
        return response.data;
    } catch (e) {
        throw new Error('Failed to fetch data');
    }
};

export const createNewAdditionalInfo = async (
    data: IAdditionalInfoDTO
): Promise<IAdditionalInfo> => {
    try {
        const response = await apiClient.post<IAdditionalInfo>('/additional-options', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchAdditionalInfo = async (
    id: string,
    data: IAdditionalInfoDTO
): Promise<IAdditionalInfo> => {
    const {title} = data;

    try {
        const response = await apiClient.patch<IAdditionalInfo>(`/additional-options/${id}`, {
            title,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeAdditionalInfo = async (id: string): Promise<void> => {
    try {
        await apiClient.delete<IAdditionalInfo[]>(`/additional-options/${id}`);
    } catch (error) {
        throw error;
    }
};

export const addProgramToAdditionalInfo = async (
    id: string,
    data: IAddProgramToAddtionalInfoDTO
): Promise<IAdditionalInfo> => {
    try {
        const result = await apiClient.post<IAdditionalInfo>(
            `/additional-options/${id}/add-program`,
            data
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const removeProgramFmAdditionalInfo = async (
    option_id: string,
    data: IRemoveProgramToAddtionalInfoDTO
): Promise<IAdditionalInfo> => {
    try {
        const result = await apiClient.post<IAdditionalInfo>(
            `/additional-options/${option_id}/remove-program`,
            data
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};
