import * as Yup from 'yup';
import {FIELD_ERRORS} from '../../../constants';
import {ActivityStatus} from '../../../models/activity';

export interface IActivityForm {
    id: string;
    name: string;
    status: ActivityStatus | undefined;
}

export const initial: IActivityForm = {id: '', name: '', status: undefined};

export const validationSchema = Yup.object({
    name: Yup.string().required(FIELD_ERRORS.REQUIRED),
    status: Yup.string().required(FIELD_ERRORS.REQUIRED),
});
