import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {ICleanUp} from '../../models/cleanUp';
import {
    approveCleanUpSpot,
    fetchCleanUByDate,
    fetchCleanUById,
    unApproveCleanUpSpot,
} from '../../api/cleanUp';
import {useAppDispatch, useAppSelector} from '../app-hooks';
import {useEffect} from 'react';
import {getArrayIndex, removeDuplicates} from '../../utils/array_helpers';

interface ISpotApprovalPayload {
    cleanUpId: string;
    spotId: string;
}

interface ICleanUpState {
    cleanUpData: ICleanUp[];
    loaded_ids: string[];
    error: string | null;
    loaded_dates: string[];
}

const initialState: ICleanUpState = {
    cleanUpData: [],
    loaded_ids: [],
    error: null,
    loaded_dates: [],
};

export const getCleanUpById = createAsyncThunk(
    'cleanUp/getCleanUpById',
    async (id: string) => await fetchCleanUById(id)
);

export const getCleanUpByDate = createAsyncThunk(
    'cleanUp/getCleanUpByDate',
    async (date: string) => await fetchCleanUByDate(date)
);

export const spotCleanUpApprove = createAsyncThunk(
    'cleanUp/spotCleanUpApprove',
    async ({cleanUpId, spotId}: ISpotApprovalPayload) => await approveCleanUpSpot(cleanUpId, spotId)
);

export const spotCleanUpUnApprove = createAsyncThunk(
    'cleanUp/spotCleanUpUnApprove',
    async ({cleanUpId, spotId}: ISpotApprovalPayload) =>
        await unApproveCleanUpSpot(cleanUpId, spotId)
);

export const cleanUpSlice = createSlice({
    name: 'cleanUp',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCleanUpById.fulfilled, (state, action) => {
                state.error = null;
                state.cleanUpData.push(action.payload);
                state.loaded_ids.push(action.payload.id);
            })
            .addCase(getCleanUpById.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(getCleanUpByDate.fulfilled, (state, action) => {
                const updatedCleanUps = [...state.cleanUpData, ...action.payload];
                const uniqueCleanUpData = removeDuplicates(updatedCleanUps);
                const uniqueCleanUpIds = uniqueCleanUpData.map((it) => it.id);

                state.error = null;
                state.cleanUpData = uniqueCleanUpData;
                state.loaded_ids = uniqueCleanUpIds;
                if (!state.loaded_dates.includes(action.meta.arg)) {
                    state.loaded_dates.push(action.meta.arg);
                }
            })
            .addCase(getCleanUpByDate.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(spotCleanUpApprove.fulfilled, (state, action) => {
                const {id} = action.payload;
                const currentIndex = getArrayIndex(state.cleanUpData, id);

                state.error = null;
                state.cleanUpData[currentIndex] = action.payload;
            })
            .addCase(spotCleanUpApprove.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(spotCleanUpUnApprove.fulfilled, (state, action) => {
                const {id} = action.payload;
                const currentIndex = getArrayIndex(state.cleanUpData, id);

                state.error = null;
                state.cleanUpData[currentIndex] = action.payload;
            })
            .addCase(spotCleanUpUnApprove.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
            });
    },
});

export const cleanUps = (state: RootState) => state.cleanUps.cleanUpData;
export const loadedIds = (state: RootState) => state.cleanUps.loaded_ids;
export const loadedDates = (state: RootState) => state.cleanUps.loaded_dates;

export const useCleanUpById = (id?: string) => {
    const dispatch = useAppDispatch();

    const loaded = useAppSelector(loadedIds).includes(id || '');
    const loadedCleanUps = useAppSelector(cleanUps);
    const clean_up = loadedCleanUps.find((c) => c.id === id);

    useEffect(() => {
        if (!loaded && id) {
            dispatch(getCleanUpById(id));
        }
    }, [loaded, id, dispatch]);

    return [clean_up, loaded] as const;
};

export const useCleanUpByDate = (selectedDate: string) => {
    const dispatch = useAppDispatch();
    const currDate = new Date(selectedDate).toISOString();

    const loaded = useAppSelector(loadedDates).includes(selectedDate);

    const loadedCleanUps = useAppSelector(cleanUps);
    const clean_ups = loadedCleanUps.filter((c) => c.date === currDate);

    useEffect(() => {
        if (!loaded) {
            dispatch(getCleanUpByDate(selectedDate));
        }
    }, [loaded, selectedDate, dispatch]);

    return [clean_ups, loaded] as const;
};

export default cleanUpSlice.reducer;
