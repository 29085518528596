import {Modal} from '../../UI/Modal';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Input} from '../../UI/Input';
import {Dropdown} from '../../UI/Input/Dropdown';
import {useSiteTypesData} from '../../../store/siteType/siteTypeSlice';
import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {
    newProgram,
    programsError,
    programsIsSubmitting,
    updateProgram,
} from '../../../store/programs/programsSlice';
import {Controller, useForm} from 'react-hook-form';
import {initial, IProgramsForm, validationSchema} from './formModel';
import {yupResolver} from '@hookform/resolvers/yup';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {FIELD_LABELS} from '../../../constants';

type ProgramModalProps = {
    program?: IProgramsForm;
    closeModal: (id?: string) => void;
};

const ProgramModal = ({program, closeModal}: ProgramModalProps) => {
    const [siteTypes] = useSiteTypesData();
    const serverError = useAppSelector(programsError);

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

    const initialFormValues = program ? program : initial;

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<IProgramsForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialFormValues,
    });

    const dispatch = useAppDispatch();
    const isSubmitting = useAppSelector(programsIsSubmitting);

    const modalTitle = !program ? 'New Program' : 'Edit Program Info';
    const actionButtonTitle = !program ? ADD_TO_LIST_LABEL : SAVE_CHANGES_LABEL;

    const onActionClick = (data: IProgramsForm) => {
        const action = !program ? newProgram : updateProgram;

        dispatch(action(data))
            .unwrap()
            .then(({id}) => closeModal(id))
            .catch(({message}) => console.log(message));
    };

    const siteTypesOptions = siteTypes.map(({id, title}) => ({
        id,
        label: title,
    }));

    return (
        <Modal title={modalTitle}>
            <ModalBody>
                <Input
                    label="Name"
                    placeholder="Type here"
                    required
                    error={!!errors.name}
                    errorMessage={errors.name?.message}
                    {...register('name')}
                />
                <Controller
                    name="site_type_id"
                    control={control}
                    render={({field}) => (
                        <Dropdown
                            {...field}
                            value={initialFormValues.site_type_id}
                            error={!!errors.site_type_id}
                            errorMessage={errors.site_type_id?.message}
                            label="Site Type"
                            required
                            options={siteTypesOptions}
                        />
                    )}
                />
                {serverError && <ErrorMessage message={serverError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleSubmit(onActionClick)}
                    label={actionButtonTitle}
                />
                <TextButton
                    type="button"
                    variant="Danger"
                    label={CANCEL_LABEL}
                    onClick={() => closeModal()}
                />
            </ModalActions>
        </Modal>
    );
};

export default ProgramModal;
