import {useMemo} from 'react';
import {useNoCleaningRecordsByDate} from '../store/noCleanigRecord/noCleaningRecordSlice';
import {useNoCleaningReasons} from '../store/noCleaningReason/noCleaningReasonSlice';
import {useProgramsData} from '../store/programs/programsSlice';

interface INoCleaningResonByProgram {
    id: string;
    program: string;
    reason: string;
}

export const useGroupedNoCleaningRecords = (selectedDay: string) => {
    const [noCleaningRecords, noCleaningRecordsLoaded] = useNoCleaningRecordsByDate(selectedDay);
    const [programs, programsLoaded] = useProgramsData();
    const [noCleaningReasons, noCleaningReasonsLoaded] = useNoCleaningReasons();

    const data = useMemo(() => {
        return noCleaningRecords
            .map((record) => {
                const program = programs.find((p) => p.id === record.program)?.name;
                const reason = noCleaningReasons.find((r) => r.id === record.reason)?.name;

                return {id: record.id, program, reason};
            })
            .filter((r) => r.program && r.reason) as INoCleaningResonByProgram[];
    }, [noCleaningReasons, noCleaningRecords, programs]);

    const loaded = noCleaningRecordsLoaded && programsLoaded && noCleaningReasonsLoaded;

    return [data, loaded] as const;
};
