import {FC} from 'react';

import s from './calendar.module.scss';

const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

const CalendarHeader: FC = () => {
    return (
        <div className={s.header}>
            {daysOfWeek.map((day, index) => (
                <div key={index}>{day}</div>
            ))}
        </div>
    );
};

export default CalendarHeader;
