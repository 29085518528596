import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {useReduxData} from '../../hooks/useReduxData';
import {RootState} from '../store';
import {ISiteType, ISiteTypeDTO} from '../../models/siteType';
import {createNewSiteType, getSiteTypes, patchSiteType, removeSiteType} from '../../api/siteType';
import {getArrayIndex} from '../../utils/array_helpers';

interface ISiteTypeState {
    siteTypesData: ISiteType[];
    error: string | null;
    loaded: boolean;
    isSubmitting: boolean;
}

const initialState: ISiteTypeState = {
    siteTypesData: [],
    error: null,
    loaded: false,
    isSubmitting: false,
};

export const loadSiteTypes = createAsyncThunk(
    'siteType/loadSiteTypes',
    async () => await getSiteTypes()
);

export const newSiteType = createAsyncThunk(
    'siteType/newSiteType',
    async (data: ISiteTypeDTO) => await createNewSiteType(data)
);

export const updateSiteType = createAsyncThunk(
    'siteType/updateSiteType',
    async (data: ISiteTypeDTO) => await patchSiteType(data)
);

export const deleteSiteType = createAsyncThunk(
    'siteType/deleteSiteType',
    async (id: string) => await removeSiteType(id)
);

export const siteTypesSlice = createSlice({
    name: 'siteType',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadSiteTypes.fulfilled, (state, action) => {
                state.siteTypesData = action.payload;
                state.loaded = true;
                state.error = null;
            })
            .addCase(loadSiteTypes.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
                state.loaded = true;
            })
            .addCase(newSiteType.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newSiteType.fulfilled, (state, action) => {
                state.isSubmitting = false;
                state.error = null;
                state.siteTypesData.push(action.payload);
            })
            .addCase(newSiteType.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(updateSiteType.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateSiteType.fulfilled, (state, action) => {
                const {id} = action.payload;
                const currentIndex = getArrayIndex(state.siteTypesData, id);

                state.isSubmitting = false;
                state.error = null;
                state.siteTypesData[currentIndex] = action.payload;
            })
            .addCase(updateSiteType.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(deleteSiteType.fulfilled, (state, action) => {
                const id = action.meta.arg;

                state.error = null;
                state.isSubmitting = false;
                state.siteTypesData = state.siteTypesData.filter((data) => data.id !== id);
            });
    },
});

export const {clearError} = siteTypesSlice.actions;

export const siteTypes = (state: RootState) => state.siteTypes.siteTypesData;
export const siteTypesError = (state: RootState) => state.siteTypes.error;
export const siteTypesLoaded = (state: RootState) => state.siteTypes.loaded;
export const siteTypeIsSubmitting = (state: RootState) => state.siteTypes.isSubmitting;

export const useSiteTypesData = () => useReduxData(siteTypes, siteTypesLoaded, loadSiteTypes);

export default siteTypesSlice.reducer;
