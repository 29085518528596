import {
    additionalInfoError,
    additionalInfoIsSubmitting,
    newAdditionalInfo,
    updateAdditionalInfo,
} from '../../../store/additionalInfo/additionalInfoSlice';
import {IAdditionalInfo} from '../../../models/additionalInfo';
import {Modal} from '../../UI/Modal';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Input} from '../../UI/Input';

import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {FIELD_LABELS} from '../../../constants';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {IAdditionalInfoForm, initial, validationSchema} from './formModel';

type AdditionalInfoModalProps = {
    additionalInfo?: IAdditionalInfo;
    programId?: string;
    closeModal: VoidFunction;
};

const AdditionalInfoModal = ({additionalInfo, programId, closeModal}: AdditionalInfoModalProps) => {
    const dispatch = useAppDispatch();
    const formError = useAppSelector(additionalInfoError);

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

    const initialFormValue: IAdditionalInfoForm = additionalInfo
        ? {title: additionalInfo.title}
        : initial;

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IAdditionalInfoForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialFormValue,
    });

    const isSubmitting = useAppSelector(additionalInfoIsSubmitting);

    const modalTitle = !additionalInfo ? 'New Additional Info Type' : 'Edit Additional Info Type';
    const actionButtonTitle = !additionalInfo ? ADD_TO_LIST_LABEL : SAVE_CHANGES_LABEL;

    const onActionClick = (formValues: IAdditionalInfoForm) => {
        if (!additionalInfo) {
            dispatch(newAdditionalInfo({...formValues, ...(programId && {programId})}))
                .unwrap()
                .then(() => closeModal());
        } else {
            dispatch(updateAdditionalInfo({option_id: additionalInfo.id, data: {...formValues}}))
                .unwrap()
                .then(() => closeModal())
                .catch(({message}) => console.log(message));
        }
    };

    return (
        <Modal title={modalTitle}>
            <ModalBody>
                <Input
                    label="Name"
                    placeholder="Type here"
                    required
                    error={!!errors.title}
                    errorMessage={errors.title?.message}
                    {...register('title')}
                />
                {formError && <ErrorMessage message={formError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    type="submit"
                    onClick={handleSubmit(onActionClick)}
                    disabled={isSubmitting}
                    label={actionButtonTitle}
                />
                <TextButton variant="Danger" label={CANCEL_LABEL} onClick={closeModal} />
            </ModalActions>
        </Modal>
    );
};

export default AdditionalInfoModal;
