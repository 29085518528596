import {ButtonHTMLAttributes, FC} from 'react';
import {Icon} from '../../Icon';
import s from '../index.module.scss';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    label: string;
    selected?: boolean;
}

export const ToggleChip: FC<IProps> = ({label, selected = false, ...rest}) => {
    return (
        <button className={`${s.container} ${s.toggle} ${selected && s.selected}`} {...rest}>
            {selected && <Icon name="Checkmark" variant="onAccent" size="18" />}
            {label}
        </button>
    );
};
