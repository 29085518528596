import * as Yup from 'yup';
import {FIELD_ERRORS} from '../../../constants';

export interface IWeatherConditionForm {
    id: string;
    title: string;
    unit?: string;
}

export const initial: IWeatherConditionForm = {id: '', title: '', unit: ''};

export const validationSchema = Yup.object({
    title: Yup.string().required(FIELD_ERRORS.REQUIRED),
    unit: Yup.string().nullable(),
});
