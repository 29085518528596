import {AxiosError} from 'axios';
import {FirebaseError} from 'firebase/app';
import {
    signInWithEmailAndPassword,
    signOut,
    AuthErrorCodes,
    confirmPasswordReset,
    updatePassword,
    reauthenticateWithCredential,
    EmailAuthProvider,
} from 'firebase/auth';

import {auth} from './firebase';
import apiClient from './client';
import {IUser, Role} from '../models/user';

export const login = async (email: string, password: string) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);

        const result = await apiClient.get<IUser>('/users/me');

        if (!result.data.is_active || ![Role.admin, Role.director].includes(result.data.role)) {
            await signOut(auth);
            throw new Error('Your acount does not have the required privilege to access this app');
        }
        return result.data;
    } catch (error) {
        if (error instanceof FirebaseError) {
            switch (error.code) {
                case AuthErrorCodes.INVALID_PASSWORD:
                case AuthErrorCodes.USER_DELETED:
                    throw new Error('Invalid credentials');
                case AuthErrorCodes.USER_DISABLED:
                    throw new Error(
                        'Your account has been disabled. Please contact the administrator'
                    );

                default:
                    throw new Error('Something went wrong');
            }
        } else if (error instanceof AxiosError) {
            throw new Error('Something went wrong');
        }

        throw error;
    }
};

export const loadUser = async () => {
    try {
        const authUser = auth.currentUser;

        if (!authUser) return null;

        const result = await apiClient.get<IUser>('/users/me');

        if (!result.data.is_active || ![Role.admin, Role.director].includes(result.data.role)) {
            await signOut(auth);
            return null;
        }
        return result.data;
    } catch (error) {
        return null;
    }
};

export const logout = async () => {
    await signOut(auth);
};

export const resetPassword = async (newPassword: string, oobCode: string) => {
    try {
        await confirmPasswordReset(auth, oobCode, newPassword);
    } catch (error) {
        if (error instanceof FirebaseError) {
            switch (error.code) {
                case AuthErrorCodes.EXPIRED_OOB_CODE:
                    throw new Error(
                        'The link has expired. Please request a new one or contact the Administrator'
                    );
                case AuthErrorCodes.INVALID_OOB_CODE:
                    throw new Error(
                        'Invalid link. Please request a new one or contact the Administrator'
                    );
                case AuthErrorCodes.USER_DISABLED:
                    throw new Error('Your account has been disable');
                case AuthErrorCodes.USER_DELETED:
                    throw new Error('Your account has been deleted');
                case AuthErrorCodes.WEAK_PASSWORD:
                    throw new Error('Weak passowrd please try a new one');
                default:
                    new Error('Something went wrong');
            }
        } else {
            throw new Error('Something went wrong');
        }
    }
};

export const changePassword = async (currentPassword: string, newPassword: string) => {
    const authUser = auth.currentUser;

    if (!authUser || !authUser.email) throw new Error('Something went wrong');

    try {
        const credential = EmailAuthProvider.credential(authUser.email, currentPassword);
        await reauthenticateWithCredential(authUser, credential);
    } catch (error) {
        if (error instanceof FirebaseError) {
            switch (error.code) {
                case AuthErrorCodes.INVALID_PASSWORD:
                    throw new Error('Invalid current password');
                case AuthErrorCodes.USER_DISABLED:
                    throw new Error('Your account has been disable');
                case AuthErrorCodes.USER_DELETED:
                    throw new Error('Your account has been deleted');
                default:
                    new Error('Something went wrong');
            }
        } else {
            throw new Error('Something went wrong');
        }
    }

    try {
        await updatePassword(authUser, newPassword);
    } catch (error) {
        if (error instanceof FirebaseError) {
            switch (error.code) {
                case AuthErrorCodes.USER_DISABLED:
                    throw new Error('Your account has been disable');
                case AuthErrorCodes.USER_DELETED:
                    throw new Error('Your account has been deleted');
                case AuthErrorCodes.WEAK_PASSWORD:
                    throw new Error('Weak passowrd please try a new one');
                default:
                    new Error('Something went wrong');
            }
        } else {
            throw new Error('Something went wrong');
        }
    }
};

export const requestResetPassword = async (email: string): Promise<void> => {
    try {
        await apiClient.post('/auth/reset-password', {email});
    } catch (error) {
        throw error;
    }
};
