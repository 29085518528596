import ListItem from '../../../components/shared/ListItem';
import {FAB} from '../../../components/UI/Button/FAB';
import {Dropdown} from '../../../components/UI/Input/Dropdown';
import {SearchInput} from '../../../components/UI/Input/Search';
import {FABWrapper} from '../../../Layout';

import s from './index.module.scss';
import {useState} from 'react';
import ActivityModal from '../../../components/shared/ActivityModal';
import {useAppDispatch} from '../../../store/app-hooks';
import {clearError, deleteActivity} from '../../../store/programs/programsSlice';
import {ACTIVITIES_MENU, FIELD_LABELS} from '../../../constants';
import {IActivityForm} from '../../../components/shared/ActivityModal/formModel';
import {IActivity} from '../../../models/activity';
import useConfirm from '../../../hooks/useConfirm';
import {useNotification} from '../../../store/notification/NotificationContext';
import useProgramFromParams from '../../../hooks/useProgramFromParams';

export const ProgramActivitiesContent = () => {
    const dispatch = useAppDispatch();
    const {confirm} = useConfirm();
    const {addNotification} = useNotification();
    const program = useProgramFromParams();

    const [activityType, setActivityType] = useState<string>('all');
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const [selectedActivity, setSelectedActivity] = useState<IActivityForm>();
    const [currentInput, setCurrentInput] = useState('');

    const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION} = FIELD_LABELS;

    const searchTerm = new RegExp(currentInput, 'i');

    const activities = program?.activities
        .filter((item) => (activityType === 'all' ? true : item.status === activityType))
        .filter((item) => (currentInput === '' ? true : searchTerm.test(item.name)));

    const closeAddNewModal = () => {
        setShowAddNewModal(false);
        dispatch(clearError());
    };

    const closeEditModal = () => {
        setShowEditModal(false);
        dispatch(clearError());
    };

    const onEdit = (currentActivity: IActivity) => {
        setShowEditModal(true);
        setSelectedActivity(currentActivity);
    };

    const onDeleteActivity = async (activityId: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        const payLoad = {
            activityId,
            programId: program!.id,
        };

        if (result) {
            dispatch(deleteActivity(payLoad))
                .unwrap()
                .then(() => {
                    addNotification(SUCCESSFUL_DELETION, 'success');
                })
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    return (
        <>
            <div className={s.container}>
                <div className={s.inputs}>
                    <SearchInput
                        onChange={(e) => setCurrentInput(e.target.value)}
                        placeholder="Search activities"
                    />
                    <Dropdown
                        value={activityType}
                        onChange={setActivityType}
                        name="select"
                        options={ACTIVITIES_MENU}
                    />
                    {/* <Button label="View Archived" variant="Tertiary" /> */}
                </div>
                <div className={s.list}>
                    {activities?.map((activity) => (
                        <ListItem
                            key={activity.id}
                            title={activity.name}
                            status={activity.status}
                            onDeleteClick={() => onDeleteActivity(activity.id)}
                            onEditClick={() => onEdit(activity)}
                        />
                    ))}
                </div>
            </div>
            <FABWrapper>
                <FAB onClick={() => setShowAddNewModal(true)} />
            </FABWrapper>
            {showAddNewModal && (
                <ActivityModal programId={program!.id} closeModal={closeAddNewModal} />
            )}
            {showEditModal && (
                <ActivityModal
                    activity={selectedActivity}
                    programId={program!.id}
                    closeModal={closeEditModal}
                />
            )}
        </>
    );
};
