import {useMemo} from 'react';
import {ICleanUp} from '../models/cleanUp';
import {useProgramsData} from '../store/programs/programsSlice';

const useGroupCleanUpsByProgram = (cleanUps: ICleanUp[]) => {
    const [programs, programsLoaded] = useProgramsData();

    const groupedCleanUps = useMemo(() => {
        // group cleans ups by program
        return cleanUps.reduce((group: Record<string, ICleanUp[]>, clean_up) => {
            const {program} = clean_up;

            const programName = programs?.find((pr) => pr.id === program)?.name ?? '';

            group[programName] = group[programName] ?? [];
            group[programName].push(clean_up);

            return group;
        }, {});
    }, [cleanUps, programs]);

    return [groupedCleanUps, programsLoaded] as const;
};

export default useGroupCleanUpsByProgram;
