import {INoCleaningRecord} from '../models/noCleaningRecord';
import apiClient from './client';

export const fetchNoCleaningRecordsByDate = async (date: string): Promise<INoCleaningRecord[]> => {
    try {
        const response = await apiClient.get<INoCleaningRecord[]>(
            `/daily-report/no-cleaning-records?date=${date}`
        );
        return response.data;
    } catch (e) {
        throw e;
    }
};
