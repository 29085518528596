import {useMemo} from 'react';
import {PageHeader, PageWrapper} from '../../../Layout';
import classes from './index.module.scss';
import CleanUpBoard, {HeaderChip} from '../../../components/bloc/DailyReport/CleanUp/CleanUpBoard';
import Spot from '../../../components/bloc/DailyReport/CleanUp/Spot';
import {useCleanUpById} from '../../../store/cleanUp/cleanUpSlice';
import {format} from 'date-fns';
import {useParams} from 'react-router-dom';
import {useCleanUpResources} from '../../../hooks/useCleanUpResources';
import {useCleanUpCollectedWeight} from '../../../hooks/useCleanUpCollectedWeight';
import {useCleanUpBasicInfo} from '../../../hooks/useCleanUpBasicInfo';
import {useCollectorName} from '../../../hooks/useCollectorName';
import {useCleanUpLitterBags} from '../../../hooks/useCleanUpLitterBags';
import {LoadingContainer} from '../../../components/UI/LoadingContainer';
import useCleanUpWeatherConditions from '../../../hooks/useCleanUpWeatherConditions';

export const Index = () => {
    const {cleanUpId} = useParams<{cleanUpId: string}>();
    const [cleanUp, cleanUpLoaded] = useCleanUpById(cleanUpId);

    const [basicInfoData, basicInfoLoaded] = useCleanUpBasicInfo(cleanUp);
    const [watherConditionData, watherConditionDataLoaded] = useCleanUpWeatherConditions(cleanUp);

    const [collectedWeights, collectedWeightsLoaded] = useCleanUpCollectedWeight(
        cleanUp?.collected_weights
    );

    const [resourcesData, resourcesDataLoaded] = useCleanUpResources(
        cleanUp?.used_general_resources
    );
    const resources = resourcesData.map((entry) => ({...entry, info: entry.info + ' pcs'}));

    const [litterBagsData, litterBagsDataLoaded] = useCleanUpLitterBags(cleanUp?.used_liter_bags);
    const literBags = litterBagsData.map((entry) => ({...entry, info: entry.info + ' pcs'}));

    const [collectorName, collectorNameLoaded] = useCollectorName(cleanUp?.created_by);

    const cleanUpDate = useMemo(
        () => cleanUp && format(new Date(cleanUp?.date), 'MMM dd, yyyy'),
        [cleanUp]
    );

    const dataLoaded =
        cleanUpLoaded &&
        basicInfoLoaded &&
        watherConditionDataLoaded &&
        collectedWeightsLoaded &&
        resourcesDataLoaded &&
        litterBagsDataLoaded &&
        collectorNameLoaded;

    const collectedWeightStatus = useMemo(() => {
        return [
            ...(cleanUp?.no_litter_found ? [{label: 'No Litter Found', type: 'success'}] : []),
            ...(!cleanUp?.no_litter_found && collectedWeights.total === 0
                ? [{label: 'Pending Data', type: 'warning'}]
                : []),
        ] as HeaderChip[];
    }, [cleanUp, collectedWeights]);

    return (
        <PageWrapper>
            {dataLoaded ? (
                <>
                    <PageHeader returnIcon title={cleanUp?.location_name ?? 'Unnamed Location'} />

                    <div className={classes.container}>
                        <p>{`Clean Up of ${cleanUpDate}`}</p>
                        <p>{`Added by ${collectorName}`}</p>

                        <CleanUpBoard data={basicInfoData} header="BASIC INFO" />
                        <CleanUpBoard data={watherConditionData} header="WEATHER CONDITIONS" />
                        <CleanUpBoard
                            data={collectedWeights.data}
                            header="COLLECTED WEIGHT"
                            headerChips={collectedWeightStatus}
                        />
                        <CleanUpBoard data={literBags} header="USED LITTER BAGS" />
                        <CleanUpBoard data={resources} header="USED RESOURCES" />

                        <h2>NOTES</h2>
                        <div className={classes.notesWrapper}>
                            <p>{cleanUp?.notes || 'No notes have been added.'}</p>
                        </div>

                        <h2>SPOTS</h2>
                        {(cleanUp?.images || []).length > 0 ? (
                            cleanUp?.images.map((img, index) => (
                                <Spot
                                    key={img.id}
                                    data={img}
                                    cleanUpId={cleanUpId!}
                                    spotName={`Spot ${index + 1}`}
                                />
                            ))
                        ) : (
                            <div className={classes.notesWrapper}>
                                <p>No photos of the Spots found.</p>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <LoadingContainer />
            )}
        </PageWrapper>
    );
};
