import {FC, useRef} from 'react';
import {ModalPortal, NotificationSnackbar} from './ModalBuildingBlocks';

import s from './index.module.scss';
import useConfirm from '../../../hooks/useConfirm';
// import {IconButton} from '../Button/Icon';
import {TextButton} from '../Button/Text';
import {INotificationState} from '../../../store/notification/NotificationContext';

interface ΙModalProps {
    title?: string;
    children: React.ReactNode;
}

export const Modal: FC<ΙModalProps> = ({title, children}) => {
    const modalRef = useRef<HTMLDivElement>(null);

    return (
        <ModalPortal wrapperId="modal-portal">
            <div className={s.overlay}>
                <div className={s.container} ref={modalRef}>
                    {title && <div className={s.header}>{title}</div>}
                    {children}
                </div>
            </div>
        </ModalPortal>
    );
};

export const ConfirmationModal: FC = () => {
    const modalRef = useRef<HTMLDivElement>(null);
    const {
        confirmState: {show, message, title},
        onCancel,
        onConfirm,
    } = useConfirm();
    return (
        <ModalPortal wrapperId="modal-portal">
            {show && (
                <div className={s.overlay}>
                    <div className={s.confirmContainer} ref={modalRef}>
                        <div className={s.header}>{title}</div>
                        <div className={s.body}>{message}</div>
                        <div className={s.actions}>
                            <TextButton label="Continue" variant="Success" onClick={onConfirm} />
                            <TextButton label="Cancel" variant="Danger" onClick={onCancel} />
                        </div>
                        {/* <div className={s.actions}>
                            <IconButton icon="Checkmark" onClick={onConfirm} variant="Success" />
                            <IconButton icon="Close" onClick={onCancel} variant="Danger" />
                        </div> */}
                    </div>
                </div>
            )}
        </ModalPortal>
    );
};

interface INotificationsContainerProps {
    notifications: INotificationState[];
}

export const NotificationsContainer: FC<INotificationsContainerProps> = ({notifications}) => {
    return (
        <ModalPortal wrapperId="snackbar-container">
            <div className={s.notificationContainer}>
                <ul>
                    {notifications.map((notification) => (
                        <NotificationSnackbar
                            key={notification.id}
                            id={notification.id}
                            variant={notification.type}
                            message={notification.message}
                        />
                    ))}
                </ul>
            </div>
        </ModalPortal>
    );
};
