import * as Yup from 'yup';
import {FIELD_ERRORS} from '../../../constants';

export interface IWasteCategoryItemForm {
    name: string;
}

export const initial: IWasteCategoryItemForm = {name: ''};

export const validationSchema = Yup.object({
    name: Yup.string().required(FIELD_ERRORS.REQUIRED),
});
