import ListContainer from '../../../../components/shared/ListContainer';
import ListItem from '../../../../components/shared/ListItem';
import {
    clearError,
    deleteWasteType,
    useWasteTypesData,
} from '../../../../store/wasteType/wasteTypeSlice';
import {useState} from 'react';
import WasteTypeModal from '../../../../components/shared/WasteTypeModal';
import {useAppDispatch} from '../../../../store/app-hooks';
import {IWasteType} from '../../../../models/wasteType';
import useConfirm from '../../../../hooks/useConfirm';
import {useNotification} from '../../../../store/notification/NotificationContext';
import {FIELD_LABELS} from '../../../../constants';

export const WasteTypesConfig = () => {
    const dispatch = useAppDispatch();
    const {confirm} = useConfirm();
    const {addNotification} = useNotification();

    const [wasteTypesData, wasteTypesLoaded] = useWasteTypesData();

    const [openAddNewModal, setOpenAddNewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [selectedWasteType, setSelectedWasteType] = useState<IWasteType>();
    const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION} = FIELD_LABELS;

    // const handleViewArchivedClick = () => {};

    const closeAddNewModal = () => {
        dispatch(clearError());
        setOpenAddNewModal(false);
    };

    const handleEdit = (wasteType: IWasteType) => {
        setOpenEditModal(true);
        setSelectedWasteType(wasteType);
    };

    const closeEditModal = () => {
        dispatch(clearError());
        setOpenEditModal(false);
    };

    const onDelete = async (id: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result) {
            dispatch(deleteWasteType(id))
                .unwrap()
                .then(() => addNotification(SUCCESSFUL_DELETION, 'success'))
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    return wasteTypesLoaded ? (
        <>
            <ListContainer
                onAddClick={() => setOpenAddNewModal(true)}
                // onViewArchivedClick={handleViewArchivedClick}
                title="Waste Types"
            >
                {wasteTypesData.map((wasteType) => (
                    <ListItem
                        key={wasteType.id}
                        title={wasteType.title}
                        onDeleteClick={() => onDelete(wasteType.id)}
                        onEditClick={() => handleEdit(wasteType)}
                    />
                ))}
            </ListContainer>
            {openAddNewModal && <WasteTypeModal closeModal={closeAddNewModal} />}
            {openEditModal && (
                <WasteTypeModal wasteType={selectedWasteType} closeModal={closeEditModal} />
            )}
        </>
    ) : (
        <div>Waiting for data to load...</div>
    );
};
