import {FC} from 'react';
import {View, Text, StyleSheet} from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '10pt',
    },
    headerBg: {
        backgroundColor: '#cccccc',
    },
    regularBg: {
        backgroundColor: '#eaeef1',
        borderColor: 'white',
        borderWidth: 1,
    },
    title: {
        fontFamily: 'Manrope',
        fontWeight: 'medium',
        fontSize: '14pt',
    },
    subtitle: {
        fontFamily: 'Manrope',
        fontWeight: 'normal',
        fontSize: '12pt',
    },
});

interface IProps {
    title: string;
    subTitle?: string;
    header?: true;
    half?: boolean;
}

const TableRow: FC<IProps> = ({title, subTitle, header, half}) => {
    return (
        <View
            style={{
                ...styles.container,
                ...(header && styles.headerBg),
                ...(!header && styles.regularBg),
                ...(half && {width: '50%'}),
            }}
        >
            <Text style={header ? styles.title : styles.subtitle}>{title}</Text>
            {subTitle && <Text style={styles.subtitle}>{subTitle}</Text>}
        </View>
    );
};

export default TableRow;
