import {configureStore} from '@reduxjs/toolkit';

import authReducer from './auth/authSlice';
import siteTypesReducer from './siteType/siteTypeSlice';
import programsReducer from './programs/programsSlice';
import substrateOptionsReducer from './substrateOptions/substrateOptionSlice';
import additionalInfoReducer from './additionalInfo/additionalInfoSlice';
import wasteTypeReducer from './wasteType/wasteTypeSlice';
import wasteCategoryReducer from './wasteCategory/wasteCategorySlice';
import userReducer from './user/userSlice';
import weatherConditionReducer from './weatherCondition/weatherCondition';
import noCleaningReasonReducer from './noCleaningReason/noCleaningReasonSlice';
import resourceReducer from './resource/resource';
import cleanUpReducer from './cleanUp/cleanUpSlice';
import dailyReportsReducer from './dailyReport/dailyReportSlice';
import noCleaningRecordReducer from './noCleanigRecord/noCleaningRecordSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        siteTypes: siteTypesReducer,
        programs: programsReducer,
        substrateOptions: substrateOptionsReducer,
        additionalInfo: additionalInfoReducer,
        wasteType: wasteTypeReducer,
        wasteCategories: wasteCategoryReducer,
        users: userReducer,
        weatherConditions: weatherConditionReducer,
        noCleaningReasons: noCleaningReasonReducer,
        resources: resourceReducer,
        cleanUps: cleanUpReducer,
        dailyReports: dailyReportsReducer,
        noCleaningRecords: noCleaningRecordReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
