import {FC} from 'react';
import {Text, StyleSheet} from '@react-pdf/renderer';

const styles = StyleSheet.create({
    pageCounter: {
        position: 'absolute',
        bottom: 5,
        right: 14,
        fontWeight: 'normal',
        fontFamily: 'Manrope',
        color: '#002342',
        fontSize: 12,
    },
});

const PageCounter: FC = () => {
    return (
        <Text
            style={styles.pageCounter}
            render={({pageNumber, totalPages}) => `page ${pageNumber} of ${totalPages}`}
            fixed
        />
    );
};

export default PageCounter;
