import s from './index.module.scss';
import {Button} from '../../UI/Button';
import React from 'react';

type ListContainerProps = {
    children: React.ReactNode;
    onAddClick: VoidFunction;
    onViewArchivedClick?: VoidFunction;
    title: string;
};

const ListContainer = ({children, title, onAddClick, onViewArchivedClick}: ListContainerProps) => {
    return (
        <div className={s.dataCard}>
            <div className={s.cardHeader}>
                <div className={`${s.title} ${s.group}`}>{title}</div>
                {onViewArchivedClick ? (
                    <Button
                        onClick={onViewArchivedClick}
                        label="View Archived"
                        variant="Tertiary"
                    />
                ) : null}
                <Button onClick={onAddClick} label="Add New" />
            </div>
            <div className={s.cardListContent}>{children}</div>
        </div>
    );
};

export default ListContainer;
