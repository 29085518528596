import {
    IAddProgramToWasteTypeDTO,
    IRemoveProgramToWasteTypeDTO,
    IWasteType,
    IWasteTypeDTO,
} from '../../models/wasteType';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    createNewWasteType,
    getWasteTypes,
    patchWasteType,
    removeWasteType,
    addProgramToWasteType as addProgramToWasteTypeApi,
    removeProgramFmWasteType as removeProgramFmWasteTypeApi,
} from '../../api/wasteType';
import {RootState} from '../store';
import {useReduxData} from '../../hooks/useReduxData';
import {getArrayIndex} from '../../utils/array_helpers';

interface IWasteTypeState {
    wasteTypesData: IWasteType[];
    error: string | null;
    loaded: boolean;
    isSubmitting: boolean;
}

const initialState: IWasteTypeState = {
    wasteTypesData: [],
    error: null,
    loaded: false,
    isSubmitting: false,
};

type UpdateWasteTypePayload = {id: string; data: IWasteTypeDTO};
type AddProgramToWasteTypePayload = {type_id: string; data: IAddProgramToWasteTypeDTO};
type RemoveProgramFmWasteTypePayload = {type_id: string; data: IRemoveProgramToWasteTypeDTO};

export const loadWasteTypes = createAsyncThunk(
    'wasteTypes/loadWasteTypes',
    async () => await getWasteTypes()
);

export const newWasteTypes = createAsyncThunk(
    'wasteTypes/newWasteTypes',
    async (data: IWasteTypeDTO) => await createNewWasteType(data)
);

export const updateWasteTypes = createAsyncThunk(
    'wasteTypes/updateWasteTypes',
    async ({id, data}: UpdateWasteTypePayload) => await patchWasteType(id, data)
);

export const deleteWasteType = createAsyncThunk(
    'wasteTypes/deleteWasteTypes',
    async (id: string) => await removeWasteType(id)
);

export const addProgramToWasteType = createAsyncThunk(
    'wasteTypes/addProgram',
    async ({type_id, data}: AddProgramToWasteTypePayload, {dispatch}) => {
        try {
            dispatch(addProgram({type_id, data}));
            return await addProgramToWasteTypeApi(type_id, data);
        } catch (error) {
            dispatch(removeProgram({type_id, data}));
            throw error;
        }
    }
);

export const removeProgramFmWasteType = createAsyncThunk(
    'wasteTypes/removeProgram',
    async ({type_id, data}: RemoveProgramFmWasteTypePayload, {dispatch}) => {
        try {
            dispatch(removeProgram({type_id, data}));
            return await removeProgramFmWasteTypeApi(type_id, data);
        } catch (error) {
            dispatch(addProgram({type_id, data}));
            throw error;
        }
    }
);

export const wasteTypesSlice = createSlice({
    name: 'wasteTypes',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
        addProgram: (state, action: PayloadAction<AddProgramToWasteTypePayload>) => {
            const {type_id, data} = action.payload;
            const index = getArrayIndex(state.wasteTypesData, type_id);
            state.wasteTypesData[index].programs.push(data.programId);
        },
        removeProgram: (state, action: PayloadAction<RemoveProgramFmWasteTypePayload>) => {
            const {type_id, data} = action.payload;
            const index = getArrayIndex(state.wasteTypesData, type_id);
            state.wasteTypesData[index].programs = state.wasteTypesData[index].programs.filter(
                (p) => p !== data.programId
            );
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadWasteTypes.fulfilled, (state, action) => {
                state.wasteTypesData = action.payload;
                state.loaded = true;
                state.error = null;
            })
            .addCase(loadWasteTypes.rejected, (state, action) => {
                state.loaded = true;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(newWasteTypes.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newWasteTypes.fulfilled, (state, action) => {
                state.isSubmitting = false;
                state.error = null;
                state.wasteTypesData.push(action.payload);
            })
            .addCase(newWasteTypes.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(updateWasteTypes.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateWasteTypes.fulfilled, (state, action) => {
                const {id} = action.payload;
                const currentIndex = getArrayIndex(state.wasteTypesData, id);

                state.isSubmitting = false;
                state.error = null;
                state.wasteTypesData[currentIndex] = action.payload;
            })
            .addCase(updateWasteTypes.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(deleteWasteType.fulfilled, (state, action) => {
                const id = action.meta.arg;

                state.error = null;
                state.isSubmitting = false;
                state.wasteTypesData = state.wasteTypesData.filter((data) => data.id !== id);
            })
            .addCase(addProgramToWasteType.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(removeProgramFmWasteType.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            });
    },
});

export const {clearError, addProgram, removeProgram} = wasteTypesSlice.actions;

export const wasteTypes = (state: RootState) => state.wasteType.wasteTypesData;
export const wasteTypesError = (state: RootState) => state.wasteType.error;
export const wasteTypesLoaded = (state: RootState) => state.wasteType.loaded;
export const wasteTypesIsSubmitting = (state: RootState) => state.wasteType.isSubmitting;

export const useWasteTypesData = () => useReduxData(wasteTypes, wasteTypesLoaded, loadWasteTypes);

export default wasteTypesSlice.reducer;
