import {Modal} from '../../UI/Modal';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Input} from '../../UI/Input';

import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {initial, IWasteTypeForm, validationSchema} from './formModel';
import {FIELD_LABELS} from '../../../constants';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {Textarea} from '../../UI/Input/Textarea';
import {
    newWasteTypes,
    updateWasteTypes,
    wasteTypesError,
    wasteTypesIsSubmitting,
} from '../../../store/wasteType/wasteTypeSlice';
import {IWasteType} from '../../../models/wasteType';

type WasteTypeModalProps = {
    wasteType?: IWasteType;
    programId?: string;
    closeModal: VoidFunction;
};

const WasteTypeModal = ({wasteType, programId, closeModal}: WasteTypeModalProps) => {
    const dispatch = useAppDispatch();
    const formError = useAppSelector(wasteTypesError);

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

    const initialFormValue: IWasteTypeForm = wasteType ? wasteType : initial;

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IWasteTypeForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialFormValue,
    });

    const isSubmitting = useAppSelector(wasteTypesIsSubmitting);

    const modalTitle = !wasteType ? 'New Waste Type' : 'Edit Waste Type';
    const actionButtonTitle = !wasteType ? ADD_TO_LIST_LABEL : SAVE_CHANGES_LABEL;

    const onActionClick = (formValues: IWasteTypeForm) => {
        if (!wasteType) {
            dispatch(newWasteTypes({...formValues, programId}))
                .unwrap()
                .then(() => closeModal());
        } else {
            dispatch(updateWasteTypes({id: wasteType.id, data: {...formValues}}))
                .unwrap()
                .then(() => closeModal());
        }
    };

    return (
        <Modal title={modalTitle}>
            <ModalBody>
                <Input
                    label="Title"
                    placeholder="Type here"
                    required
                    error={!!errors.title}
                    errorMessage={errors.title?.message}
                    {...register('title')}
                />
                <Textarea
                    label="Description"
                    placeholder="Type here"
                    error={!!errors.description}
                    errorMessage={errors.description?.message}
                    {...register('description')}
                />
                {formError && <ErrorMessage message={formError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    type="submit"
                    onClick={handleSubmit(onActionClick)}
                    disabled={isSubmitting}
                    label={actionButtonTitle}
                />
                <TextButton variant="Danger" label={CANCEL_LABEL} onClick={closeModal} />
            </ModalActions>
        </Modal>
    );
};

export default WasteTypeModal;
