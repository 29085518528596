import {ISiteType, ISiteTypeDTO} from '../models/siteType';
import apiClient from './client';

export const getSiteTypes = async (): Promise<ISiteType[]> => {
    try {
        const response = await apiClient.get<ISiteType[]>('/site-types');
        return response.data;
    } catch (e) {
        throw new Error('Failed to fetch data');
    }
};

export const createNewSiteType = async (data: ISiteTypeDTO): Promise<ISiteType> => {
    const {title} = data;

    try {
        const response = await apiClient.post<ISiteType>('/site-types', {title});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchSiteType = async (data: ISiteTypeDTO): Promise<ISiteType> => {
    const {title, id} = data;

    try {
        const response = await apiClient.patch<ISiteType>(`/site-types/${id}`, {title});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeSiteType = async (id: string): Promise<void> => {
    try {
        await apiClient.delete(`/site-types/${id}`);
    } catch (error) {
        throw error;
    }
};
