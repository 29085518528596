import React, {FC, useRef, useState, Children, cloneElement} from 'react';
import {useOnOutsideClick} from '../../../hooks/useOnOutsideClick';
import {IconButton} from '../Button/Icon';
import s from './index.module.scss';

interface IProps {
    children: React.ReactNode;
}

export const MoreMenu: FC<IProps> = ({children}) => {
    const [open, setOpen] = useState(false);
    const menuContainerRef = useRef<HTMLDivElement>(null);

    useOnOutsideClick(menuContainerRef, open, () => {
        setOpen(false);
    });

    return (
        <div className={s.menu} ref={menuContainerRef}>
            <IconButton icon="More" onClick={() => setOpen(!open)} variant="Secondary" />
            {open && (
                <ul>
                    {Children.map(children, (child: any) =>
                        cloneElement(child, {closeMenuCb: () => setOpen(false)})
                    )}
                </ul>
            )}
        </div>
    );
};
