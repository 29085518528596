import * as Yup from 'yup';
import {FIELD_ERRORS} from '../../../constants';

export interface ITeamForm {
    name: string;
    numOfVounteers?: number;
    notes?: string;
}

export const initial: ITeamForm = {name: ''};

export const validationSchema = Yup.object({
    name: Yup.string().required(FIELD_ERRORS.REQUIRED),
    numOfVounteers: Yup.number()
        .positive(FIELD_ERRORS.POSITIVE_NUM)
        .nullable(true)
        .transform((_, val) => (val ? Number(val) : null)),
    notes: Yup.string().optional(),
});
