import {RemovableChip} from '../../UI/Chips/RemovableChip';
import {MoreMenu} from '../../UI/MoreMenu';
import s from './index.module.scss';
import ListItemDescription from '../ListItem/ListItemDescription';
import ListItemDetails from '../ListItem/ListItemDetails';
import {MoreMenuItem} from '../../UI/MoreMenu/MoreMenuItem';

interface IListItemChild {
    id: string;
    name: string;
}

interface IMoreMenuItem {
    label: string;
    action: VoidFunction;
}

type ListItemWithChildrenProps = {
    title: string;
    listChildId: string;
    key: string;
    description?: string;
    details?: string[];
    menuActions: IMoreMenuItem[];
    items?: IListItemChild[];
    onRemoveChip?: (listItemChildId: string, itemId: string) => void;
};

export const ListItemWithChildren = ({
    title,
    listChildId,
    description,
    details,
    menuActions,
    items,
    onRemoveChip,
}: ListItemWithChildrenProps) => {
    return (
        <div className={s.listItem}>
            <div className={s.header}>
                <div className={s.title}>
                    <div>{title}</div>
                    {description && <ListItemDescription description={description} />}
                    {details && <ListItemDetails details={details} />}
                </div>
                <MoreMenu>
                    {menuActions.map((item) => (
                        <MoreMenuItem key={item.label} label={item.label} onClick={item.action} />
                    ))}
                </MoreMenu>
            </div>
            <div className={s.children}>
                {items && items.length > 0 ? (
                    items.map((item) => (
                        <RemovableChip
                            onClick={() => onRemoveChip && onRemoveChip(listChildId, item.id)}
                            key={item.id}
                            label={item.name}
                        />
                    ))
                ) : (
                    <i>No items added</i>
                )}
            </div>
        </div>
    );
};
