import {ButtonHTMLAttributes, FC} from 'react';
import s from './index.module.scss';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'Primary' | 'Secondary' | 'Tertiary' | 'Success' | 'Danger';
    label: string;
}

export const Button: FC<IProps> = ({variant = 'Primary', label, ...rest}) => {
    const buttonVariants = () =>
        `${variant === 'Primary' && s.primary} ${variant === 'Secondary' && s.secondary} ${
            variant === 'Tertiary' && s.tertiary
        } ${variant === 'Success' && s.success} ${variant === 'Danger' && s.danger}`;
    return (
        <button className={`${s.solid} ${buttonVariants()}`} {...rest}>
            {label}
        </button>
    );
};
