import {FC} from 'react';
import {View, StyleSheet, Text} from '@react-pdf/renderer';
import Table from './Table';
import {IPDFUsedResources} from '../../../models/pdfReport';

const styles = StyleSheet.create({
    empty: {
        padding: 8,
        fontFamily: 'Manrope',
        fontWeight: 'normal',
        fontSize: 14,
    },
});

interface IProps {
    data: IPDFUsedResources[];
}

const LocationResources: FC<IProps> = ({data}) => {
    return (
        <View style={{marginTop: 24}}>
            <Table
                title="USED RESOURCES"
                mode="grid"
                rows={data.map((item) => ({title: item.name, info: `${item.items} items`}))}
            />
            {data.length === 0 && <Text style={styles.empty}>No resources used</Text>}
        </View>
    );
};

export default LocationResources;
