export enum WeatherTypes {
    NUMBER = 'number',
    LIST = 'list',
}

interface IOption {
    id: string;
    title: string;
    created_at: string;
    updated_at: string;
}

export interface IWeatherCondition {
    id: string;
    title: string;
    unit: string;
    type: WeatherTypes;
    updated_at: string;
    programs: string[];
    options: IOption[];
}

export interface IWeatherConditionDTO {
    title: string;
    unit?: string;
    type: WeatherTypes;
    programId?: string;
}

export interface IAddProgramToWeatherConditionDTO {
    programId: string;
}

export interface IRemoveProgramToWeatherConditionDTO extends IAddProgramToWeatherConditionDTO {}
