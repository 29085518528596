import {useMemo} from 'react';
import {ICollectedWeight} from '../models/cleanUp';
import {useWasteTypesData} from '../store/wasteType/wasteTypeSlice';
import {BoardData} from '../components/bloc/DailyReport/CleanUp/CleanUpBoard';

export const useCleanUpCollectedWeight = (cleanUpWeights?: ICollectedWeight[]) => {
    const [wasteTypes, loaded] = useWasteTypesData();

    const collectedWeightData = useMemo(() => {
        const total = cleanUpWeights?.reduce((acc, curr) => acc + (curr?.dry + curr?.wet), 0);

        const currentWeights = cleanUpWeights?.map((it) => ({
            label: wasteTypes.find((wt) => wt.id === it.waste_type_id)?.title,
            info: `${it.wet + it.dry} kg`,
        }));

        let collectedWeightData: BoardData[] = [];

        if (currentWeights) {
            collectedWeightData = [
                {
                    label: 'Total',
                    info: `${total} kg`,
                },
                ...currentWeights,
            ];
        }

        return {data: collectedWeightData, total};
    }, [wasteTypes, cleanUpWeights]);

    return [collectedWeightData, loaded] as const;
};
