import {createContext, FC, useContext, useReducer} from 'react';
import {NotificationsContainer} from '../../components/UI/Modal';
import {NotificationVariant} from '../../components/UI/Modal/ModalBuildingBlocks';

type NotificationActions =
    | {
          type: 'ADD';
          snack: {type: NotificationVariant; message: string};
      }
    | {type: 'REMOVE'; id: number};

export interface INotificationState {
    id: number;
    type: NotificationVariant;
    message: string;
}

interface INotificationBarState {
    id: number;
    snaks: INotificationState[];
}

const initialState: INotificationBarState = {
    id: 1,
    snaks: [],
};

const reducer = (state: INotificationBarState, action: NotificationActions) => {
    switch (action.type) {
        case 'ADD':
            return {
                id: state.id + 1,
                snaks: [{id: state.id, ...action.snack} as INotificationState, ...state.snaks],
            };
        case 'REMOVE':
            return {
                ...state,
                snaks: [...state.snaks.filter((s) => s.id !== action.id)],
            };

        default:
            return state;
    }
};

type NotificationsContextType = {
    addNotification: (string: string, variant?: NotificationVariant) => number;
    removeNotification: (id: number) => void;
};

export const NotificationsContext = createContext<NotificationsContextType>(
    {} as NotificationsContextType
);

export const useNotification = () => {
    return useContext(NotificationsContext);
};

interface IProps {
    children: React.ReactNode;
}

export const SnackBarProvider: FC<IProps> = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const addNotification = (message: string, variant: NotificationVariant = 'success') => {
        dispatch({type: 'ADD', snack: {message, type: variant}});
        return state.id;
    };

    const removeNotification = (id: number) => {
        dispatch({type: 'REMOVE', id});
    };

    const value = {
        addNotification,
        removeNotification,
    };

    return (
        <NotificationsContext.Provider value={value}>
            {children}
            <NotificationsContainer notifications={state.snaks} />
        </NotificationsContext.Provider>
    );
};
