import {FC} from 'react';
import {View} from '@react-pdf/renderer';
import Table from './Table';
import {IPDFPCleanUp} from '../../../models/pdfReport';
import {coordinatesToString} from '../../../utils/cordinates_helpers';
import {durationToString} from '../../../utils/date_helpers';

interface IProps {
    data: IPDFPCleanUp;
}

const LocationBasicInfo: FC<IProps> = ({data}) => {
    const coordinates = coordinatesToString(data.location);
    const duration = durationToString(data.duration);

    const formater = new Intl.NumberFormat('en', {maximumFractionDigits: 3});

    return (
        <View>
            <Table
                title="BASIC INFO"
                mode="grid"
                rows={[
                    {title: 'Coordinates', info: coordinates},
                    {title: 'Linked Activity', info: data.activityName || '-'},
                    {title: 'Number of Participants', info: data.num_of_participant || '-'},
                    {title: 'Team', info: data.teamName || '-'},
                    {title: 'Area', info: data.area ? `${formater.format(data.area)} m^2` : '-'},
                    {
                        title: 'Lenght',
                        info: data.length ? `${formater.format(data.length)} m` : '-',
                    },
                    {title: 'Duration', info: duration || '-'},
                    {title: 'Added By', info: data.creatorName || '-'},
                ]}
            />
        </View>
    );
};

export default LocationBasicInfo;
