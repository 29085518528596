import {initial, IWeatherConditionForm, validationSchema} from './formModel';
import {Modal} from '../../UI/Modal';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {Input} from '../../UI/Input';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {yupResolver} from '@hookform/resolvers/yup';
import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {Button} from '../../UI/Button';
import {FIELD_LABELS} from '../../../constants';
import {TextButton} from '../../UI/Button/Text';
import {IWeatherCondition, WeatherTypes} from '../../../models/weatherCondition';
import {
    newWeatherCondition,
    updateWeatherCondition,
    weatherConditionsError,
    weatherConditionsIsSubmitting,
} from '../../../store/weatherCondition/weatherCondition';

type WeatherConditionModalProps = {
    weatherCondition?: IWeatherCondition;
    isNumberInput: boolean;
    programId?: string;
    closeModal: () => void;
};

const WeatherConditionModal = ({
    weatherCondition,
    closeModal,
    isNumberInput,
    programId,
}: WeatherConditionModalProps) => {
    const dispatch = useAppDispatch();

    const serverError = useAppSelector(weatherConditionsError);
    const isSubmitting = useAppSelector(weatherConditionsIsSubmitting);

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

    let modalTitle = !weatherCondition ? 'New List Input' : 'Edit List Input Type';

    if (!weatherCondition && isNumberInput) modalTitle = 'New Number Input';
    if (weatherCondition && isNumberInput) modalTitle = 'Edit Number Input';

    const actionButtonTitle = !weatherCondition ? ADD_TO_LIST_LABEL : SAVE_CHANGES_LABEL;

    const initialFormValues = weatherCondition ? weatherCondition : initial;

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IWeatherConditionForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialFormValues,
    });

    const onActionClick = (formData: IWeatherConditionForm) => {
        const {id: weatherId, ...data} = formData;

        const newPayLoad = {
            ...data,
            type: isNumberInput ? WeatherTypes.NUMBER : WeatherTypes.LIST,
            ...(programId && {programId}),
        };

        const action = !weatherCondition
            ? dispatch(newWeatherCondition(newPayLoad))
            : dispatch(
                  updateWeatherCondition({
                      weatherId,
                      data: {
                          title: data.title,
                          unit: data.unit,
                      },
                  })
              );

        action
            .unwrap()
            .then(() => closeModal())
            .catch(({message}) => console.log(message));
    };

    return (
        <Modal title={modalTitle}>
            <ModalBody>
                <Input
                    label="Name"
                    placeholder="Type here"
                    required
                    error={!!errors.title}
                    errorMessage={errors.title?.message}
                    {...register('title')}
                />
                {isNumberInput && (
                    <Input
                        label="Unit"
                        placeholder="Type here"
                        error={!!errors.unit}
                        errorMessage={errors.unit?.message}
                        {...register('unit')}
                    />
                )}
                {serverError && <ErrorMessage message={serverError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleSubmit(onActionClick)}
                    label={actionButtonTitle}
                />
                <TextButton
                    type="button"
                    variant="Danger"
                    label={CANCEL_LABEL}
                    onClick={() => closeModal()}
                />
            </ModalActions>
        </Modal>
    );
};

export default WeatherConditionModal;
