import {FC} from 'react';
import {Location, Navigate} from 'react-router-dom';

interface IProps {
    from?: Location;
}

const RedirectToLogin: FC<IProps> = ({from}) => {
    return <Navigate to="/auth/login" state={{from}} replace />;
};

export default RedirectToLogin;
