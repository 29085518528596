import {FC} from 'react';
import {StyleSheet, Document, Page, View, Font} from '@react-pdf/renderer';
import Header from './Header';
import ProjectTotals from './ProjectTotals';
import PageCounter from './PageCounter';
import LocationGallery from './LocationGallery';
import LocationsNotes from './LocationsNotes';
import LocationResources from './LocationResources';
import LocationCollectedWeight from './LocationCollectedWeight';
import LocationBasicInfo from './LocationBasicInfo';
import LocationHeader from './LocationHeader';
import {IPDFReportData} from '../../../models/pdfReport';
import {format} from 'date-fns';

const regular = require('../../../assets/fonts/Manrope-Regular.ttf');
const medium = require('../../../assets/fonts/Manrope-Medium.ttf');
const light = require('../../../assets/fonts/Manrope-Light.ttf');
const extraLight = require('../../../assets/fonts/Manrope-ExtraLight.ttf');
const semiBold = require('../../../assets/fonts/Manrope-SemiBold.ttf');
const bold = require('../../../assets/fonts/Manrope-Bold.ttf');
const extraBold = require('../../../assets/fonts/Manrope-ExtraBold.ttf');

Font.register({
    family: 'Manrope',
    fonts: [
        {src: regular},
        {src: medium, fontWeight: 500},
        {src: light, fontWeight: 300},
        {src: extraLight, fontWeight: 200},
        {src: semiBold, fontWeight: 600},
        {src: bold, fontWeight: 700},
        {src: extraBold, fontWeight: 800},
    ],
});

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: '20pt',
    },
});

interface IProps {
    data: IPDFReportData;
}

const DailyReportPDF: FC<IProps> = ({data}) => {
    return (
        <Document title={`Report of ${format(new Date(data.date), 'MMMM do, yyyy')}`}>
            <Page size="A4" style={styles.page}>
                <Header date={data.date} />
                {data.projects.map((project, index) => (
                    <View key={project.name} break={index !== 0}>
                        <ProjectTotals data={project} />
                        {project.cleanUps.map((cleanUp) => (
                            <View key={cleanUp.id} break>
                                <LocationHeader data={cleanUp} />
                                <LocationBasicInfo data={cleanUp} />
                                <LocationCollectedWeight data={cleanUp} />
                                <LocationResources data={cleanUp.usedResources} />
                                <LocationsNotes notes={cleanUp.notes} />
                                <LocationGallery spots={cleanUp.images} />
                            </View>
                        ))}
                    </View>
                ))}

                <PageCounter />
            </Page>
        </Document>
    );
};

export default DailyReportPDF;
