import {Modal} from '../../UI/Modal';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Input} from '../../UI/Input';
import {
    newSiteType,
    siteTypeIsSubmitting,
    siteTypesError,
    updateSiteType,
} from '../../../store/siteType/siteTypeSlice';

import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {initial, ISiteTypeForm, validationSchema} from './formModel';
import {FIELD_LABELS} from '../../../constants';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';

type SiteTypeModalProps = {
    siteType?: ISiteTypeForm;
    closeModal: VoidFunction;
};

const SiteTypeModal = ({siteType, closeModal}: SiteTypeModalProps) => {
    const dispatch = useAppDispatch();
    const formError = useAppSelector(siteTypesError);

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

    const initialFormValue: ISiteTypeForm = siteType ? siteType : initial;

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<ISiteTypeForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialFormValue,
    });

    const isSubmitting = useAppSelector(siteTypeIsSubmitting);

    const modalTitle = !siteType ? 'New Clean Up Site Type' : 'Edit Clean Up Site Type';
    const actionButtonTitle = !siteType ? ADD_TO_LIST_LABEL : SAVE_CHANGES_LABEL;

    const onActionClick = (formValues: ISiteTypeForm) => {
        const action = !siteType ? newSiteType : updateSiteType;

        dispatch(action(formValues))
            .unwrap()
            .then(() => closeModal())
            .catch(({message}) => console.log(message));
    };

    return (
        <Modal title={modalTitle}>
            <ModalBody>
                <Input
                    label="Name"
                    placeholder="Type here"
                    required
                    error={!!errors.title}
                    errorMessage={errors.title?.message}
                    {...register('title')}
                />
                {formError && <ErrorMessage message={formError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    type="submit"
                    onClick={handleSubmit(onActionClick)}
                    disabled={isSubmitting}
                    label={actionButtonTitle}
                />
                <TextButton variant="Danger" label={CANCEL_LABEL} onClick={closeModal} />
            </ModalActions>
        </Modal>
    );
};

export default SiteTypeModal;
