import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Icon} from '../../components/UI/Icon';
import {Input} from '../../components/UI/Input';
import {Button} from '../../components/UI/Button';
import {TextButton} from '../../components/UI/Button/Text';
import {ErrorMessage} from '../../components/UI/ErrorMessage';
import {FIELD_ERRORS} from '../../constants';
import s from './index.module.scss';
import {resetPassword} from '../../api/auth';
import {useNotification} from '../../store/notification/NotificationContext';

interface IResetPassword {
    newPassword: string;
    repeatNewPassword: string;
}

const intitial: IResetPassword = {newPassword: '', repeatNewPassword: ''};

const schema = yup.object({
    newPassword: yup
        .string()
        .required(FIELD_ERRORS.REQUIRED)
        .test('len', FIELD_ERRORS.MIN_CHARACTERS, (value) => (value?.length || 0) >= 6),
    repeatNewPassword: yup
        .string()
        .required(FIELD_ERRORS.REQUIRED)
        .test(
            'passwords-match',
            FIELD_ERRORS.PASS_DONT_MATCH,
            (value, ctx) => value === ctx.parent.newPassword
        ),
});

const ActionsPage = () => {
    const [params] = useSearchParams();
    const {addNotification} = useNotification();
    const navigate = useNavigate();
    const [error, setError] = useState<string>();
    const [submiting, setSubmiting] = useState(false);
    const mode = params.get('mode');
    const oobCode = params.get('oobCode');

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IResetPassword>({defaultValues: intitial, resolver: yupResolver(schema)});

    const reset = async (data: IResetPassword) => {
        try {
            setSubmiting(true);
            await resetPassword(data.newPassword, oobCode!);
            addNotification('Password Reset Successful', 'success');
            navigate('/auth/login');
        } catch (error: any) {
            setSubmiting(false);
            setError(error.message);
        }
    };

    return (
        <div className={s.container}>
            <Icon name="Logo" viewbox="0 0 72 72" size="160" variant="secondaryAccent" />
            {mode === 'resetPassword' && oobCode != null && (
                <form className={s.form} onSubmit={handleSubmit(reset)}>
                    <div className={s.form}>
                        <Input
                            type="password"
                            label="New Password"
                            required
                            error={!!errors.newPassword}
                            errorMessage={errors.newPassword?.message}
                            {...register('newPassword')}
                        />
                        <Input
                            type="password"
                            label="Repeat New Password"
                            required
                            error={!!errors.repeatNewPassword}
                            errorMessage={errors.repeatNewPassword?.message}
                            {...register('repeatNewPassword')}
                        />
                        {error && <ErrorMessage message={error} />}
                    </div>
                    <div className={s.actions}>
                        <Button
                            label="Submit"
                            variant="Primary"
                            type="submit"
                            disabled={submiting}
                        />
                        <TextButton
                            type="button"
                            label="Back to Login"
                            variant="Secondary"
                            onClick={() => navigate('/auth/login')}
                        />
                    </div>
                </form>
            )}
        </div>
    );
};

export default ActionsPage;
