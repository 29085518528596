import {useMemo} from 'react';
import {useUserData} from '../store/user/userSlice';

export const useCollectorName = (collectorId?: string) => {
    const [users, loaded] = useUserData();

    const collectorName = useMemo(() => {
        const collector = users.find((us) => us.id === collectorId);

        return `${collector?.first_name} ${collector?.last_name}`;
    }, [users, collectorId]);

    return [collectorName, loaded] as const;
};
