import {FC} from 'react';
import {Svg, Path} from '@react-pdf/renderer';

interface IProps {
    color?: 'black' | 'gray';
    size?: number;
}

const Logo: FC<IProps> = ({color = 'black', size = 72}) => {
    return (
        <Svg style={{width: size, height: size}} viewBox="0 0 72 72">
            <Path
                fill={color === 'gray' ? '#002342' : 'black'}
                d="M6 14.1646V22.3291H8.11538H10.2308V20.8101V19.2911H14.4615H18.6923V20.8101V22.3291H20.6154H22.5385V14.1646V6H14.2692H6V14.1646ZM49.8462 14.1646V22.3291H57.9231H66V20.4304V18.5316H59.8335H53.6669L53.7758 14.2595L53.8846 9.98734L59.9423 9.88291L66 9.7781V7.88924V6H57.9231H49.8462V14.1646ZM28.3077 14.3544V22.3291H36.3846H44.4615V14.3544V6.37975H42.5385H40.6154V12.4557V18.5316H36.3846H32.1538V12.4557V6.37975H30.2308H28.3077V14.3544ZM18.6923 12.2658V14.7342H14.4615H10.2308V12.2658V9.79747H14.4615H18.6923V12.2658ZM6.38462 36.1899V44.3544H14.6538H22.9231V36.1899V28.0253H21H19.0769V34.1013V40.1772H14.6538H10.2308V34.1013V28.0253H8.30769H6.38462V36.1899ZM27.9231 36V43.9747H30.0385H32.1538V37.8987V31.8228H36.3846H40.6154V37.8987V43.9747H42.5385H44.4615V36V28.0253H36.1923H27.9231V36ZM49.8462 29.924V31.8228H56H62.1538V36V40.1772H56H49.8462V42.2658V44.3544H57.9231H66V36.1899V28.0253H57.9231H49.8462V29.924ZM6 57.8354V66H14.2692H22.5385V64.1013V62.2025H16.3846H10.2308V55.9367V49.6709H8.11538H6V57.8354ZM27.9231 57.8354V66H36.1923H44.4615V64.1108V62.2215L38.2115 62.1171L31.9615 62.0127V57.8354V53.6582L38.2115 53.5538L44.4615 53.4494V51.5601V49.6709H36.1923H27.9231V57.8354ZM49.8462 57.6456V65.6203H51.7508H53.6558L53.77 63.2468L53.8846 60.8734L58.0192 60.7656L62.1538 60.6577V59.1516V57.6456H57.9031H53.6519L53.7685 55.6519L53.8846 53.6582L59.9423 53.5538L66 53.449V51.5601V49.6709H57.9231H49.8462V57.6456Z"
            />
        </Svg>
    );
};

export default Logo;
