import classes from './Details.module.scss';
import {RemovableChip} from '../../../UI/Chips/RemovableChip';
import {CustomLocation} from './RowItem';
import {useNavigate} from 'react-router-dom';

type DetailsProps = {
    header: string;
    subtitle: string;
    data: string[] | CustomLocation[];
    clickable?: boolean;
};

const Details = ({header, subtitle, data, clickable = false}: DetailsProps) => {
    const navigate = useNavigate();

    const handleChipClick = (item: CustomLocation) => {
        clickable && navigate(`/daily-reports/clean-up/${item.id}`);
    };

    return (
        <div className={classes.rowContainer}>
            <h3>{header}</h3>
            <p>{subtitle}</p>
            <div className={classes.chipContainer}>
                {data.map((item, idx) => {
                    const label =
                        typeof item === 'string' ? item : item.location_name ?? 'Unnamed  Location';

                    return (
                        <RemovableChip
                            key={idx}
                            onChipClick={() => handleChipClick(item as CustomLocation)}
                            label={label}
                            clickable={clickable}
                            readOnly
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Details;
