import {useParams} from 'react-router-dom';
import {useProgramsData} from '../store/programs/programsSlice';

const useProgramFromParams = () => {
    const [programs] = useProgramsData();
    const {programId} = useParams<{programId: string}>();
    const program = programs.find((o) => o.id === programId);

    return program;
};

export default useProgramFromParams;
