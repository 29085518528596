import {FC} from 'react';
import {View, Text, StyleSheet} from '@react-pdf/renderer';
import ImageSpot from './ImageSpot';
import {ISpot} from '../../../models/cleanUp';

const styles = StyleSheet.create({
    container: {
        marginTop: 24,
    },
    header: {
        fontFamily: 'Manrope',
        fontWeight: 'bold',
        fontSize: 14,
    },
    spots: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    empty: {
        padding: 8,
        fontFamily: 'Manrope',
        fontWeight: 'normal',
        fontSize: 14,
    },
});

interface IProps {
    spots: ISpot[];
}

const LocationGallery: FC<IProps> = ({spots}) => {
    return (
        <View style={styles.container}>
            <Text style={styles.header}>PHOTOS</Text>
            {spots.length > 0 && (
                <View style={styles.spots}>
                    {spots.map((s) => (
                        <ImageSpot key={s.id} spot={s} />
                    ))}
                </View>
            )}
            {spots.length === 0 && <Text style={styles.empty}>No photos added</Text>}
        </View>
    );
};

export default LocationGallery;
