import {FC, useState, ReactNode} from 'react';
import {Button} from '../../UI/Button';
import {IconButton} from '../../UI/Button/Icon';
import {Divider} from '../../UI/Divider';
import s from './index.module.scss';

interface IProps {
    title: string;
    children?: ReactNode;
    onSave?: VoidFunction;
    onAddNew?: VoidFunction;
    readOnly?: boolean;
}

export const DataConfigCard: FC<IProps> = ({
    children,
    title,
    onAddNew,
    onSave,
    readOnly = false,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={`${s.dataConfigCard} ${readOnly && s.readOnly}`}>
            <div className={`${s.cardHeader} ${!open && s.closed}`}>
                <div className={s.title}>{title}</div>
                <IconButton
                    icon={open ? 'ChevronUp' : 'ChevronDown'}
                    onClick={() => setOpen(!open)}
                />
            </div>
            {open && (
                <>
                    <Divider />
                    <div className={s.cardContent}>{children}</div>
                    <div className={s.cardContentActions}>
                        {onAddNew && (
                            <Button
                                label="Add New"
                                variant="Secondary"
                                onClick={() => onAddNew()}
                            />
                        )}
                        {onSave && <Button label="Save Changes" onClick={() => onSave()} />}
                    </div>
                </>
            )}
        </div>
    );
};
