import s from '../index.module.scss';
import {AdditionalLocationInfoConfig} from './AdditionalLocationInfoConfig';
import {CleanUpSitesTypesConfig} from './CleanUpSitesTypesConfig';
import {SubstrateOptionsConfig} from './SubstrateOptionsConfig';

export const LocationConfigurationContent = () => {
    return (
        <div className={s.container}>
            <CleanUpSitesTypesConfig />
            <SubstrateOptionsConfig />
            <AdditionalLocationInfoConfig />
        </div>
    );
};
