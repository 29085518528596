import { WasteTypesConfig } from './WasteTypesConfig';
import { WasteCategoriesConfig } from './WasteCategoriesConfig';

import s from '../index.module.scss';

export const WasteConfigurationContent = () => {
  return (
    <div className={s.container}>
      <WasteTypesConfig />
      <WasteCategoriesConfig />
    </div>
  );
};
