import * as Yup from 'yup';
import {FIELD_ERRORS} from '../../../constants';

export interface IChangePasswordForm {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

export const initial: IChangePasswordForm = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
};

export const validationSchema = Yup.object({
    currentPassword: Yup.string().required(FIELD_ERRORS.REQUIRED),
    newPassword: Yup.string().required(FIELD_ERRORS.REQUIRED).min(6, FIELD_ERRORS.MIN_CHARACTERS),
    confirmNewPassword: Yup.string()
        .required(FIELD_ERRORS.REQUIRED)
        .test(
            'passwords-match',
            FIELD_ERRORS.PASS_DONT_MATCH,
            (value, ctx) => value === ctx.parent.newPassword
        ),
});
