import {useWasteTypesData} from '../../../store/wasteType/wasteTypeSlice';
import {useResources} from '../../../store/resource/resource';
import {useWeatherConditionsData} from '../../../store/weatherCondition/weatherCondition';
import {useSubstrateOptionsData} from '../../../store/substrateOptions/substrateOptionSlice';
import useProgramFromParams from '../../../hooks/useProgramFromParams';
import {useAdditionalInfoData} from '../../../store/additionalInfo/additionalInfoSlice';
import {ResourceType} from '../../../models/resource';

import ProgramWasteTypes from '../../../components/bloc/WasteTypes/ProgramWasteTypes';
import ProgramResources from '../../../components/bloc/Resources/ProgramResources';
import ProgramWheatherConditions from '../../../components/bloc/WeatherConditions/ProgramWheatherConditions';
import ProgramSubstrateOptions from '../../../components/bloc/SubstrateOptions/ProgramSubstrateOptions';
import ProgramAddtionalInfo from '../../../components/bloc/AdditionalInfo/ProgramAddtionalInfo';

import s from './index.module.scss';

export const ProgramDataConfigurationContent = () => {
    const program = useProgramFromParams();
    const [wasteTypes, wasteTypesLoaded] = useWasteTypesData();
    const [resources, resourcesLoaded] = useResources();
    const [conditions, conditionsLoaded] = useWeatherConditionsData();
    const [substrateOptions, substrateOptionsLoaded] = useSubstrateOptionsData();
    const [additionalInfo, additionalInfoLoaded] = useAdditionalInfoData();

    return (
        <div className={s.container}>
            {wasteTypesLoaded && program && (
                <ProgramWasteTypes waste_types={wasteTypes} program={program} />
            )}

            {resourcesLoaded && program && (
                <>
                    <ProgramResources
                        resources={resources.filter((r) => r.type === ResourceType.GENERAL)}
                        program={program}
                        type={ResourceType.GENERAL}
                    />
                    <ProgramResources
                        resources={resources.filter((r) => r.type === ResourceType.LITTER_BAG)}
                        program={program}
                        type={ResourceType.LITTER_BAG}
                    />
                </>
            )}
            {conditionsLoaded && program && (
                <ProgramWheatherConditions program={program} conditions={conditions} />
            )}
            {substrateOptionsLoaded && program && (
                <ProgramSubstrateOptions options={substrateOptions} program={program} />
            )}
            {additionalInfoLoaded && program && (
                <ProgramAddtionalInfo program={program} additional_info={additionalInfo} />
            )}
        </div>
    );
};
