import {forwardRef, Ref, useMemo, useRef, useState} from 'react';
import {useOnOutsideClick} from '../../../../hooks/useOnOutsideClick';
import {Icon} from '../../Icon';
import s from '../index.module.scss';

// TODO: Create dropdown with generic option type for received array of options...
export interface IOption {
    label: string;
    id: string;
}

interface IDropdownProps {
    name?: string;
    label?: string;
    required?: boolean;
    value?: string;
    options: IOption[];
    onChange?: (id: string) => void;
    error?: boolean;
    errorMessage?: string;
}

export const Dropdown = forwardRef(
    (
        {
            name,
            label,
            required,
            value,
            options,
            onChange,
            error,
            errorMessage,
            ...rest
        }: IDropdownProps,
        ref: Ref<HTMLDivElement>
    ) => {
        const dropdownContainerRef = useRef<HTMLDivElement>(null);
        const [focused, setFocused] = useState(false);

        const selectedObject = options.find((option) => option.id === value);

        const [inputValue, setInputValue] = useState(selectedObject ? selectedObject.label : '');

        const onFocus = () => {
            setFocused(true);
            setInputValue('');
        };

        useOnOutsideClick(dropdownContainerRef, focused, () => {
            setFocused(false);
            inputValue === '' && selectedObject && setInputValue(selectedObject.label);
        });

        const updateSelected = (option: IOption) => {
            setInputValue(option.label);
            setFocused(false);
            onChange && onChange(option.id);
        };

        const filteredOptions = useMemo(
            () =>
                options.filter((option) => {
                    const normalizedName = option.label.toLowerCase();
                    const normalizedValue = inputValue.toLowerCase();
                    return normalizedName.includes(normalizedValue);
                }),
            [options, inputValue]
        );

        return (
            <div className={`${s.wrapper}`} ref={ref}>
                <div className={s.feedbackArea}>
                    {label && (
                        <label className={`${error && s.error} ${required && s.required}`}>
                            {label}
                        </label>
                    )}
                    {error && errorMessage && <div className={s.message}>{errorMessage}</div>}
                </div>

                <div className={`${s.dropdown}`} ref={dropdownContainerRef}>
                    <div className={`${s.container} ${error && s.error}`}>
                        <input
                            placeholder={selectedObject ? selectedObject.label : 'Select'}
                            onFocus={onFocus}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            {...rest}
                        />
                        <button onClick={onFocus}>
                            <Icon
                                name={focused ? 'ChevronUp' : 'ChevronDown'}
                                size="20"
                                variant="onBackground"
                            />
                        </button>
                    </div>

                    {focused && (
                        <ul>
                            {filteredOptions.length > 0 ? (
                                filteredOptions.map((option) => (
                                    <li key={option.id}>
                                        <button onClick={() => updateSelected(option)}>
                                            {option.label}
                                        </button>
                                    </li>
                                ))
                            ) : (
                                <li className={s.noMatch}>No mathes found</li>
                            )}
                        </ul>
                    )}
                </div>
            </div>
        );
    }
);
