import {Role} from './models/user';

export enum FIELD_LABELS {
    ADD_TO_LIST_LABEL = 'Add to List',
    SAVE_CHANGES_LABEL = 'Save Changes',
    CANCEL_LABEL = 'Cancel',
    MODAL_CONFIRMATION = 'Are you sure?',
    DELETE_OBJECT = 'Deleting this object is an irreversible action!',
    SUCCESSFUL_DELETION = 'Successful Deletion',
    FAIL_DELETION = 'Deletion Failed',
    SUCCESSFUL_OPERATION = 'Successful Operation',
    FAIL_OPERATION = 'Operation Failed',
}

export enum FIELD_ERRORS {
    REQUIRED = 'Required Field',
    INVALID_EMAIL = 'Invalid email',
    MIN_CHARACTERS = 'At least six (6) characters are required',
    PASS_DONT_MATCH = "Passwords don't match",
    POSITIVE_NUM = 'Positive Number is required',
}

export enum ActivityStatus {
    DRAFT = 'draft',
    ONGOING = 'ongoing',
    COMPLETED = 'completed',
}

export enum UserStatusTypes {
    active = 'active',
    inactive = 'inactive',
}

export const ACTIVITIES_MENU = [
    {
        id: 'all',
        label: 'All Activities',
    },
    {
        id: ActivityStatus.ONGOING,
        label: 'Ongoing Activities',
    },
    {
        id: ActivityStatus.DRAFT,
        label: 'Drafts',
    },
    {
        id: ActivityStatus.COMPLETED,
        label: 'Completed Activities',
    },
];

export const ACTIVITY_OPTIONS = [
    {
        id: ActivityStatus.ONGOING,
        label: 'Ongoing',
    },
    {
        id: ActivityStatus.DRAFT,
        label: 'Draft',
    },
    {
        id: ActivityStatus.COMPLETED,
        label: 'Completed',
    },
];

export const DIRECTOR_USERS_MENU = [
    {
        id: 'all',
        label: 'All Users',
    },
    {
        id: Role.collector,
        label: 'Data Collectors',
    },
    {
        id: Role.admin,
        label: 'Admins',
    },
    {
        id: 'deactivated',
        label: 'Deactivated Users',
    },
];

export const ADMIN_USERS_MENU = [
    {
        id: 'all',
        label: 'All Users',
    },
    {
        id: 'deactivated',
        label: 'Deactivated Users',
    },
];

export const USER_STATUS_OPTIONS = [
    {
        id: UserStatusTypes.active,
        label: 'Active',
    },
    {
        id: UserStatusTypes.inactive,
        label: 'Inactive',
    },
];

export const USER_ROLE_OPTIONS = [
    {
        id: Role.admin,
        label: 'Administrator',
    },
    {
        id: Role.collector,
        label: 'Collector',
    },
];
