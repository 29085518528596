import {Modal} from '../../UI/Modal';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Input} from '../../UI/Input';

import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {initial, IWasteCategoryForm, validationSchema} from './formModel';
import {FIELD_LABELS} from '../../../constants';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {Textarea} from '../../UI/Input/Textarea';
import {
    newWasteCategory,
    UpdateWasteCategory,
    updateWasteCategory,
    wasteCategoriesError,
    wasteCategoriesIsSubmitting,
} from '../../../store/wasteCategory/wasteCategorySlice';
import {IWasteCategoryDTO} from '../../../models/wasteCategory';

type WasteCategoryModalProps = {
    wasteCategory?: IWasteCategoryForm;
    closeModal: VoidFunction;
};

const WasteCategoryModal = ({wasteCategory, closeModal}: WasteCategoryModalProps) => {
    const dispatch = useAppDispatch();
    const formError = useAppSelector(wasteCategoriesError);

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

    const initialFormValue: IWasteCategoryForm = wasteCategory ? wasteCategory : initial;

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IWasteCategoryForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialFormValue,
    });

    const isSubmitting = useAppSelector(wasteCategoriesIsSubmitting);

    const modalTitle = !wasteCategory ? 'New Waste Category' : 'Edit Waste Category';
    const actionButtonTitle = !wasteCategory ? ADD_TO_LIST_LABEL : SAVE_CHANGES_LABEL;

    const onActionClick = (formValues: IWasteCategoryForm) => {
        const {id, title, description} = formValues;

        const data: IWasteCategoryDTO = {title, description};
        const updatePayLoad: UpdateWasteCategory = {
            data,
            categoryId: id,
        };

        const action = !wasteCategory
            ? dispatch(newWasteCategory(data))
            : dispatch(updateWasteCategory(updatePayLoad));

        action
            .unwrap()
            .then(() => closeModal())
            .catch(({message}) => console.log(message));
    };

    return (
        <Modal title={modalTitle}>
            <ModalBody>
                <Input
                    label="Title"
                    placeholder="Type here"
                    required
                    error={!!errors.title}
                    errorMessage={errors.title?.message}
                    {...register('title')}
                />
                <Textarea
                    label="Description"
                    placeholder="Type here"
                    error={!!errors.description}
                    errorMessage={errors.description?.message}
                    {...register('description')}
                />
                {formError && <ErrorMessage message={formError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    type="submit"
                    onClick={handleSubmit(onActionClick)}
                    disabled={isSubmitting}
                    label={actionButtonTitle}
                />
                <TextButton variant="Danger" label={CANCEL_LABEL} onClick={closeModal} />
            </ModalActions>
        </Modal>
    );
};

export default WasteCategoryModal;
