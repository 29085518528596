import {IMonthReport} from '../models/daily-report';
import apiClient from './client';

export const loadMonthView = async (date: string): Promise<IMonthReport> => {
    try {
        const response = await apiClient.get<IMonthReport>('daily-report/month', {params: {date}});
        return response.data;
    } catch (error) {
        throw error;
    }
};
