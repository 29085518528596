import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {useAuthData} from '../../../store/auth/authSlice';
import {newUser, updateUser, userError, usersIsSubmitting} from '../../../store/user/userSlice';
import {IUser, IUserDTO, Role} from '../../../models/user';

import {Modal} from '../../UI/Modal';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Input} from '../../UI/Input';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {Dropdown} from '../../UI/Input/Dropdown';

import {initial, IUserForm, validationSchema} from './formModel';
import {
    FIELD_LABELS,
    USER_STATUS_OPTIONS,
    USER_ROLE_OPTIONS,
    UserStatusTypes,
} from '../../../constants';

type UserModalProps = {
    user?: IUser;
    programId?: string;
    teamId?: string;
    closeModal: VoidFunction;
};

const UserModal = ({user, programId, teamId, closeModal}: UserModalProps) => {
    const [currentUser] = useAuthData();
    const dispatch = useAppDispatch();
    const formError = useAppSelector(userError);

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

    const formUser = user && {
        id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        phone: user.phone,
        role: user.role,
        is_active: user && user.is_active ? UserStatusTypes.active : UserStatusTypes.inactive,
    };

    const initialFormValue = user ? formUser : initial;

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<IUserForm>({
        resolver: yupResolver(validationSchema(currentUser!.role)),
        defaultValues: initialFormValue,
    });

    const isSubmitting = useAppSelector(usersIsSubmitting);

    const modalTitle = !user ? 'New User' : 'Edit User Info';
    const actionButtonTitle = !user ? ADD_TO_LIST_LABEL : SAVE_CHANGES_LABEL;

    const onActionClick = (formValues: IUserForm) => {
        const {id, ...userDTOValues} = formValues;

        const newUserPayLoad: IUserDTO = {
            ...userDTOValues,
            phone: userDTOValues.phone || '',
            is_active: userDTOValues.is_active === UserStatusTypes.active,
            ...(programId && {programId}),
            ...(teamId && {teamId}),
        };

        const {email, ...updatePayLoad} = newUserPayLoad;

        const action = !user
            ? dispatch(newUser(newUserPayLoad))
            : dispatch(
                  updateUser({
                      data: updatePayLoad,
                      userId: user.id,
                  })
              );

        action
            .unwrap()
            .then(() => closeModal())
            .catch(({message}) => console.log(message));
    };

    return (
        <Modal title={modalTitle}>
            <ModalBody>
                <Input
                    label="First Name"
                    placeholder="Type here"
                    required
                    error={!!errors.first_name}
                    errorMessage={errors.first_name?.message}
                    {...register('first_name')}
                />
                <Input
                    label="Last Name"
                    placeholder="Type here"
                    required
                    error={!!errors.last_name}
                    errorMessage={errors.last_name?.message}
                    {...register('last_name')}
                />
                {!user && (
                    <Input
                        label="Email"
                        placeholder="Type here"
                        required
                        error={!!errors.email}
                        errorMessage={errors.email?.message}
                        {...register('email')}
                    />
                )}
                <Input
                    label="Phone Number"
                    placeholder="Type here"
                    error={!!errors.phone}
                    errorMessage={errors.phone?.message}
                    {...register('phone')}
                />
                {currentUser?.role === Role.director && (
                    <Controller
                        name="role"
                        control={control}
                        render={({field}) => (
                            <Dropdown
                                {...field}
                                value={initialFormValue!.role}
                                error={!!errors.role}
                                errorMessage={errors.role?.message}
                                label="User Role"
                                required
                                options={USER_ROLE_OPTIONS}
                            />
                        )}
                    />
                )}
                <Controller
                    name="is_active"
                    control={control}
                    render={({field}) => (
                        <Dropdown
                            {...field}
                            value={initialFormValue!.is_active}
                            error={!!errors.is_active}
                            errorMessage={errors.is_active?.message}
                            label="Status"
                            required
                            options={USER_STATUS_OPTIONS}
                        />
                    )}
                />
                {formError && <ErrorMessage message={formError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    type="submit"
                    onClick={handleSubmit(onActionClick)}
                    disabled={isSubmitting}
                    label={actionButtonTitle}
                />
                <TextButton variant="Danger" label={CANCEL_LABEL} onClick={closeModal} />
            </ModalActions>
        </Modal>
    );
};

export default UserModal;
