import ListContainer from '../../../../components/shared/ListContainer';
import ListItem from '../../../../components/shared/ListItem';
import {IResource} from '../../../../models/resource';
import {useAppDispatch} from '../../../../store/app-hooks';
import {useState} from 'react';
import ResourceModal from '../../../../components/shared/ResourceModal';
import {clearError, deleteResource} from '../../../../store/resource/resource';
import useConfirm from '../../../../hooks/useConfirm';
import {useNotification} from '../../../../store/notification/NotificationContext';
import {FIELD_LABELS} from '../../../../constants';

type LitterBagsConfigProps = {
    litterBagResources: IResource[];
};

export const LitterBagsConfig = ({litterBagResources}: LitterBagsConfigProps) => {
    const dispatch = useAppDispatch();
    const {confirm} = useConfirm();
    const {addNotification} = useNotification();

    const [openNewModal, setOpenNewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [selectedResource, setSelectedResource] = useState<IResource>();

    const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION} = FIELD_LABELS;

    // const handleViewArchivedClick = () => {};

    const onCloseNewModal = () => {
        dispatch(clearError());
        setOpenNewModal(false);
    };

    const handleEditModal = (currResource: IResource) => {
        setOpenEditModal(true);
        setSelectedResource(currResource);
    };

    const onCloseEditModal = () => {
        dispatch(clearError());
        setOpenEditModal(false);
    };

    const onDeleteResource = async (resourceId: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result) {
            dispatch(deleteResource(resourceId))
                .unwrap()
                .then(() => addNotification(SUCCESSFUL_DELETION, 'success'))
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    return (
        <>
            <ListContainer
                onAddClick={() => setOpenNewModal(true)}
                // onViewArchivedClick={handleViewArchivedClick}
                title="Litter Bags"
            >
                {litterBagResources.map((bag) => (
                    <ListItem
                        key={bag.id}
                        title={bag.title}
                        description={`capacity: ${bag.capacity}lt`}
                        onDeleteClick={() => onDeleteResource(bag.id)}
                        onEditClick={() => handleEditModal(bag)}
                    />
                ))}
            </ListContainer>
            {openNewModal && <ResourceModal closeModal={onCloseNewModal} isLitterBag />}
            {openEditModal && (
                <ResourceModal
                    resource={selectedResource}
                    closeModal={onCloseEditModal}
                    isLitterBag
                />
            )}
        </>
    );
};
