import ListContainer from '../../../../components/shared/ListContainer';
import ListItem from '../../../../components/shared/ListItem';
import {
    clearErrorMessage,
    deleteAdditionalInfo,
    useAdditionalInfoData,
} from '../../../../store/additionalInfo/additionalInfoSlice';
import {useState} from 'react';
import {useAppDispatch} from '../../../../store/app-hooks';
import AdditionalInfoModal from '../../../../components/shared/AdditionalInfoModal';
import {IAdditionalInfo} from '../../../../models/additionalInfo';
import {useNotification} from '../../../../store/notification/NotificationContext';
import useConfirm from '../../../../hooks/useConfirm';
import {FIELD_LABELS} from '../../../../constants';

export const AdditionalLocationInfoConfig = () => {
    const dispatch = useAppDispatch();
    const {confirm} = useConfirm();
    const {addNotification} = useNotification();

    const [additionalInfo, additionalInfoLoaded] = useAdditionalInfoData();
    const [selectedAdditionalInfo, setSelectedAdditionalInfo] = useState<IAdditionalInfo>();

    const [openAddNewModal, setOpenAddNewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION} = FIELD_LABELS;

    // const handleViewArchivedClick = () => {};

    const handleAddNewClick = () => setOpenAddNewModal(true);

    const closeAddNewModal = () => {
        setOpenAddNewModal(false);
        dispatch(clearErrorMessage());
    };

    const handleEditModal = (locInfo: IAdditionalInfo) => {
        setOpenEditModal(true);
        setSelectedAdditionalInfo(locInfo);
    };

    const closeEditModal = () => {
        dispatch(clearErrorMessage());
        setOpenEditModal(false);
    };

    const onDelete = async (id: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);
        if (result) {
            dispatch(deleteAdditionalInfo(id))
                .unwrap()
                .then(() => addNotification(SUCCESSFUL_DELETION, 'success'))
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    return additionalInfoLoaded ? (
        <>
            <ListContainer
                onAddClick={handleAddNewClick}
                // onViewArchivedClick={handleViewArchivedClick}
                title="Additional Location Info"
            >
                {additionalInfo.map((locInfo) => (
                    <ListItem
                        key={locInfo.id}
                        title={locInfo.title}
                        onDeleteClick={() => onDelete(locInfo.id)}
                        onEditClick={() => handleEditModal(locInfo)}
                    />
                ))}
            </ListContainer>
            {openAddNewModal && <AdditionalInfoModal closeModal={closeAddNewModal} />}
            {openEditModal && (
                <AdditionalInfoModal
                    additionalInfo={selectedAdditionalInfo}
                    closeModal={closeEditModal}
                />
            )}
        </>
    ) : (
        <div>Waiting for data to load...</div>
    );
};
