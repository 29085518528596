import {useParams} from 'react-router-dom';
import {useProgramWithUsers} from '../store/programs/programsSlice';

const useProgramWithUsersFromParams = () => {
    const {programId} = useParams<{programId: string}>();

    const [program, isLoading] = useProgramWithUsers(programId);

    return [program, isLoading] as const;
};

export default useProgramWithUsersFromParams;
