import { FC, InputHTMLAttributes } from 'react';
import s from '../index.module.scss';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const Checkbox: FC<IProps> = ({ label, ...rest }) => {
  return (
    <label className={`${s.checkbox} ${rest.disabled && s.disabled}`}>
      <input type="checkbox" {...rest} />
      <span className={s.checkmark}></span>
      {label && label}
    </label>
  );
};
