import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import s from './index.module.scss';

interface IProps {
  routes: { title: string; url: string }[];
}

export const SecondaryNav: FC<IProps> = ({ routes }) => {
  return (
    <nav className={s.container}>
      <ul>
        {routes.map(({ title, url }, i) => (
          <li key={i}>
            <NavLink
              to={url}
              className={({ isActive }) => (isActive ? s.active : undefined)}
            >
              {title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};
