import {FC, useState} from 'react';
import {useAppDispatch} from '../../../store/app-hooks';
import {useNotification} from '../../../store/notification/NotificationContext';
import {
    addProgramToWasteType,
    removeProgramFmWasteType,
} from '../../../store/wasteType/wasteTypeSlice';
import {IProgram} from '../../../models/program';
import {IWasteType} from '../../../models/wasteType';

import WasteTypeModal from '../../shared/WasteTypeModal';
import {DataConfigCard} from '../../shared/DataConfigCard';
import {ToggleChip} from '../../UI/Chips/ToggleChip';
import {FIELD_LABELS} from '../../../constants';

const {FAIL_OPERATION} = FIELD_LABELS;

interface IProps {
    program: IProgram;
    waste_types: IWasteType[];
}

const ProgramWasteTypes: FC<IProps> = ({waste_types, program}) => {
    const [openModal, setOpenModal] = useState(false);
    const {addNotification} = useNotification();
    const dispath = useAppDispatch();

    const handleAddWasteType = (type_id: string) => {
        program &&
            dispath(addProgramToWasteType({type_id, data: {programId: program?.id}}))
                .unwrap()
                .catch(() => addNotification(FAIL_OPERATION, 'error'));
    };

    const handleRemoveWasteType = (type_id: string) => {
        program &&
            dispath(removeProgramFmWasteType({type_id, data: {programId: program?.id}}))
                .unwrap()
                .catch(() => addNotification(FAIL_OPERATION, 'error'));
    };

    return (
        <>
            <DataConfigCard title="WASTE TYPES" onAddNew={() => setOpenModal(true)}>
                {waste_types.map((type) => (
                    <ToggleChip
                        key={type.id}
                        label={type.title}
                        selected={type.programs.includes(program.id)}
                        onClick={() =>
                            type.programs.includes(program.id)
                                ? handleRemoveWasteType(type.id)
                                : handleAddWasteType(type.id)
                        }
                    />
                ))}
            </DataConfigCard>
            {openModal && (
                <WasteTypeModal programId={program.id} closeModal={() => setOpenModal(false)} />
            )}
        </>
    );
};

export default ProgramWasteTypes;
