import {useMemo} from 'react';
import {ICleanUp} from '../models/cleanUp';
import useGroupCleanUpsByProgram from './useGroupCleanUpsByProgram';

export const useDayReportCleanUpData = (cleanUps: ICleanUp[]) => {
    const [groups, loaded] = useGroupCleanUpsByProgram(cleanUps);

    const groupedCleanUps = useMemo(() => {
        // merge cleanUp properties(locations, resources and collected weights) in unique array for each one
        return Object.entries(groups).map(([program, program_clean_ups]) => {
            const collected_weights = program_clean_ups.flatMap((x) => x.collected_weights);
            const used_general_resources = program_clean_ups.flatMap(
                (x) => x.used_general_resources
            );
            const used_liter_bags = program_clean_ups.flatMap((x) => x.used_liter_bags);
            const locations = program_clean_ups.flatMap(({location_name, id}) => ({
                location_name,
                id,
            }));

            return {
                program,
                collected_weights,
                used_general_resources,
                used_liter_bags,
                locations,
            };
        });
    }, [groups]);

    return [groupedCleanUps, loaded] as const;
};
