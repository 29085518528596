import {WeatherConditionsNumericalConfig} from './WeatherConditionsNumericalConfig';
import {WeatherConditionsTextConfig} from './WeatherConditionsTextConfig';

import s from '../index.module.scss';
import {useWeatherConditionsData} from '../../../store/weatherCondition/weatherCondition';
import {WeatherTypes} from '../../../models/weatherCondition';

export const WeatherConfigurationContent = () => {
    const [weatherData, loadedWeatherData] = useWeatherConditionsData();

    const weatherDataListType = weatherData.filter((item) => item.type === WeatherTypes.LIST);
    const weatherDataNumericType = weatherData.filter((item) => item.type === WeatherTypes.NUMBER);

    return loadedWeatherData ? (
        <div className={s.container}>
            <WeatherConditionsTextConfig data={weatherDataListType} />
            <WeatherConditionsNumericalConfig data={weatherDataNumericType} />
        </div>
    ) : (
        <div>Fetching data...</div>
    );
};
