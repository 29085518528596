import {FC} from 'react';
import {PDFDownloadLink} from '@react-pdf/renderer';
import DailyReportPDF from '../../../components/UI/PdfReport/DailyReportPDF';
import {usePdfReportData} from '../../../hooks/usePdfReportData';
import {TextButton} from '../Button/Text';
import {format} from 'date-fns';

interface IProps {
    date: string;
}

const DownloadLink: FC<IProps> = ({date}) => {
    const [data, dataLoaded] = usePdfReportData(date);

    const filename = `Report of ${format(new Date(date), 'MMMM do, yyyy')}.pdf`;

    return (
        <PDFDownloadLink document={<DailyReportPDF data={data} />} fileName={filename}>
            {({blob, url, loading, error}) =>
                loading && !dataLoaded ? (
                    ''
                ) : (
                    <TextButton label="Download Daily Report" style={{marginLeft: '20px'}} />
                )
            }
        </PDFDownloadLink>
    );
};

export default DownloadLink;
