import {createContext, Dispatch, FC, useReducer} from 'react';
import {ConfirmationModal} from '../../components/UI/Modal';

type ConfirmActions =
    | {
          type: 'SHOW';
          value: {title: string; message: string};
      }
    | {type: 'HIDE'};

interface IConfirmState {
    show: boolean;
    title?: string;
    message?: string;
}

const initialState: IConfirmState = {
    show: false,
};

const reducer = (state: IConfirmState, action: ConfirmActions) => {
    switch (action.type) {
        case 'SHOW':
            return {
                show: true,
                title: action.value.title,
                message: action.value.message,
            };
        case 'HIDE':
            return initialState;

        default:
            return state;
    }
};

type ContextType = [state: IConfirmState, dispach: Dispatch<ConfirmActions>];

export const ConfirmContext = createContext<ContextType>({} as ContextType);

interface IProps {
    children: React.ReactNode;
}

export const ConfirmContextProvider: FC<IProps> = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ConfirmContext.Provider value={[state, dispatch]}>
            {children}
            <ConfirmationModal />
        </ConfirmContext.Provider>
    );
};
