import axios from 'axios';
import {auth} from './firebase';

const apiClient = axios.create({baseURL: process.env.REACT_APP_API});

apiClient.interceptors.request.use(async (request) => {
    const token = await auth.currentUser?.getIdToken();

    if (token && request.headers) {
        request.headers['Authorization'] = `Bearer ${token}`;
    }

    return request;
});

apiClient.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => Promise.reject(error.response.data.message[0])
);

export default apiClient;
