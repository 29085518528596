import {FC, useState} from 'react';
import {useAppDispatch} from '../../../store/app-hooks';
import {useNotification} from '../../../store/notification/NotificationContext';
import {IProgram} from '../../../models/program';
import {IResource, ResourceType} from '../../../models/resource';
import {addProgramToResource, removeProgramFmResource} from '../../../store/resource/resource';

import {DataConfigCard} from '../../shared/DataConfigCard';
import ResourceModal from '../../shared/ResourceModal';
import {ToggleChip} from '../../UI/Chips/ToggleChip';

import {FIELD_LABELS} from '../../../constants';

const {FAIL_OPERATION} = FIELD_LABELS;

interface IProps {
    program: IProgram;
    resources: IResource[];
    type: ResourceType;
}

const ProgramResources: FC<IProps> = ({program, resources, type}) => {
    const [openModal, setOpenModal] = useState(false);
    const {addNotification} = useNotification();
    const dispath = useAppDispatch();

    const handleAddWasteType = (resource_id: string) => {
        dispath(addProgramToResource({resource_id, data: {programId: program.id}}))
            .unwrap()
            .catch(() => addNotification(FAIL_OPERATION, 'error'));
    };

    const handleRemoveWasteType = (resource_id: string) => {
        dispath(removeProgramFmResource({resource_id, data: {programId: program.id}}))
            .unwrap()
            .catch(() => addNotification(FAIL_OPERATION, 'error'));
    };

    return (
        <>
            <DataConfigCard
                title={
                    type === ResourceType.GENERAL
                        ? 'RESOURCES (GENERAL)'
                        : 'RESOURCES (LITTER BAGS)'
                }
                onAddNew={() => setOpenModal(true)}
            >
                {resources.map((resource) => (
                    <ToggleChip
                        key={resource.id}
                        label={resource.title}
                        selected={resource.programs.includes(program.id)}
                        onClick={() =>
                            resource.programs.includes(program.id)
                                ? handleRemoveWasteType(resource.id)
                                : handleAddWasteType(resource.id)
                        }
                    />
                ))}
            </DataConfigCard>
            {openModal && (
                <ResourceModal
                    isLitterBag={type === ResourceType.LITTER_BAG}
                    programId={program.id}
                    closeModal={() => setOpenModal(false)}
                />
            )}
        </>
    );
};

export default ProgramResources;
