import {FC, useMemo} from 'react';
import {Icon} from '../../../components/UI/Icon';
import {MoreMenu} from '../../../components/UI/MoreMenu';
import s from '../index.module.scss';
import ListItemDetails from '../../../components/shared/ListItem/ListItemDetails';
import {MoreMenuItem} from '../../../components/UI/MoreMenu/MoreMenuItem';
import {IUser} from '../../../models/user';
import {useUserProgramsData} from '../../../store/programs/programsSlice';

interface IProps {
    user: IUser;
    onOpenEditModal: (user: IUser) => void;
    onRemoveUser: (userId: string) => void;
    onUserActivation: (user: IUser) => void;
}

export const UserItem: FC<IProps> = ({user, onOpenEditModal, onRemoveUser, onUserActivation}) => {
    const [userPrograms] = useUserProgramsData(user.id);

    const details: string[] = useMemo(
        () =>
            [
                `${user.role}`,
                user.phone && `${user.phone}`,
                userPrograms && `${userPrograms.length} Programs`,
            ].filter((item) => !!item),
        [userPrograms, user]
    );

    const activationLabel = user.is_active ? 'Deactivate user' : 'Activate user';

    return (
        <div className={s.item}>
            <div className={s.content}>
                <Icon name="User" variant="tertiaryAccent" size="60" />
                <div className={s.info}>
                    <div>
                        {user.first_name} {user.last_name}
                    </div>
                    <ListItemDetails details={details} />
                </div>
            </div>
            <MoreMenu>
                <MoreMenuItem label="Edit Info" onClick={() => onOpenEditModal(user)} />
                <MoreMenuItem label={activationLabel} onClick={() => onUserActivation(user)} />
                <MoreMenuItem label="Delete User" onClick={() => onRemoveUser(user.id)} />
            </MoreMenu>
        </div>
    );
};
