import {FC, useMemo} from 'react';
import {addDays, isAfter, isBefore, isSameDay, isToday, startOfMonth, startOfWeek} from 'date-fns';

import CalendarCell from './CalendarCell';

import s from './calendar.module.scss';
import {endOfMonth} from 'date-fns/esm';
import CalendarHeader from './CalendarHeader';
import CalendarControls from './CalendarControls';

export interface ICalendarItem {
    date: Date;
    type: 'CLEAN_UP' | 'NO_CLEANING';
    value: number;
}

export interface ICalendarControls {
    selectedDate: Date;
    onPrevMonth: VoidFunction;
    onPrevYear: VoidFunction;
    onNextMonth: VoidFunction;
    onNextYear: VoidFunction;
}

interface IProps extends ICalendarControls {
    items: ICalendarItem[];
    onDaySelect: (day: Date) => void;
}

const Calendar: FC<IProps> = ({selectedDate, items, onDaySelect, ...rest}) => {
    const dates = useMemo(() => {
        const firstDay = startOfMonth(selectedDate);
        const lastDay = endOfMonth(selectedDate);
        const firstDayOnView = startOfWeek(firstDay, {weekStartsOn: 1});

        return [...Array(7 * 6)].map((_, index) => {
            const date = addDays(firstDayOnView, index);
            const disabled = isBefore(date, firstDay) || isAfter(date, lastDay);
            const isSelected = isToday(date);

            const cleanUps = items.find(
                (i) => isSameDay(date, i.date) && i.type === 'CLEAN_UP'
            )?.value;

            const noCleaningActivities = items.find(
                (i) => isSameDay(date, i.date) && i.type === 'NO_CLEANING'
            )?.value;

            return {date, disabled, isSelected, cleanUps, noCleaningActivities};
        });
    }, [selectedDate, items]);

    return (
        <div className={s.container}>
            <CalendarControls selectedDate={selectedDate} {...rest} />
            <CalendarHeader />
            <div className={s.calendar}>
                {dates.map((d, index) => (
                    <CalendarCell
                        key={index}
                        date={d.date}
                        disabled={d.disabled}
                        isSelected={d.isSelected}
                        cleanUps={d.cleanUps}
                        noCleaningActivities={d.noCleaningActivities}
                        onSelect={onDaySelect}
                    />
                ))}
            </div>
        </div>
    );
};

export default Calendar;
