import {FC, useMemo, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {
    addUsersToProgram,
    addUsersToTeam,
    programsError,
    programsIsSubmitting,
} from '../../../store/programs/programsSlice';

import {IUser} from '../../../models/user';
import {IProgramWithUsers} from '../../../models/program';
import {ITeamWithUsers} from '../../../models/team';

import {Modal} from '../../UI/Modal';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Checkbox} from '../../UI/Controls/Checkbox';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {FIELD_LABELS} from '../../../constants';

import s from './index.module.scss';

const {ADD_TO_LIST_LABEL, CANCEL_LABEL} = FIELD_LABELS;

interface IProps {
    users: IUser[];
    program: IProgramWithUsers;
    team?: ITeamWithUsers;
    onClose: () => void;
}

const UserSelectionModal: FC<IProps> = ({onClose, users, program, team}) => {
    const [selected, setSelected] = useState<string[]>([]);
    const isSumbiting = useAppSelector(programsIsSubmitting);
    const dispatch = useAppDispatch();
    const error = useAppSelector(programsError);

    const availableUsers = useMemo(
        () =>
            !team
                ? users.filter(
                      (user) =>
                          !program?.users.map((u) => u.id).includes(user.id) &&
                          !program?.teams.some((t) => t.users?.map((u) => u.id).includes(user.id))
                  )
                : users.filter((user) => !team.users?.map((u) => u.id).includes(user.id)),
        [users, program, team]
    );

    const handleCheckboxClick = (userId: string) => {
        setSelected((prev) => {
            if (prev.includes(userId)) {
                return prev.filter((item) => item !== userId);
            } else {
                return [...prev, userId];
            }
        });
    };

    const handleSumbit = () => {
        if (team) {
            dispatch(
                addUsersToTeam({programId: program.id, teamId: team.id, data: {userId: selected}})
            )
                .unwrap()
                .then(() => onClose());
        } else {
            dispatch(addUsersToProgram({programId: program.id, data: {userId: selected}}))
                .unwrap()
                .then(() => onClose());
        }
    };

    return (
        <Modal title="Select Users">
            <ModalBody>
                <div className={s.list}>
                    {availableUsers.map((u) => (
                        <Checkbox
                            key={u.id}
                            label={`${u.last_name} ${u.first_name}`}
                            onChange={() => handleCheckboxClick(u.id)}
                            checked={selected.includes(u.id)}
                        />
                    ))}
                </div>
                {error && <ErrorMessage message={error} />}
            </ModalBody>
            <ModalActions>
                <Button
                    onClick={handleSumbit}
                    label={ADD_TO_LIST_LABEL}
                    disabled={isSumbiting || selected.length === 0}
                />
                <TextButton variant="Danger" label={CANCEL_LABEL} onClick={() => onClose()} />
            </ModalActions>
        </Modal>
    );
};

export default UserSelectionModal;
