import {Modal} from '../../UI/Modal';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Input} from '../../UI/Input';

import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {getValidationSchema, initial, IResourceForm} from './formModel';
import {FIELD_LABELS} from '../../../constants';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {
    newResource,
    resourcesError,
    resourcesIsSubmitting,
    updateResource,
} from '../../../store/resource/resource';
import {ICreateResourceDTO, ResourceType} from '../../../models/resource';

type ResourceModalProps = {
    resource?: IResourceForm;
    programId?: string;
    closeModal: VoidFunction;
    isLitterBag: boolean;
};

const ResourceModal = ({resource, closeModal, isLitterBag, programId}: ResourceModalProps) => {
    const dispatch = useAppDispatch();
    const formError = useAppSelector(resourcesError);

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

    const initialFormValue: IResourceForm = resource ? resource : initial;

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IResourceForm>({
        resolver: yupResolver(getValidationSchema(isLitterBag)),
        defaultValues: initialFormValue,
    });

    const isSubmitting = useAppSelector(resourcesIsSubmitting);

    let modalTitle = !resource ? 'New Resource' : 'Edit Resource';

    if (!resource && isLitterBag) modalTitle = 'New Litter Bag';
    if (resource && isLitterBag) modalTitle = 'Edit Litter Bag';

    const actionButtonTitle = !resource ? ADD_TO_LIST_LABEL : SAVE_CHANGES_LABEL;

    const onActionClick = (formValues: IResourceForm) => {
        const {id: resourceId} = formValues;

        const newResourcePayLoad: ICreateResourceDTO = {
            title: formValues.title,
            type: isLitterBag ? ResourceType.LITTER_BAG : ResourceType.GENERAL,
            capacity: isLitterBag ? formValues.capacity : undefined,
            ...(programId && {programId}),
        };

        const {type, ...updatePayLoad} = newResourcePayLoad;

        const action = !resource
            ? dispatch(newResource(newResourcePayLoad))
            : dispatch(
                  updateResource({
                      resourceId,
                      data: updatePayLoad,
                  })
              );

        action
            .unwrap()
            .then(() => closeModal())
            .catch(({message}) => console.log(message));
    };

    return (
        <Modal title={modalTitle}>
            <ModalBody>
                <Input
                    label="Name"
                    placeholder="Type here"
                    required
                    error={!!errors.title}
                    errorMessage={errors.title?.message}
                    {...register('title')}
                />
                {isLitterBag && (
                    <Input
                        label="Maximum Capacity (lt)"
                        placeholder="Type here"
                        required
                        error={!!errors.capacity}
                        errorMessage={errors.capacity?.message}
                        {...register('capacity')}
                    />
                )}
                {formError && <ErrorMessage message={formError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    type="submit"
                    onClick={handleSubmit(onActionClick)}
                    disabled={isSubmitting}
                    label={actionButtonTitle}
                />
                <TextButton variant="Danger" label={CANCEL_LABEL} onClick={closeModal} />
            </ModalActions>
        </Modal>
    );
};

export default ResourceModal;
