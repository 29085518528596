import { FC } from 'react';
import s from './index.module.scss';

interface IProps {
  vertical?: boolean;
}

export const Divider: FC<IProps> = ({ vertical }) => {
  return (
    <div
      className={`${s.container} ${vertical ? s.vertical : s.horizontal}`}
    ></div>
  );
};
