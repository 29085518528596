import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {useReduxData} from '../../hooks/useReduxData';
import {RootState} from '../store';
import {
    createNewResource,
    getResources,
    patchResource,
    removeResource,
    addProgramToRersouce as addProgramToRersouceApi,
    removeProgramFmRersouce as removeProgramFmRersouceApi,
} from '../../api/resource';
import {
    IResource,
    ICreateResourceDTO,
    IUpdateResourceDTO,
    IAddProgramToResourceDTO,
    IRemoveProgramFmResourceDTO,
} from '../../models/resource';
import {getArrayIndex} from '../../utils/array_helpers';

interface IResourceState {
    resourcesData: IResource[];
    error: string | null;
    loaded: boolean;
    isSubmitting: boolean;
}

const initialState: IResourceState = {
    resourcesData: [],
    error: null,
    loaded: false,
    isSubmitting: false,
};

type UpdateResourceProps = {data: IUpdateResourceDTO; resourceId: string};
type AddProgramToResourcePayload = {resource_id: string; data: IAddProgramToResourceDTO};
type RemoveProgramResourcePayload = {resource_id: string; data: IRemoveProgramFmResourceDTO};

export const loadResources = createAsyncThunk(
    'resource/loadResources',
    async () => await getResources()
);

export const newResource = createAsyncThunk(
    'resource/newResource',
    async (data: ICreateResourceDTO) => await createNewResource(data)
);

export const updateResource = createAsyncThunk(
    'resource/updateResource',
    async ({data, resourceId}: UpdateResourceProps) => await patchResource(resourceId, data)
);

export const deleteResource = createAsyncThunk(
    'resource/deleteResource',
    async (resourceId: string) => await removeResource(resourceId)
);

export const addProgramToResource = createAsyncThunk(
    'resource/addProgram',
    async ({resource_id, data}: AddProgramToResourcePayload, {dispatch}) => {
        try {
            dispatch(addProgram({resource_id, data}));
            return await addProgramToRersouceApi(resource_id, data);
        } catch (error) {
            dispatch(removeProgram({resource_id, data}));
            throw error;
        }
    }
);

export const removeProgramFmResource = createAsyncThunk(
    'resource/removeProgram',
    async ({resource_id, data}: RemoveProgramResourcePayload, {dispatch}) => {
        try {
            dispatch(removeProgram({resource_id, data}));
            return await removeProgramFmRersouceApi(resource_id, data);
        } catch (error) {
            dispatch(addProgram({resource_id, data}));
            throw error;
        }
    }
);

export const resourcesSlice = createSlice({
    name: 'resource',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
        addProgram: (state, action: PayloadAction<AddProgramToResourcePayload>) => {
            const {resource_id, data} = action.payload;
            const index = getArrayIndex(state.resourcesData, resource_id);
            state.resourcesData[index].programs.push(data.programId);
        },
        removeProgram: (state, action: PayloadAction<RemoveProgramResourcePayload>) => {
            const {resource_id, data} = action.payload;
            const index = getArrayIndex(state.resourcesData, resource_id);
            state.resourcesData[index].programs = state.resourcesData[index].programs.filter(
                (p) => p !== data.programId
            );
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadResources.fulfilled, (state, action) => {
                state.resourcesData = action.payload;
                state.loaded = true;
                state.error = null;
            })
            .addCase(loadResources.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
                state.loaded = true;
            })

            .addCase(newResource.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newResource.fulfilled, (state, action) => {
                state.isSubmitting = false;
                state.error = null;
                state.resourcesData.push(action.payload);
            })
            .addCase(newResource.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(updateResource.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateResource.fulfilled, (state, action) => {
                const {id} = action.payload;
                const currentIndex = getArrayIndex(state.resourcesData, id);

                state.isSubmitting = false;
                state.error = null;
                state.resourcesData[currentIndex] = action.payload;
            })
            .addCase(updateResource.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(deleteResource.fulfilled, (state, action) => {
                const id = action.meta.arg;

                state.error = null;
                state.isSubmitting = false;
                state.resourcesData = state.resourcesData.filter((data) => data.id !== id);
            })
            .addCase(addProgramToResource.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(removeProgramFmResource.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            });
    },
});

export const {clearError, addProgram, removeProgram} = resourcesSlice.actions;

export const resources = (state: RootState) => state.resources.resourcesData;
export const resourcesError = (state: RootState) => state.resources.error;
export const resourcesLoaded = (state: RootState) => state.resources.loaded;
export const resourcesIsSubmitting = (state: RootState) => state.resources.isSubmitting;

export const useResources = () => useReduxData(resources, resourcesLoaded, loadResources);

export default resourcesSlice.reducer;
