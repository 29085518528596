import {FC} from 'react';

import s from './index.module.scss';

interface IProps {
    message: string;
}

export const ErrorMessage: FC<IProps> = ({message}) => {
    return <div className={s.container}>{message}</div>;
};
