import {FC} from 'react';
import {Icon} from '../Icon';

import classes from './index.module.scss';

interface IProps {
    title?: string;
}

const DEFAULT = 'Loading Data. Please Wait...';

export const LoadingContainer: FC<IProps> = ({title}) => {
    return (
        <div className={classes.container}>
            <Icon name="Logo" viewbox="0 0 72 72" size="160" variant="tertiaryAccent" />
            <div className={classes.message}>{title ?? DEFAULT}</div>
        </div>
    );
};
