import {FC} from 'react';
import {View, StyleSheet, Text} from '@react-pdf/renderer';
import Logo from './Logo';
import {IPDFPCleanUp} from '../../../models/pdfReport';

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 24,
    },
    info: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    region: {
        fontFamily: 'Manrope',
        fontSize: 12,
    },
    location: {
        fontFamily: 'Manrope',
        fontWeight: 'bold',
        fontSize: 20,
        color: '#002342',
    },
});

interface IProps {
    data: IPDFPCleanUp;
}

const LocationHeader: FC<IProps> = ({
    data: {location_name, district, municipality, city_island},
}) => {
    const regionString = [district, municipality, city_island].filter((item) => item).join(' | ');

    return (
        <View style={styles.container} fixed>
            <View style={styles.info}>
                <Text style={styles.region}>{regionString}</Text>
                <Text style={styles.location}>{location_name || 'Unnamed Location'}</Text>
            </View>
            <Logo color="gray" size={40} />
        </View>
    );
};

export default LocationHeader;
