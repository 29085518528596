import {useEffect} from 'react';
import {onAuthStateChanged} from 'firebase/auth';
import {RouterProvider} from 'react-router-dom';
import {auth} from './api/firebase';
import {useAppDispatch} from './store/app-hooks';
import {loadCurrentUser} from './store/auth/authSlice';
import {ConfirmContextProvider} from './store/confirm/ConfirmationContext';
import {SnackBarProvider} from './store/notification/NotificationContext';
import {router} from './routes';

export const App = () => {
    const dispach = useAppDispatch();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (auth) => {
            dispach(loadCurrentUser());
        });

        return unsubscribe;
    }, [dispach]);

    return (
        <ConfirmContextProvider>
            <SnackBarProvider>
                <RouterProvider router={router} />
            </SnackBarProvider>
        </ConfirmContextProvider>
    );
};
