import StatusChip, {StatusChipType} from '../../../UI/Chips/StatusChip';
import classes from './CleanUpBoard.module.scss';

export type BoardData = {
    label?: string;
    info?: string | number;
};

export type HeaderChip = {label: string; type: StatusChipType};

type CleanUpBoardProps = {
    header: string;
    headerChips?: HeaderChip[];
    data?: BoardData[];
};

const CleanUpBoard = ({data, header, headerChips = []}: CleanUpBoardProps) => {
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <h2>{header}</h2>
                {headerChips.map((c) => (
                    <StatusChip key={c.label} label={c.label} type={c.type} />
                ))}
            </div>
            {data?.map((it, index) => (
                <div key={index} className={classes.contextWrapper}>
                    <div>{it.label}</div>
                    <div>{it.info ?? '-'}</div>
                </div>
            ))}
        </div>
    );
};

export default CleanUpBoard;
