import * as Yup from 'yup';
import {FIELD_ERRORS, UserStatusTypes} from '../../../constants';
import {Role} from '../../../models/user';

export interface IUserForm {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    role?: Role;
    is_active: UserStatusTypes | '';
}

export const initial: IUserForm = {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    role: undefined,
    phone: undefined,
    is_active: '',
};

export const validationSchema = (userRole: Role) =>
    Yup.object({
        first_name: Yup.string().required(FIELD_ERRORS.REQUIRED),
        last_name: Yup.string().required(FIELD_ERRORS.REQUIRED),
        email: Yup.string().email(FIELD_ERRORS.INVALID_EMAIL).required(FIELD_ERRORS.REQUIRED),
        ...(userRole === Role.director && {role: Yup.string().required(FIELD_ERRORS.REQUIRED)}),
        ...(userRole !== Role.director && {role: Yup.string().optional()}),
        phone: Yup.string().nullable(),
        is_active: Yup.string().required(FIELD_ERRORS.REQUIRED),
    });
