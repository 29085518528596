import {FC} from 'react';
import {View, StyleSheet, Text} from '@react-pdf/renderer';
import Table from './Table';
import {IPDFPCleanUp} from '../../../models/pdfReport';

const styles = StyleSheet.create({
    empty: {
        padding: 8,
        fontFamily: 'Manrope',
        fontWeight: 'normal',
        fontSize: 14,
    },
});

interface IProps {
    data: IPDFPCleanUp;
}

const LocationCollectedWeight: FC<IProps> = ({
    data: {
        no_litter_found,
        collectedWeight: {total, wasteTypes},
    },
}) => {
    return (
        <View style={{marginTop: 24}}>
            <Table
                title="COLLECTED WEIGHT"
                titleInfo={`${total} kg`}
                mode="grid"
                rows={wasteTypes.map((item) => ({title: item.name, info: `${item.weight} kg`}))}
            />
            {wasteTypes.length === 0 && no_litter_found !== true && (
                <Text style={styles.empty}>The collector has not reported collected weight</Text>
            )}
            {no_litter_found && (
                <Text style={styles.empty}>The collector has reported NO LITTER FOUND</Text>
            )}
        </View>
    );
};

export default LocationCollectedWeight;
