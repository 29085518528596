export enum ActivityStatus {
    DRAFT = 'draft',
    ONGOING = 'ongoing',
    COMPLETED = 'completed',
}

export interface IActivity {
    id: string;
    name: string;
    status: ActivityStatus;
    created_at: string;
    updated_at: string;
}

export type IActivityDTO = Pick<IActivity, 'name' | 'status'>;
