import {useState} from 'react';
import {useAppDispatch} from '../../../store/app-hooks';
import useConfirm from '../../../hooks/useConfirm';
import useProgramWithUsersFromParams from '../../../hooks/useProgramWithUsersFromParams';
import {useCollectorData} from '../../../store/user/userSlice';
import {useNotification} from '../../../store/notification/NotificationContext';
import {
    clearError,
    deleteTeam,
    removeUserFromProgram,
    removeUserFromTeam,
} from '../../../store/programs/programsSlice';

import {ITeamWithUsers} from '../../../models/team';
import {ListItemWithChildren} from '../../../components/shared/ListItemWithChildren';
import TeamModal from '../../../components/shared/TeamModal';
import {Button} from '../../../components/UI/Button';
import {RemovableChip} from '../../../components/UI/Chips/RemovableChip';
import UserModal from '../../../components/shared/UserModal';
import UserSelectionModal from '../../../components/shared/UserSelectionModal/UserSelectionModal';

import {FIELD_LABELS} from '../../../constants';
import s from './index.module.scss';

const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION} = FIELD_LABELS;

export const ProgramParticipantsContent = () => {
    const [program] = useProgramWithUsersFromParams();
    const [collectors] = useCollectorData();
    const [openTeamModal, setOpenTeamModal] = useState(false);
    const [openUserModal, setOpenUserModal] = useState(false);
    const [openUserSelection, setOpenUserSelection] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState<ITeamWithUsers | undefined>();

    const dispatch = useAppDispatch();
    const {confirm} = useConfirm();
    const {addNotification} = useNotification();

    const handleCloseNewTeamModal = () => {
        dispatch(clearError());
        setOpenTeamModal(false);
        setSelectedTeam(undefined);
    };

    const handleEditTeam = (team: ITeamWithUsers) => {
        setSelectedTeam({...team});
        setOpenTeamModal(true);
    };

    const onDeleteTeam = async (teamId: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        const payLoad = {
            teamId,
            programId: program!.id,
        };

        if (result) {
            dispatch(deleteTeam(payLoad))
                .unwrap()
                .then(() => {
                    addNotification(SUCCESSFUL_DELETION, 'success');
                })
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    const handleNewUser = (team?: ITeamWithUsers) => {
        setSelectedTeam(team && {...team});
        setOpenUserModal(true);
    };

    const handleCloseNewUserModal = () => {
        setSelectedTeam(undefined);
        setOpenUserModal(false);
    };

    const handleAddUsersToTeam = (team: ITeamWithUsers) => {
        setSelectedTeam({...team});
        setOpenUserSelection(true);
    };

    const handleCloseUserSelectionModal = () => {
        dispatch(clearError());
        setOpenUserSelection(false);
        setSelectedTeam(undefined);
    };

    const onRemoveUserFmProgram = async (userId: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result && program) {
            dispatch(removeUserFromProgram({programId: program.id, userId}))
                .unwrap()
                .then(() => {
                    addNotification(SUCCESSFUL_DELETION, 'success');
                })
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    const onRemoveUserFmTeam = async (userId: string, teamId: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result && program) {
            dispatch(removeUserFromTeam({programId: program.id, teamId, userId}))
                .unwrap()
                .then(() => {
                    addNotification(SUCCESSFUL_DELETION, 'success');
                })
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    return (
        <>
            <div className={s.content}>
                <div className={s.uncategorised}>
                    <div className={s.header}>
                        <div className={`${s.title} ${s.group}`}>Uncategorised Participants</div>

                        <Button
                            label="Add New"
                            variant="Tertiary"
                            onClick={() => handleNewUser()}
                        />
                        <Button
                            label="Select from List"
                            onClick={() => setOpenUserSelection(true)}
                        />
                    </div>
                    <div className={s.list}>
                        {program && program.users.length > 0 ? (
                            program?.users.map((user) => (
                                <RemovableChip
                                    label={`${user.first_name} ${user.last_name}`}
                                    key={user.id}
                                    onClick={() => onRemoveUserFmProgram(user.id)}
                                />
                            ))
                        ) : (
                            <i>Add users that don't belong to a team</i>
                        )}
                    </div>
                </div>
                <div className={s.list_header}>
                    <div className={s.title}>Teams</div>
                    <Button label="Create Team" onClick={() => setOpenTeamModal(true)} />
                </div>
                <div className={s.teams_list}>
                    {program?.teams.map((team) => (
                        <ListItemWithChildren
                            key={team.id}
                            title={team.name}
                            details={[`Participants`, `${team.users?.length ?? 0}`]}
                            menuActions={[
                                {label: 'Edit Info', action: () => handleEditTeam(team)},
                                {
                                    label: 'Add New Participant',
                                    action: () => handleNewUser(team),
                                },
                                {
                                    label: 'Add Participants from List',
                                    action: () => handleAddUsersToTeam(team),
                                },
                                {label: 'Remove Team', action: () => onDeleteTeam(team.id)},
                            ]}
                            items={team.users?.map((user) => ({
                                id: user.id,
                                name: `${user.first_name} ${user.last_name}`,
                            }))}
                            listChildId={team.id}
                            onRemoveChip={(teamId, userId) => onRemoveUserFmTeam(userId, teamId)}
                        />
                    ))}
                </div>
            </div>
            {openTeamModal && program && (
                <TeamModal
                    programId={program?.id}
                    team={selectedTeam}
                    onClose={() => handleCloseNewTeamModal()}
                />
            )}
            {openUserModal && program && (
                <UserModal
                    programId={program.id}
                    teamId={selectedTeam?.id}
                    closeModal={() => handleCloseNewUserModal()}
                />
            )}
            {openUserSelection && program && (
                <UserSelectionModal
                    users={collectors}
                    program={program}
                    team={selectedTeam}
                    onClose={() => handleCloseUserSelectionModal()}
                />
            )}
        </>
    );
};
