import apiClient from './client';
import {
    IAddProgramToWasteTypeDTO,
    IRemoveProgramToWasteTypeDTO,
    IWasteType,
    IWasteTypeDTO,
} from '../models/wasteType';

export const getWasteTypes = async (): Promise<IWasteType[]> => {
    try {
        const response = await apiClient.get<IWasteType[]>('/waste-type');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createNewWasteType = async (data: IWasteTypeDTO): Promise<IWasteType> => {
    try {
        const response = await apiClient.post<IWasteType>('/waste-type', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchWasteType = async (id: string, data: IWasteTypeDTO): Promise<IWasteType> => {
    const {title, description} = data;

    try {
        const response = await apiClient.patch<IWasteType>(`/waste-type/${id}`, {
            title,
            description,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeWasteType = async (id: string): Promise<void> => {
    try {
        await apiClient.delete<IWasteType>(`/waste-type/${id}`);
    } catch (error) {
        throw error;
    }
};

export const addProgramToWasteType = async (
    id: string,
    data: IAddProgramToWasteTypeDTO
): Promise<IWasteType> => {
    try {
        const result = await apiClient.post<IWasteType>(`/waste-type/${id}/add-program`, data);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const removeProgramFmWasteType = async (
    type_id: string,
    data: IRemoveProgramToWasteTypeDTO
): Promise<IWasteType> => {
    try {
        const result = await apiClient.post<IWasteType>(
            `/waste-type/${type_id}/remove-program`,
            data
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};
