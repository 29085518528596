import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../app-hooks';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {INoCleaningRecord} from '../../models/noCleaningRecord';
import {fetchNoCleaningRecordsByDate} from '../../api/noCleaningRecords';
import {removeDuplicates} from '../../utils/array_helpers';

interface INoCleaningRecordsState {
    data: INoCleaningRecord[];
    error: string | null;
    loaded_dates: string[];
}

const initialState: INoCleaningRecordsState = {
    data: [],
    error: null,
    loaded_dates: [],
};

export const getNoCleaningRecordsByDate = createAsyncThunk(
    'noCleaningRecords/getNoCleaningRecordsByDate',
    async (date: string) => await fetchNoCleaningRecordsByDate(date)
);

export const noCleaningRecordsSlice = createSlice({
    name: 'noCleaningRecords',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getNoCleaningRecordsByDate.fulfilled, (state, action) => {
                const updatedData = [...state.data, ...action.payload];
                const uniqueData = removeDuplicates(updatedData);

                state.error = null;
                state.data = uniqueData;
                if (!state.loaded_dates.includes(action.meta.arg)) {
                    state.loaded_dates.push(action.meta.arg);
                }
            })
            .addCase(getNoCleaningRecordsByDate.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
            });
    },
});

export const noCleaningRecords = (state: RootState) => state.noCleaningRecords.data;
export const loadedDates = (state: RootState) => state.noCleaningRecords.loaded_dates;

export const useNoCleaningRecordsByDate = (selectedDate: string) => {
    const dispatch = useAppDispatch();
    const currDate = new Date(selectedDate).toISOString();

    const loaded = useAppSelector(loadedDates).includes(selectedDate);

    const loadedData = useAppSelector(noCleaningRecords);
    const noCleaningRecordsData = loadedData.filter((item) => item.date === currDate);

    useEffect(() => {
        if (!loaded) {
            dispatch(getNoCleaningRecordsByDate(selectedDate));
        }
    }, [loaded, dispatch, selectedDate]);

    return [noCleaningRecordsData, loaded] as const;
};

export default noCleaningRecordsSlice.reducer;
