import s from './index.module.scss';

type ListItemDescriptionProps = {
    description: string;
};

const ListItemDescription = ({description}: ListItemDescriptionProps) => (
    <div className={s.description}>{description}</div>
);

export default ListItemDescription;
