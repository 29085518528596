import {IUsedLitterBag} from '../models/cleanUp';
import {useCleanUpLitterBags} from './useCleanUpLitterBags';

export const useAggregateLitterBags = (used_liter_bags: IUsedLitterBag[]) => {
    const aggregatedLiterBags = used_liter_bags.reduce((acc: IUsedLitterBag[], curr) => {
        const index = acc.findIndex((obj) => obj.resource === curr.resource);

        if (index !== -1) {
            acc[index].full += curr.full;
            acc[index].half += curr.half;
        } else {
            acc.push({
                resource: curr.resource,
                half: curr.half,
                full: curr.full,
            });
        }

        return acc;
    }, []);

    return useCleanUpLitterBags(aggregatedLiterBags);
};
