import ListContainer from '../../../../components/shared/ListContainer';
import ListItem from '../../../../components/shared/ListItem';
import {
    clearError,
    deleteSiteType,
    useSiteTypesData,
} from '../../../../store/siteType/siteTypeSlice';
import {useState} from 'react';
import SiteTypeModal from '../../../../components/shared/SiteTypeModal';
import {ISiteTypeForm} from '../../../../components/shared/SiteTypeModal/formModel';
import {ISiteType} from '../../../../models/siteType';
import {useAppDispatch} from '../../../../store/app-hooks';
import useConfirm from '../../../../hooks/useConfirm';
import {useNotification} from '../../../../store/notification/NotificationContext';
import {FIELD_LABELS} from '../../../../constants';

export const CleanUpSitesTypesConfig = () => {
    const dispatch = useAppDispatch();
    const {confirm} = useConfirm();
    const {addNotification} = useNotification();

    const [cleanUpSitesTypes, dataLoaded] = useSiteTypesData();
    const [selectedSiteType, setSelectedSiteType] = useState<ISiteTypeForm>();

    const [openAddNewModal, setOpenAddNewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION} = FIELD_LABELS;

    const onDelete = async (id: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result) {
            dispatch(deleteSiteType(id))
                .unwrap()
                .then(() => addNotification(SUCCESSFUL_DELETION, 'success'))
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    const onEdit = (siteType: ISiteType) => {
        setOpenEditModal(true);
        setSelectedSiteType(siteType);
    };

    const handleAddNewClick = () => setOpenAddNewModal(true);

    // const handleViewArchivedClick = () => {};

    const closeAddNewModal = () => {
        dispatch(clearError());
        setOpenAddNewModal(false);
    };

    const closeEditModal = () => {
        dispatch(clearError());
        setOpenEditModal(false);
    };

    return dataLoaded ? (
        <>
            <ListContainer
                onAddClick={handleAddNewClick}
                // onViewArchivedClick={handleViewArchivedClick}
                title="Clean Up Site Types"
            >
                {cleanUpSitesTypes.map((siteType) => (
                    <ListItem
                        key={siteType.id}
                        title={siteType.title}
                        onDeleteClick={() => onDelete(siteType.id)}
                        onEditClick={() => onEdit(siteType)}
                    />
                ))}
            </ListContainer>
            {openAddNewModal && <SiteTypeModal closeModal={closeAddNewModal} />}
            {openEditModal && (
                <SiteTypeModal siteType={selectedSiteType} closeModal={closeEditModal} />
            )}
        </>
    ) : (
        <div>Waiting for data to load...</div>
    );
};
