import apiClient from './client';
import {IWasteCategory, IWasteCategoryDTO, WasteCategoryItemDTO} from '../models/wasteCategory';

export const getWasteCategories = async (): Promise<IWasteCategory[]> => {
    try {
        const response = await apiClient.get<IWasteCategory[]>('/waste-categories');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createWasteCategory = async (data: IWasteCategoryDTO): Promise<IWasteCategory> => {
    try {
        const response = await apiClient.post<IWasteCategory>('/waste-categories', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchWasteCategory = async (
    categoryId: string,
    data: IWasteCategoryDTO
): Promise<IWasteCategory> => {
    try {
        const response = await apiClient.patch<IWasteCategory>(
            `/waste-categories/${categoryId}`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeWasteCategory = async (categoryId: string): Promise<void> => {
    try {
        await apiClient.delete(`/waste-categories/${categoryId}`);
    } catch (error) {
        throw error;
    }
};

export const createWasteCategoryItem = async (
    categoryId: string,
    data: WasteCategoryItemDTO
): Promise<IWasteCategory> => {
    try {
        const response = await apiClient.post<IWasteCategory>(
            `/waste-categories/${categoryId}/item`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeWasteCategoryItem = async (
    wasteCategoryId: string,
    itemId: string
): Promise<void> => {
    try {
        await apiClient.delete(`/waste-categories/${wasteCategoryId}/item/${itemId}`);
    } catch (error) {
        throw error;
    }
};
