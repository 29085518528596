import {FC} from 'react';

import s from './calendar.module.scss';

interface IProps {
    date: Date;
    disabled: boolean;
    isSelected: boolean;
    cleanUps?: number;
    noCleaningActivities?: number;
    onSelect: (day: Date) => void;
}

const CalendarCell: FC<IProps> = ({
    date,
    disabled,
    isSelected,
    cleanUps,
    noCleaningActivities,
    onSelect,
}) => {
    const headerClass = `${s.cell__header} ${isSelected ? s['cell__header--selected'] : ''} ${
        disabled ? s['cell__header--disabled'] : ''
    }`;

    const itemsClass = `${s.cell__item} ${disabled && s['cell__item--disabled']}`;

    return (
        <div className={s.cell} onClick={() => onSelect(date)}>
            <div className={headerClass}>{date.getDate().toString().padStart(2, '0')}</div>
            {cleanUps && (
                <div className={itemsClass}>
                    <div className={s.cell__item_label}>Clean Ups</div>
                    <div className={s.cell__item_value}>{cleanUps}</div>
                </div>
            )}
            {noCleaningActivities && (
                <div className={itemsClass}>
                    <div className={s.cell__item_label}>No Cleaning Activities</div>
                    <div className={s.cell__item_value}>{noCleaningActivities}</div>
                </div>
            )}
        </div>
    );
};

export default CalendarCell;
