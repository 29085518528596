import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {useReduxData} from '../../hooks/useReduxData';
import {removeWasteCategory} from '../../api/wasteCategory';
import {IWasteCategory, IWasteCategoryDTO, WasteCategoryItemDTO} from '../../models/wasteCategory';
import {
    createWasteCategory,
    createWasteCategoryItem,
    getWasteCategories,
    patchWasteCategory,
    removeWasteCategoryItem,
} from '../../api/wasteCategory';
import {getArrayIndex} from '../../utils/array_helpers';

export type UpdateWasteCategory = {
    categoryId: string;
    data: IWasteCategoryDTO;
};

export type CreateWasteCategoryItem = {
    wasteCategoryId: string;
    data: WasteCategoryItemDTO;
};

export type RemoveWasteCategoryItem = {
    wasteCategoryId: string;
    itemId: string;
};

interface IWasteCategoryState {
    wasteCategoriesData: IWasteCategory[];
    error: string | null;
    loaded: boolean;
    isSubmitting: boolean;
}

const initialState: IWasteCategoryState = {
    wasteCategoriesData: [],
    error: null,
    loaded: false,
    isSubmitting: false,
};

export const loadWasteCategories = createAsyncThunk(
    'wasteCategories/loadWasteCategories',
    async () => await getWasteCategories()
);

export const newWasteCategory = createAsyncThunk(
    'wasteCategories/newWasteCategory',
    async (data: IWasteCategoryDTO) => await createWasteCategory(data)
);

export const updateWasteCategory = createAsyncThunk(
    'wasteCategories/updateWasteCategory',
    async ({categoryId, data}: UpdateWasteCategory) => await patchWasteCategory(categoryId, data)
);

export const newWasteCategoryItem = createAsyncThunk(
    'wasteCategories/newWasteCategoryItem',
    async ({wasteCategoryId, data}: CreateWasteCategoryItem) =>
        await createWasteCategoryItem(wasteCategoryId, data)
);

export const deleteWasteCategory = createAsyncThunk(
    'wasteCategories/deleteWasteCategory',
    async (categoryId: string) => await removeWasteCategory(categoryId)
);

export const deleteWasteCategoryItem = createAsyncThunk(
    'wasteCategories/deleteWasteCategoryItem',
    async ({wasteCategoryId, itemId}: RemoveWasteCategoryItem) =>
        await removeWasteCategoryItem(wasteCategoryId, itemId)
);

export const wasteCategorySlice = createSlice({
    name: 'wasteCategories',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadWasteCategories.fulfilled, (state, action) => {
                state.wasteCategoriesData = action.payload;
                state.loaded = true;
                state.error = null;
            })
            .addCase(loadWasteCategories.rejected, (state, action) => {
                state.loaded = true;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(newWasteCategory.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newWasteCategory.fulfilled, (state, action) => {
                state.isSubmitting = false;
                state.error = null;
                state.wasteCategoriesData.push(action.payload);
            })
            .addCase(newWasteCategory.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(updateWasteCategory.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateWasteCategory.fulfilled, (state, action) => {
                const {id} = action.payload;
                const currentIndex = getArrayIndex(state.wasteCategoriesData, id);

                state.isSubmitting = false;
                state.error = null;
                state.wasteCategoriesData[currentIndex] = action.payload;
            })
            .addCase(updateWasteCategory.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(newWasteCategoryItem.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newWasteCategoryItem.fulfilled, (state, action) => {
                const {id} = action.payload;
                const currentIndex = getArrayIndex(state.wasteCategoriesData, id);

                state.isSubmitting = false;
                state.error = null;
                state.wasteCategoriesData[currentIndex] = action.payload;
            })
            .addCase(newWasteCategoryItem.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(deleteWasteCategoryItem.fulfilled, (state, action) => {
                const {wasteCategoryId, itemId} = action.meta.arg;

                const currentIndex = getArrayIndex(state.wasteCategoriesData, wasteCategoryId);
                const currentItemIndex = getArrayIndex(
                    state.wasteCategoriesData[currentIndex].items,
                    itemId
                );

                state.error = null;
                state.isSubmitting = false;
                state.wasteCategoriesData[currentIndex].items.splice(currentItemIndex, 1);
            })

            .addCase(deleteWasteCategory.fulfilled, (state, action) => {
                const id = action.meta.arg;

                state.error = null;
                state.isSubmitting = false;
                state.wasteCategoriesData = state.wasteCategoriesData.filter(
                    (data) => data.id !== id
                );
            });
    },
});

export const {clearError} = wasteCategorySlice.actions;

export const wasteCategories = (state: RootState) => state.wasteCategories.wasteCategoriesData;
export const wasteCategoriesError = (state: RootState) => state.wasteCategories.error;
export const wasteCategoriesLoaded = (state: RootState) => state.wasteCategories.loaded;
export const wasteCategoriesIsSubmitting = (state: RootState) => state.wasteCategories.isSubmitting;

export const useWasteCategoriesData = () =>
    useReduxData(wasteCategories, wasteCategoriesLoaded, loadWasteCategories);

export default wasteCategorySlice.reducer;
