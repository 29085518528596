import {FC} from 'react';
import {Icon} from '../../components/UI/Icon';
import {Input} from '../../components/UI/Input';
import s from './index.module.scss';
import {Button} from '../../components/UI/Button';
import {TextButton} from '../../components/UI/Button/Text';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useAppDispatch, useAppSelector} from '../../store/app-hooks';
import {authError, authSubmiting, requestPasswordResset} from '../../store/auth/authSlice';
import {ErrorMessage} from '../../components/UI/ErrorMessage';
import {useNotification} from '../../store/notification/NotificationContext';

interface IFormInputs {
    email: string;
}

const initial: IFormInputs = {email: ''};

const schema = Yup.object({
    email: Yup.string().email().required(),
});

const PasswordResetPage: FC = () => {
    const navigate = useNavigate();
    const {addNotification} = useNotification();
    const error = useAppSelector(authError);
    const isSubmiting = useAppSelector(authSubmiting);
    const dispatch = useAppDispatch();

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
        defaultValues: initial,
    });

    const handleFormSubmit = (data: IFormInputs) => {
        dispatch(requestPasswordResset(data.email))
            .unwrap()
            .then(() => {
                addNotification(
                    'You will receive an email containing instructions to reset your password',
                    'success'
                );
                navigate('../login');
            });
    };

    return (
        <form
            className={s.container}
            onSubmit={handleSubmit((formData) => handleFormSubmit(formData))}
        >
            <Icon name="Logo" viewbox="0 0 72 72" size="160" variant="secondaryAccent" />
            <div className={s.form}>
                <div className={s.infoMessage}>
                    Please enter your email and you will receive instructions to reset your password
                </div>
                <Input
                    type="email"
                    label="Email"
                    required
                    error={!!errors.email}
                    errorMessage={errors.email?.message}
                    {...register('email')}
                />
                {error && <ErrorMessage message={error} />}
            </div>
            <div className={s.actions}>
                <Button label="Sumbit" variant="Primary" type="submit" disabled={isSubmiting} />
                <TextButton
                    type="button"
                    label="Back to Login"
                    variant="Secondary"
                    onClick={() => navigate('../login')}
                />
            </div>
        </form>
    );
};

export default PasswordResetPage;
