import * as Yup from 'yup';
import {FIELD_ERRORS} from '../../../constants';
import {IWasteTypeDTO} from '../../../models/wasteType';

export interface IWasteTypeForm extends IWasteTypeDTO {
    title: string;
    description: string;
}

export const initial: IWasteTypeForm = {title: '', description: ''};

export const validationSchema = Yup.object({
    title: Yup.string().required(FIELD_ERRORS.REQUIRED),
    description: Yup.string(),
});
