import ListContainer from '../../../components/shared/ListContainer';
import ListItem from '../../../components/shared/ListItem';

import s from '../index.module.scss';
import {
    clearError,
    deleteNoCleaningReason,
    useNoCleaningReasons,
} from '../../../store/noCleaningReason/noCleaningReasonSlice';
import {useState} from 'react';
import NoCleaningReasonModal from '../../../components/shared/NoCleaningReasonModal';
import {useAppDispatch} from '../../../store/app-hooks';
import {INoCleaningReason} from '../../../models/noCleaningReason';
import useConfirm from '../../../hooks/useConfirm';
import {useNotification} from '../../../store/notification/NotificationContext';
import {FIELD_LABELS} from '../../../constants';

export const NoActivitiesConfigurationContent = () => {
    const dispatch = useAppDispatch();
    const {confirm} = useConfirm();
    const {addNotification} = useNotification();

    const [noCleaningActivities, loadedNoCleaningActivities] = useNoCleaningReasons();
    const [openNewModal, setOpenNewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [currentReason, setCurrentReason] = useState<INoCleaningReason>();

    const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION} = FIELD_LABELS;

    // const handleViewArchivedClick = () => {};

    const closeNewModal = () => {
        dispatch(clearError());
        setOpenNewModal(false);
    };

    const closeEditModal = () => {
        dispatch(clearError());
        setOpenEditModal(false);
    };

    const onEditReason = (editedReason: INoCleaningReason) => {
        setOpenEditModal(true);
        setCurrentReason(editedReason);
    };

    const onDeleteReason = async (id: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result) {
            dispatch(deleteNoCleaningReason(id))
                .unwrap()
                .then(() => addNotification(SUCCESSFUL_DELETION, 'success'))
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    return loadedNoCleaningActivities ? (
        <div className={s.container}>
            <ListContainer
                onAddClick={() => setOpenNewModal(true)}
                // onViewArchivedClick={handleViewArchivedClick}
                title="No Cleaning Reasons"
            >
                {noCleaningActivities.map((reason) => (
                    <ListItem
                        key={reason.id}
                        title={reason.name}
                        onDeleteClick={() => onDeleteReason(reason.id)}
                        onEditClick={() => onEditReason(reason)}
                    />
                ))}
            </ListContainer>
            {openNewModal && <NoCleaningReasonModal closeModal={closeNewModal} />}
            {openEditModal && (
                <NoCleaningReasonModal
                    noCleaningReason={currentReason}
                    closeModal={closeEditModal}
                />
            )}
        </div>
    ) : (
        <div>Fetching data...</div>
    );
};
