import {ButtonHTMLAttributes, FC} from 'react';
import {IconNames} from '../../../../assets/utilities/icons-library';
import {Icon} from '../../Icon';
import s from '../index.module.scss';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: IconNames;
    variant?: 'Primary' | 'Secondary' | 'Tertiary';
}

export const FAB: FC<IProps> = ({icon = 'Plus', variant = 'Primary', ...rest}) => {
    const buttonVariants = () =>
        `${variant === 'Primary' && s.primary} ${variant === 'Secondary' && s.secondary} ${
            variant === 'Tertiary' && s.tertiary
        }`;
    const getIconVariants = () => {
        if (rest.disabled) {
            return 'onDisabled';
        }
        return 'onAccent';
    };

    return (
        <button className={`${s.FABtn} ${buttonVariants()}`} {...rest}>
            <Icon name={icon} variant={getIconVariants()} />
        </button>
    );
};
