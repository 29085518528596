import {format} from 'date-fns';
import {FC} from 'react';
import {IconButton} from '../Button/Icon';
import {ICalendarControls} from './Calendar';

import s from './calendar.module.scss';

const CalendarControls: FC<ICalendarControls> = ({
    selectedDate,
    onNextMonth,
    onNextYear,
    onPrevMonth,
    onPrevYear,
}) => {
    return (
        <div className={s.controls}>
            <IconButton icon="Backward" variant="Primary" onClick={onPrevYear} />
            <IconButton icon="ChevronLeft" variant="Primary" onClick={onPrevMonth} />
            <div className={s.controls__date}>{format(selectedDate, 'MMMM, yyyy')}</div>
            <IconButton icon="ChevronRight" variant="Primary" onClick={onNextMonth} />
            <IconButton icon="Forward" variant="Primary" onClick={onNextYear} />
        </div>
    );
};

export default CalendarControls;
