import {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {FABWrapper, PageContentWrapper, PageHeader, PageWrapper} from '../../Layout';
import {ProgramCard} from './ProgramCard';
import {FAB} from '../../components/UI/Button/FAB';
import s from './index.module.scss';
import {clearError, useProgramsData} from '../../store/programs/programsSlice';
import ProgramModal from '../../components/shared/ProgramModal';
import {useAppDispatch} from '../../store/app-hooks';

export const ProgramsPage: FC = () => {
    const dispatch = useAppDispatch();
    const [programs, programsLoaded] = useProgramsData();
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const closeModal = (programId?: string) => {
        setShowModal(false);
        dispatch(clearError());
        programId && navigate(`/programs/${programId}`);
    };

    return (
        <PageWrapper>
            <PageHeader title="Foundation Programs" />
            <PageContentWrapper>
                {programsLoaded ? (
                    <ul className={s.list}>
                        {programs.map((program) => (
                            <li key={program.id}>
                                <ProgramCard program={program} />
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div>
                        {/* TODO: replace div in future development when LoadingSpinner is created */}
                        Fetching programs
                    </div>
                )}
                <FABWrapper>
                    <FAB onClick={() => setShowModal(true)} />
                </FABWrapper>
            </PageContentWrapper>
            {showModal && <ProgramModal closeModal={closeModal} />}
        </PageWrapper>
    );
};
