import * as Yup from 'yup';
import {FIELD_ERRORS} from '../../../constants';
import {IWasteCategoryDTO} from '../../../models/wasteCategory';

export interface IWasteCategoryForm extends IWasteCategoryDTO {
    id: string;
}

export const initial: IWasteCategoryForm = {id: '', title: '', description: ''};

export const validationSchema = Yup.object({
    title: Yup.string().required(FIELD_ERRORS.REQUIRED),
    description: Yup.string(),
});
