import {ILocation} from '../models/cleanUp';

/**
 * Converts decimal degrees to degrees minutes seconds.
 *
 * @param dd the decimal degrees value.
 * @param isLng specifies whether the decimal degrees value is a longitude.
 * @return degrees minutes seconds string in the format 49°15'51.35"N
 */
export const convertToDms = (dd: number, isLng?: boolean) => {
    const dir = dd < 0 ? (isLng ? 'W' : 'S') : isLng ? 'E' : 'N';

    const absDd = Math.abs(dd);
    const deg = absDd | 0;
    const frac = absDd - deg;
    const min = (frac * 60) | 0;
    let sec = frac * 3600 - min * 60;
    // Round it to 2 decimal points.
    sec = Math.round(sec * 100) / 100;
    return deg + '°' + min + "'" + sec + '"' + dir;
};

export const coordinatesToString = (coordinates?: ILocation) => {
    return coordinates
        ? `${convertToDms(+coordinates.longitude, true)}, ${convertToDms(
              +coordinates.latitude,
              false
          )}`
        : '-';
};
