import {ButtonHTMLAttributes, FC} from 'react';
import {IconNames} from '../../../../assets/utilities/icons-library';
import {Icon} from '../../Icon';
import s from '../index.module.scss';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon: IconNames;
    variant?: 'Primary' | 'Secondary' | 'Tertiary' | 'Success' | 'Danger';
}

export const IconButton: FC<IProps> = ({icon, variant, ...rest}) => {
    const buttonVariants = () =>
        `${variant === 'Primary' && s.primary} ${variant === 'Secondary' && s.secondary} ${
            variant === 'Tertiary' && s.tertiary
        } ${variant === 'Success' && s.success} ${variant === 'Danger' && s.danger}`;

    const getIconVariants = () => {
        if (variant === 'Primary' && !rest.disabled) {
            return 'primaryAccent';
        }
        if (variant === 'Secondary' && !rest.disabled) {
            return 'secondaryAccent';
        }
        if (variant === 'Tertiary' && !rest.disabled) {
            return 'tertiaryAccent';
        }
        if (variant === 'Success' && !rest.disabled) {
            return 'success';
        }
        if (variant === 'Danger' && !rest.disabled) {
            return 'danger';
        }
        if (rest.disabled) {
            return 'onDisabled';
        }
        return 'onBackground';
    };
    return (
        <button className={`${s.icon} ${buttonVariants()}`} {...rest}>
            <Icon name={icon} variant={getIconVariants()} size="32" />
        </button>
    );
};
