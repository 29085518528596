import apiClient from './client';
import {INoCleaningReason, INoCleaningReasonDTO} from '../models/noCleaningReason';

export const getNoCleaningReasons = async (): Promise<INoCleaningReason[]> => {
    try {
        const response = await apiClient.get<INoCleaningReason[]>('/no-cleaning-reasons');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createNoCleaningReason = async (
    data: INoCleaningReasonDTO
): Promise<INoCleaningReason> => {
    try {
        const response = await apiClient.post<INoCleaningReason>('/no-cleaning-reasons', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchNoCleaningReason = async (
    reasonId: string,
    data: INoCleaningReasonDTO
): Promise<INoCleaningReason> => {
    try {
        const response = await apiClient.patch<INoCleaningReason>(
            `/no-cleaning-reasons/${reasonId}`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeNoCleaningReason = async (reasonId: string): Promise<void> => {
    try {
        await apiClient.delete(`/no-cleaning-reasons/${reasonId}`);
    } catch (error) {
        throw error;
    }
};
