import { FC } from 'react';
import {
  IconColor,
  IconNames,
  Icons,
} from '../../../assets/utilities/icons-library';
import swatches from '../../../assets/styles/swatches.module.scss';

interface IProps {
  name: IconNames;
  variant?: IconColor;
  size?: string;
  viewbox?: string;
}

// Add all IconColor entries
const CSS_SWATCHES_VARIANTS = [
  'onAccent',
  'onBackground',
  'onDisabled',
  'primaryAccent',
  'secondaryAccent',
  'tertiaryAccent',
  'danger',
  'success',
];

const getFillStyle = (variant: string) => ({
  fill: CSS_SWATCHES_VARIANTS.includes(variant) ? swatches[variant] : variant,
});

export const Icon: FC<IProps> = ({
  name,
  variant = 'onBackground',
  size = '38',
  viewbox = '0 0 38 38',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={viewbox}
      style={getFillStyle(variant)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {Icons[name]()}
    </svg>
  );
};
