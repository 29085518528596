import * as Yup from 'yup';
import {FIELD_ERRORS} from '../../../constants';

export interface ICostForm {
    id: string;
    name: string;
    amount: number;
    activityId: string;
    date: string | null;
}

export const initial: ICostForm = {id: '', name: '', amount: 0, date: null, activityId: ''};

export const validationSchema = Yup.object({
    name: Yup.string().required(FIELD_ERRORS.REQUIRED),
    amount: Yup.number().positive().required(FIELD_ERRORS.REQUIRED),
    activityId: Yup.string().required(FIELD_ERRORS.REQUIRED),
    date: Yup.date().nullable().required(FIELD_ERRORS.REQUIRED),
});
