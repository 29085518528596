import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IMonthReportItem} from '../../models/daily-report';
import {loadMonthView} from '../../api/daily-report';
import {RootState} from '../store';
import {useAppDispatch, useAppSelector} from '../app-hooks';
import {useEffect} from 'react';
import {isSameMonth} from 'date-fns';

interface IDailyReportState {
    clean_ups: IMonthReportItem[];
    no_cleaning_records: IMonthReportItem[];
    loaded_months: string[];
    error: string | null;
}

const loadReportByMonth = createAsyncThunk('daily-reports', (date: string) => loadMonthView(date));

const initialState: IDailyReportState = {
    clean_ups: [],
    no_cleaning_records: [],
    loaded_months: [],
    error: null,
};

const dailyReportsSlice = createSlice({
    name: 'daily-reports',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loadReportByMonth.pending, (state) => {
                state.error = null;
            })
            .addCase(loadReportByMonth.fulfilled, (state, action) => {
                state.error = null;
                if (!state.loaded_months.includes(action.meta.arg)) {
                    state.clean_ups.push(...action.payload.clean_ups);
                    state.no_cleaning_records.push(...action.payload.no_cleaning_records);
                    state.loaded_months.push(action.meta.arg);
                }
            })
            .addCase(loadReportByMonth.rejected, (state, payload) => {
                state.error = payload.error.message || 'Something went wrong';
            });
    },
});

const clean_ups_data = (state: RootState) => state.dailyReports.clean_ups;
const no_cleaning_records_data = (state: RootState) => state.dailyReports.no_cleaning_records;
const loaded_months = (state: RootState) => state.dailyReports.loaded_months;

export const useMonthReport = (date: string) => {
    const value = new Date(date);
    const dispath = useAppDispatch();
    const loaded = useAppSelector(loaded_months).includes(date);
    const clean_ups = useAppSelector(clean_ups_data).filter((clean_up) =>
        isSameMonth(new Date(clean_up.date), value)
    );
    const no_cleaning_records = useAppSelector(no_cleaning_records_data).filter((clean_up) =>
        isSameMonth(new Date(clean_up.date), value)
    );
    useEffect(() => {
        if (!loaded && date) {
            dispath(loadReportByMonth(date));
        }
    }, [loaded, date, dispath]);

    return [{clean_ups, no_cleaning_records}, loaded] as const;
};

export default dailyReportsSlice.reducer;
