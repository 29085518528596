import {IUsedResources} from '../models/cleanUp';
import {useResources} from '../store/resource/resource';
import {BoardData} from '../components/bloc/DailyReport/CleanUp/CleanUpBoard';
import {useMemo} from 'react';

export const useCleanUpResources = (cleanUpResources?: IUsedResources[]) => {
    const [generalResources, loaded] = useResources();

    const totalResourcesData: BoardData[] = useMemo(() => {
        const totalGeneralResources =
            cleanUpResources?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0;

        const currentResources = cleanUpResources?.map((res) => ({
            label: generalResources.find((gnr) => gnr.id === res.resource)?.title,
            info: `${res.amount}`,
        }));

        return [
            {
                label: 'Total',
                info: `${totalGeneralResources}`,
            },
            ...(currentResources ? currentResources : []),
        ];
    }, [generalResources, cleanUpResources]);

    return [totalResourcesData, loaded] as const;
};
