export enum Role {
    admin = 'admin',
    collector = 'collector',
    director = 'director',
}

export interface IUser {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    role: Role;
    is_active: boolean;
    created_at: Date;
    updated_at: Date;
}

export interface IUserDTO {
    first_name: string;
    last_name: string;
    email: string;
    role?: Role;
    is_active: boolean;
    phone?: string;
    programId?: string;
    teamId?: string;
}
