import {PageHeader, PageWrapper} from '../../../Layout';
import {DataConfigCard} from '../../../components/shared/DataConfigCard';
import classes from './index.module.scss';
import RowItem from '../../../components/bloc/DailyReport/Day/RowItem';
import {useSearchParams} from 'react-router-dom';
import {useCleanUpByDate} from '../../../store/cleanUp/cleanUpSlice';
import {dateToString} from '../../../utils/date_helpers';
import {useDayReportCleanUpData} from '../../../hooks/useDayReportCleanUpData';

import {useGroupedNoCleaningRecords} from '../../../hooks/useGroupedNoCleaningRecords';
import {LoadingContainer} from '../../../components/UI/LoadingContainer';
import DownloadLink from '../../../components/UI/PdfReport/DownloadLink';

export const DailyReportsDay = () => {
    const [params] = useSearchParams();
    const selectedDate = params.get('date');
    const currentDay = dateToString(new Date());

    const [cleanUps, loadedCleanUps] = useCleanUpByDate(selectedDate ?? currentDay);
    const [groupedCleanUps, programsLoaded] = useDayReportCleanUpData(cleanUps);
    const [noCleaningRecords, noCleaningRecordsLoaded] = useGroupedNoCleaningRecords(
        selectedDate ?? currentDay
    );

    const loadedData = loadedCleanUps && programsLoaded && noCleaningRecordsLoaded;

    return (
        <PageWrapper>
            <PageHeader returnIcon title={selectedDate ?? currentDay} />
            {loadedData ? (
                <>
                    <DownloadLink date={selectedDate ?? currentDay} />
                    {groupedCleanUps?.map((cleanUp) => (
                        <div key={cleanUp.program} className={`${classes.container}`}>
                            <DataConfigCard readOnly title={cleanUp.program}>
                                <RowItem cleanUp={cleanUp} />
                            </DataConfigCard>
                        </div>
                    ))}
                    {noCleaningRecords.map((record) => (
                        <div key={record.id} className={`${classes.container}`}>
                            <DataConfigCard readOnly title={record.program}>
                                <div className={classes['no_cleaning_record']}>
                                    No cleaning activities due to{' '}
                                    <span className={classes['no_cleaning_record__name']}>
                                        {record.reason}
                                    </span>
                                </div>
                            </DataConfigCard>
                        </div>
                    ))}
                </>
            ) : (
                <LoadingContainer />
            )}
        </PageWrapper>
    );
};
