import * as Yup from 'yup';
import {FIELD_ERRORS} from '../../../constants';

export interface IResourceForm {
    id: string;
    title: string;
    capacity?: string;
}

export const initial: IResourceForm = {id: '', title: '', capacity: ''};

export const getValidationSchema = (isLitterBag: boolean) =>
    Yup.object({
        title: Yup.string().required(FIELD_ERRORS.REQUIRED),
        capacity: Yup.string().when([], {
            is: () => isLitterBag,
            then: Yup.string().required(FIELD_ERRORS.REQUIRED),
            otherwise: Yup.string().notRequired().nullable(),
        }),
    });
