import ListContainer from '../../../../components/shared/ListContainer';
import {ListItemWithChildren} from '../../../../components/shared/ListItemWithChildren';
import {
    clearError,
    deleteWasteCategory,
    deleteWasteCategoryItem,
    RemoveWasteCategoryItem,
    useWasteCategoriesData,
} from '../../../../store/wasteCategory/wasteCategorySlice';
import {useState} from 'react';
import WasteCategoryModal from '../../../../components/shared/WasteCategoryModal';
import {useAppDispatch} from '../../../../store/app-hooks';
import {IWasteCategory} from '../../../../models/wasteCategory';
import {IWasteCategoryForm} from '../../../../components/shared/WasteCategoryModal/formModel';
import useConfirm from '../../../../hooks/useConfirm';
import {useNotification} from '../../../../store/notification/NotificationContext';
import {FIELD_LABELS} from '../../../../constants';
import WasteCategoryItemModal from '../../../../components/shared/WasteCategoryItemModal';

export const WasteCategoriesConfig = () => {
    const dispatch = useAppDispatch();

    const {confirm} = useConfirm();
    const {addNotification} = useNotification();

    const [wasteCategories, isLoaded] = useWasteCategoriesData();
    const [openNewModal, setOpenNewModal] = useState(false);
    const [openNewItemModal, setOpenNewItemModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState<IWasteCategoryForm>();

    const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION} = FIELD_LABELS;

    const handleAddNewClick = () => setOpenNewModal(true);
    // const handleViewArchivedClick = () => {};

    const closeAddNewModal = () => {
        dispatch(clearError());
        setOpenNewModal(false);
    };

    const closeAddNewItemModal = () => {
        dispatch(clearError());
        setOpenNewItemModal(false);
    };

    const closeEditModal = () => {
        dispatch(clearError());
        setOpenEditModal(false);
    };

    const handleEdit = (category: IWasteCategory) => {
        setOpenEditModal(true);
        setSelectedCategory(category);
    };

    const handleAddNewCategoryItem = (category: IWasteCategory) => {
        setOpenNewItemModal(true);
        setSelectedCategory(category);
    };

    const onDelete = async (id: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result) {
            dispatch(deleteWasteCategory(id))
                .unwrap()
                .then(() => addNotification(SUCCESSFUL_DELETION, 'success'))
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    const handleRemoveCategoryItem = async (wasteCategoryId: string, itemId: string) => {
        const data: RemoveWasteCategoryItem = {
            wasteCategoryId,
            itemId,
        };
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result) {
            dispatch(deleteWasteCategoryItem(data))
                .unwrap()
                .then(() => addNotification(SUCCESSFUL_DELETION, 'success'))
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    return isLoaded ? (
        <>
            <ListContainer
                onAddClick={handleAddNewClick}
                // onViewArchivedClick={handleViewArchivedClick}
                title="Waste Categories"
            >
                {wasteCategories.map((category) => (
                    <ListItemWithChildren
                        key={category.id}
                        title={category.title}
                        description={category.description}
                        menuActions={[
                            {label: 'Edit Info', action: () => handleEdit(category)},
                            {
                                label: 'Add New Item',
                                action: () => handleAddNewCategoryItem(category),
                            },
                            {label: 'View Archived Items', action: () => {}},
                            {label: 'Remove Category', action: () => onDelete(category.id)},
                        ]}
                        items={category?.items}
                        onRemoveChip={handleRemoveCategoryItem}
                        listChildId={category.id}
                    />
                ))}
            </ListContainer>
            {openNewModal && <WasteCategoryModal closeModal={closeAddNewModal} />}
            {openNewItemModal && (
                <WasteCategoryItemModal
                    wasteCategoryId={selectedCategory!.id}
                    closeModal={closeAddNewItemModal}
                />
            )}
            {openEditModal && (
                <WasteCategoryModal wasteCategory={selectedCategory} closeModal={closeEditModal} />
            )}
        </>
    ) : (
        <div>Waiting for data to load...</div>
    );
};
