import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {useReduxData} from '../../hooks/useReduxData';
import {RootState} from '../store';
import {
    IAddProgramToSubstrateOptionDTO,
    ISubstrateOption,
    ICreateSubstrateOptionDTO,
    IUpdateSubstrateOptionDTO,
} from '../../models/substrateOption';
import {
    createNewSubstrateOption,
    getSubstrateOptions,
    patchSubstrateOption,
    removeSubstrateOption,
    addProgramToSubstrateOption as addProgramToSubstrateOptionApi,
    removeProgramFmSubstrateOption as removeProgramFmSubstrateOptionApi,
} from '../../api/substrateOptions';
import {getArrayIndex} from '../../utils/array_helpers';
import {IRemoveProgramFmResourceDTO} from '../../models/resource';

interface ISubstrateOptionState {
    substrateOptionsData: ISubstrateOption[];
    error: string | null;
    loaded: boolean;
    isSubmitting: boolean;
}

const initialState: ISubstrateOptionState = {
    substrateOptionsData: [],
    error: null,
    loaded: false,
    isSubmitting: false,
};

type UpdateSubstrateOptionPayload = {option_id: string; data: IUpdateSubstrateOptionDTO};

type AddProgramToSubstrateOptionPayload = {
    option_id: string;
    data: IAddProgramToSubstrateOptionDTO;
};
type RemoveProgramFmSubstrateOptionPayload = {
    option_id: string;
    data: IRemoveProgramFmResourceDTO;
};

export const loadSubstrateOptions = createAsyncThunk(
    'substrateOptions/loadSubstrateOptions',
    async () => await getSubstrateOptions()
);

export const newSubstrateOption = createAsyncThunk(
    'substrateOptions/newSubstrateOption',
    async (data: ICreateSubstrateOptionDTO) => await createNewSubstrateOption(data)
);

export const updateSubstrateOption = createAsyncThunk(
    'substrateOptions/updateSubstrateOption',
    async ({option_id, data}: UpdateSubstrateOptionPayload) =>
        await patchSubstrateOption(option_id, data)
);

export const deleteSubstrateOption = createAsyncThunk(
    'substrateOptions/deleteSubstrateOption',
    async (id: string) => await removeSubstrateOption(id)
);

export const addProgramToSubstrateOption = createAsyncThunk(
    'substrateOptions/addProgram',
    async ({option_id, data}: AddProgramToSubstrateOptionPayload, {dispatch}) => {
        try {
            dispatch(addProgram({option_id, data}));
            return await addProgramToSubstrateOptionApi(option_id, data);
        } catch (error) {
            dispatch(removeProgram({option_id, data}));
            throw error;
        }
    }
);

export const removeProgramFmSubstrateOption = createAsyncThunk(
    'substrateOptions/removeProgram',
    async ({option_id, data}: RemoveProgramFmSubstrateOptionPayload, {dispatch}) => {
        try {
            dispatch(removeProgram({option_id, data}));
            return await removeProgramFmSubstrateOptionApi(option_id, data);
        } catch (error) {
            dispatch(addProgram({option_id, data}));
            throw error;
        }
    }
);

export const substrateOptionsSlice = createSlice({
    name: 'substrateOptions',
    initialState,
    reducers: {
        clearErrorMessage: (state) => {
            state.error = null;
        },
        addProgram: (state, action: PayloadAction<AddProgramToSubstrateOptionPayload>) => {
            const {option_id, data} = action.payload;
            const index = getArrayIndex(state.substrateOptionsData, option_id);
            state.substrateOptionsData[index].programs.push(data.programId);
        },
        removeProgram: (state, action: PayloadAction<RemoveProgramFmSubstrateOptionPayload>) => {
            const {option_id, data} = action.payload;
            const index = getArrayIndex(state.substrateOptionsData, option_id);
            state.substrateOptionsData[index].programs = state.substrateOptionsData[
                index
            ].programs.filter((p) => p !== data.programId);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadSubstrateOptions.fulfilled, (state, action) => {
                state.substrateOptionsData = action.payload;
                state.loaded = true;
                state.error = null;
            })
            .addCase(loadSubstrateOptions.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
                state.loaded = true;
            })
            .addCase(newSubstrateOption.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newSubstrateOption.fulfilled, (state, action) => {
                state.isSubmitting = false;
                state.error = null;
                state.substrateOptionsData.push(action.payload);
            })
            .addCase(newSubstrateOption.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(updateSubstrateOption.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateSubstrateOption.fulfilled, (state, action) => {
                const {id} = action.payload;
                const currentIndex = getArrayIndex(state.substrateOptionsData, id);

                state.isSubmitting = false;
                state.error = null;
                state.substrateOptionsData[currentIndex] = action.payload;
            })
            .addCase(updateSubstrateOption.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(deleteSubstrateOption.fulfilled, (state, action) => {
                const id = action.meta.arg;

                state.error = null;
                state.isSubmitting = false;
                state.substrateOptionsData = state.substrateOptionsData.filter(
                    (data) => data.id !== id
                );
            });
    },
});

export const {clearErrorMessage, addProgram, removeProgram} = substrateOptionsSlice.actions;

export const substrateOptions = (state: RootState) => state.substrateOptions.substrateOptionsData;
export const substrateOptionsError = (state: RootState) => state.substrateOptions.error;
export const substrateOptionsLoaded = (state: RootState) => state.substrateOptions.loaded;
export const substrateOptionsIsSubmitting = (state: RootState) =>
    state.substrateOptions.isSubmitting;

export const useSubstrateOptionsData = () =>
    useReduxData(substrateOptions, substrateOptionsLoaded, loadSubstrateOptions);

export default substrateOptionsSlice.reducer;
