import {FC, useState} from 'react';
import {Modal} from '../../UI/Modal';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Input} from '../../UI/Input';
import {useForm} from 'react-hook-form';
import {IChangePasswordForm, initial, validationSchema} from './formModel';
import {yupResolver} from '@hookform/resolvers/yup';
import {changePassword} from '../../../api/auth';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {useNotification} from '../../../store/notification/NotificationContext';

type ChangePasswordModalProps = {
    onClose: () => void;
};

const ChangePasswordModal: FC<ChangePasswordModalProps> = ({onClose}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string>();
    const {addNotification} = useNotification();

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IChangePasswordForm>({
        defaultValues: initial,
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data: IChangePasswordForm) => {
        try {
            setIsSubmitting(true);
            setError(undefined);
            await changePassword(data.currentPassword, data.newPassword);
            addNotification('Password Changed Successfully', 'success');
            onClose();
        } catch (error: any) {
            setIsSubmitting(false);
            setError(error.message);
        }
    };

    return (
        <Modal title="Change Password">
            <ModalBody>
                <Input
                    label="Current Password"
                    placeholder="Type here"
                    type="password"
                    required
                    error={!!errors.currentPassword?.message}
                    errorMessage={errors.currentPassword?.message}
                    {...register('currentPassword')}
                />
                <Input
                    label="New Password"
                    placeholder="Type here"
                    type="password"
                    required
                    error={!!errors.newPassword?.message}
                    errorMessage={errors.newPassword?.message}
                    {...register('newPassword')}
                />
                <Input
                    label="Confirm New Password"
                    placeholder="Type here"
                    type="password"
                    required
                    error={!!errors.confirmNewPassword?.message}
                    errorMessage={errors.confirmNewPassword?.message}
                    {...register('confirmNewPassword')}
                />
                {error && <ErrorMessage message={error} />}
            </ModalBody>

            <ModalActions>
                <Button
                    type="submit"
                    label="Submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isSubmitting}
                />
                <TextButton variant="Danger" label="Cancel" onClick={onClose} />
            </ModalActions>
        </Modal>
    );
};

export default ChangePasswordModal;
