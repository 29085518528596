import {FC} from 'react';
import {View, Text, StyleSheet} from '@react-pdf/renderer';
import Logo from './Logo';
import {format} from 'date-fns';

const styles = StyleSheet.create({
    reportHeader: {
        height: '100pt',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    title: {
        fontFamily: 'Manrope',
        fontSize: '9pt',
    },
    subTitle: {
        fontFamily: 'Manrope',
        fontWeight: 'light',
        fontSize: '20pt',
    },
});

interface IProps {
    date: Date;
}

const Header: FC<IProps> = ({date}) => {
    return (
        <View style={styles.reportHeader}>
            <View style={{flex: 1}}>
                <Text style={styles.title}>
                    A.C. LASKARIDIS CHARITABLE FOUNDATION MARINE PROTECTION PROJECTS
                </Text>

                <Text style={styles.subTitle}>{`Daily Report of ${format(
                    date,
                    'MMMM do, yyyy'
                )}`}</Text>
            </View>

            <Logo />
        </View>
    );
};

export default Header;
