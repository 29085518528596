import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {useReduxData} from '../../hooks/useReduxData';
import {
    IAdditionalInfo,
    IAdditionalInfoDTO,
    IAddProgramToAddtionalInfoDTO,
    IRemoveProgramToAddtionalInfoDTO,
} from '../../models/additionalInfo';
import {
    createNewAdditionalInfo,
    getAdditionalInfo,
    patchAdditionalInfo,
    removeAdditionalInfo,
    addProgramToAdditionalInfo as addProgramToAdditionalInfoApi,
    removeProgramFmAdditionalInfo as removeProgramFmAdditionalInfoApi,
} from '../../api/additionalInfo';
import {RootState} from '../store';
import {getArrayIndex} from '../../utils/array_helpers';

interface IAdditionalInfoState {
    additionalInfoData: IAdditionalInfo[];
    error: string | null;
    loaded: boolean;
    isSubmitting: boolean;
}

const initialState: IAdditionalInfoState = {
    additionalInfoData: [],
    error: null,
    loaded: false,
    isSubmitting: false,
};

type UpdateAdditionalInfoPayload = {option_id: string; data: IAdditionalInfoDTO};
type AddProgramToAdditionalInfoPayload = {option_id: string; data: IAddProgramToAddtionalInfoDTO};
type RemoveProgramFmAdditionalInfoPayload = {
    option_id: string;
    data: IRemoveProgramToAddtionalInfoDTO;
};

export const loadAdditionalInfo = createAsyncThunk(
    'additionalInfo/loadAdditionalInfo',
    async () => await getAdditionalInfo()
);

export const newAdditionalInfo = createAsyncThunk(
    'additionalInfo/newAdditionalInfo',
    async (data: IAdditionalInfoDTO) => await createNewAdditionalInfo(data)
);

export const updateAdditionalInfo = createAsyncThunk(
    'additionalInfo/updateAdditionalInfo',
    async ({option_id, data}: UpdateAdditionalInfoPayload) =>
        await patchAdditionalInfo(option_id, data)
);

export const deleteAdditionalInfo = createAsyncThunk(
    'additionalInfo/deleteAdditionalInfo',
    async (id: string) => await removeAdditionalInfo(id)
);

export const addProgramToAdditionalInfo = createAsyncThunk(
    'additionalInfo/addProgram',
    async ({option_id, data}: AddProgramToAdditionalInfoPayload, {dispatch}) => {
        try {
            dispatch(addProgram({option_id, data}));
            return await addProgramToAdditionalInfoApi(option_id, data);
        } catch (error) {
            dispatch(removeProgram({option_id, data}));
            throw error;
        }
    }
);

export const removeProgramFmAdditionalInfo = createAsyncThunk(
    'additionalInfo/removeProgram',
    async ({option_id, data}: RemoveProgramFmAdditionalInfoPayload, {dispatch}) => {
        try {
            dispatch(removeProgram({option_id, data}));
            return await removeProgramFmAdditionalInfoApi(option_id, data);
        } catch (error) {
            dispatch(addProgram({option_id, data}));
            throw error;
        }
    }
);

export const additionalInfoSlice = createSlice({
    name: 'additionalInfo',
    initialState,
    reducers: {
        clearErrorMessage: (state) => {
            state.error = null;
        },
        addProgram: (state, action: PayloadAction<AddProgramToAdditionalInfoPayload>) => {
            const {option_id, data} = action.payload;
            const index = getArrayIndex(state.additionalInfoData, option_id);
            state.additionalInfoData[index].programs.push(data.programId);
        },
        removeProgram: (state, action: PayloadAction<RemoveProgramFmAdditionalInfoPayload>) => {
            const {option_id, data} = action.payload;
            const index = getArrayIndex(state.additionalInfoData, option_id);
            state.additionalInfoData[index].programs = state.additionalInfoData[
                index
            ].programs.filter((p) => p !== data.programId);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadAdditionalInfo.fulfilled, (state, action) => {
                state.additionalInfoData = action.payload;
                state.loaded = true;
                state.error = null;
            })
            .addCase(loadAdditionalInfo.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
                state.loaded = true;
            })

            .addCase(newAdditionalInfo.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newAdditionalInfo.fulfilled, (state, action) => {
                state.isSubmitting = false;
                state.error = null;
                state.additionalInfoData.push(action.payload);
            })
            .addCase(newAdditionalInfo.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(updateAdditionalInfo.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateAdditionalInfo.fulfilled, (state, action) => {
                const {id} = action.payload;
                const currentIndex = getArrayIndex(state.additionalInfoData, id);

                state.isSubmitting = false;
                state.error = null;
                state.additionalInfoData[currentIndex] = action.payload;
            })
            .addCase(updateAdditionalInfo.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(deleteAdditionalInfo.fulfilled, (state, action) => {
                const id = action.meta.arg;

                state.error = null;
                state.isSubmitting = false;
                state.additionalInfoData = state.additionalInfoData.filter(
                    (data) => data.id !== id
                );
            })
            .addCase(addProgramToAdditionalInfo.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(removeProgramFmAdditionalInfo.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            });
    },
});

export const {clearErrorMessage, addProgram, removeProgram} = additionalInfoSlice.actions;

export const additionalInfo = (state: RootState) => state.additionalInfo.additionalInfoData;
export const additionalInfoError = (state: RootState) => state.additionalInfo.error;
export const additionalInfoLoaded = (state: RootState) => state.additionalInfo.loaded;
export const additionalInfoIsSubmitting = (state: RootState) => state.additionalInfo.isSubmitting;

export const useAdditionalInfoData = () =>
    useReduxData(additionalInfo, additionalInfoLoaded, loadAdditionalInfo);

export default additionalInfoSlice.reducer;
