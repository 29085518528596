import Details from './Details';
import {ICollectedWeight, IUsedLitterBag, IUsedResources} from '../../../../models/cleanUp';
import {useAggregateCollectedWeights} from '../../../../hooks/useAggregateCollectedWeights';
import {useAggregateResources} from '../../../../hooks/useAggregateResources';
import {useAggregateLitterBags} from '../../../../hooks/useAggregateLitterBags';

export type CustomLocation = {
    location_name: string | null;
    id: string;
};

export type ICleanUpPartial = {
    locations: CustomLocation[];
    program: string;
    collected_weights: ICollectedWeight[];
    used_general_resources: IUsedResources[];
    used_liter_bags: IUsedLitterBag[];
};

type RowItemProps = {
    cleanUp: ICleanUpPartial;
};

const RowItem = ({cleanUp}: RowItemProps) => {
    const {collected_weights, used_general_resources, locations, used_liter_bags} = cleanUp;

    const [aggregatedWeights, collectedWeightsLoaded] =
        useAggregateCollectedWeights(collected_weights);

    const [totalResources, resourcesDataLoaded] = useAggregateResources(used_general_resources);
    const [litterBagsData, litterBagsDataLoaded] = useAggregateLitterBags(used_liter_bags);

    const dataLoaded = collectedWeightsLoaded && resourcesDataLoaded && litterBagsDataLoaded;

    return dataLoaded ? (
        <div>
            <Details
                header="Collected Weight"
                subtitle="Totals of all collected waste for the day"
                data={aggregatedWeights.data.map((it) => `${it?.label}  ${it?.info}`)}
            />
            <Details
                header="Litter Bags"
                subtitle="Totals of all litter bags used in all clean ups"
                data={litterBagsData.map((it) => `${it?.label}  ${it?.info} Items`)}
            />
            <Details
                header="Resources"
                subtitle="Totals of all resources used in all clean ups"
                data={totalResources.map((it) => `${it?.label}  ${it?.info} Items`)}
            />
            <Details
                header="Clean Ups"
                subtitle="Press any location to view Clean Up details"
                data={locations}
                clickable
            />
        </div>
    ) : (
        <div>Fetching data</div>
    );
};

export default RowItem;
