import apiClient from './client';
import {
    ISubstrateOption,
    ICreateSubstrateOptionDTO,
    IAddProgramToSubstrateOptionDTO,
    IRemoveProgramToSubstrateOptionDTO,
    IUpdateSubstrateOptionDTO,
} from '../models/substrateOption';

export const getSubstrateOptions = async (): Promise<ISubstrateOption[]> => {
    try {
        const response = await apiClient.get<ISubstrateOption[]>('/substrate-options');
        return response.data;
    } catch (e) {
        throw new Error('Failed to fetch data');
    }
};

export const createNewSubstrateOption = async (
    data: ICreateSubstrateOptionDTO
): Promise<ISubstrateOption> => {
    try {
        const response = await apiClient.post<ISubstrateOption>('/substrate-options', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchSubstrateOption = async (
    id: string,
    data: IUpdateSubstrateOptionDTO
): Promise<ISubstrateOption> => {
    try {
        const response = await apiClient.patch<ISubstrateOption>(`/substrate-options/${id}`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeSubstrateOption = async (id: string): Promise<void> => {
    try {
        await apiClient.delete<ISubstrateOption>(`/substrate-options/${id}`);
    } catch (error) {
        throw error;
    }
};

export const addProgramToSubstrateOption = async (
    option_id: string,
    data: IAddProgramToSubstrateOptionDTO
): Promise<ISubstrateOption> => {
    try {
        const result = await apiClient.post<ISubstrateOption>(
            `/substrate-options/${option_id}/add-program`,
            data
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const removeProgramFmSubstrateOption = async (
    option_id: string,
    data: IRemoveProgramToSubstrateOptionDTO
): Promise<ISubstrateOption> => {
    try {
        const result = await apiClient.post<ISubstrateOption>(
            `/substrate-options/${option_id}/remove-program`,
            data
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};
