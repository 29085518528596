import {forwardRef, TextareaHTMLAttributes} from 'react';
import s from '../index.module.scss';

interface IProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    name?: string;
    label?: string;
    error?: boolean;
    errorMessage?: string;
    required?: boolean;
    valid?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, IProps>(
    ({label, valid, error, errorMessage, required, ...rest}, ref) => {
        const containerAltStyles = () =>
            `${rest.disabled && s.disabled} ${valid && s.valid} ${error && s.error}`;

        return (
            <div className={`${s.wrapper} ${s.textarea}`}>
                <div className={s.feedbackArea}>
                    {label && (
                        <label className={`${containerAltStyles()} ${required && s.required}`}>
                            {label}
                        </label>
                    )}
                    {error && errorMessage && <div className={s.message}>{errorMessage}</div>}
                </div>
                <div className={`${s.container} ${containerAltStyles()}`}>
                    <textarea ref={ref} {...rest} />
                </div>
            </div>
        );
    }
);
