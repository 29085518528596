import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {login, logout, loadUser, requestResetPassword} from '../../api/auth';
import {useReduxData} from '../../hooks/useReduxData';
import {IUser} from '../../models/user';
import {RootState} from '../store';

interface IAuthState {
    user: IUser | null;
    error: string | null;
    loaded: boolean;
    isSubmiting: boolean;
}

const initialState: IAuthState = {
    user: null,
    error: null,
    isSubmiting: false,
    loaded: false,
};

export const loginAction = createAsyncThunk(
    'auth/login',
    async ({email, password}: {email: string; password: string}, {rejectWithValue}) => {
        try {
            return login(email, password);
        } catch (error) {
            rejectWithValue(error);
            return null;
        }
    }
);

export const logoutAction = createAsyncThunk('auth/logut', async () => {
    await logout();
});

export const loadCurrentUser = createAsyncThunk('auth/loadCurrentUser', async () => {
    const user = await loadUser();
    return user;
});

export const requestPasswordResset = createAsyncThunk(
    'auth/requestPasswordResset',
    async (email: string) => {
        return requestResetPassword(email);
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginAction.pending, (state) => {
                state.isSubmiting = true;
                state.error = null;
            })
            .addCase(loginAction.fulfilled, (state, action) => {
                state.isSubmiting = false;
                state.user = action.payload;
            })
            .addCase(loginAction.rejected, (state, action) => {
                state.error = action.error.message || 'Something went wrong';
                state.isSubmiting = false;
            })
            .addCase(logoutAction.fulfilled, (state) => {
                return {...initialState, loaded: state.loaded};
            })
            .addCase(loadCurrentUser.fulfilled, (state, action) => {
                return {...state, loaded: true, user: action.payload, error: null};
            })
            .addCase(requestPasswordResset.pending, (state) => {
                state.isSubmiting = true;
                state.error = null;
            })
            .addCase(requestPasswordResset.rejected, (state, action) => {
                state.isSubmiting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(requestPasswordResset.fulfilled, (state) => {
                state.isSubmiting = false;
                state.error = null;
            });
    },
});

export const authUser = (state: RootState) => state.auth.user;
export const authSubmiting = (state: RootState) => state.auth.isSubmiting;
export const authError = (state: RootState) => state.auth.error;
export const authUserLoaded = (state: RootState) => state.auth.loaded;

export const useAuthData = () => useReduxData(authUser, authUserLoaded, loadCurrentUser);

export default authSlice.reducer;
