import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {useReduxData} from '../../hooks/useReduxData';
import {
    createWeatherCondition,
    createWeatherConditionListTypeOption,
    getWeatherConditions,
    patchWeatherCondition,
    removeWeatherCondition,
    removeWeatherConditionListTypeOption,
    addProgramToWeatherCondition as addProgramToWeatherConditionApi,
    removeProgramFmWeatherCondition as removeProgramFmWeatherConditionApi,
} from '../../api/weatherCondition';
import {
    IWeatherCondition,
    IWeatherConditionDTO,
    IAddProgramToWeatherConditionDTO,
    IRemoveProgramToWeatherConditionDTO,
} from '../../models/weatherCondition';
import {getArrayIndex} from '../../utils/array_helpers';

interface IWeatherConditionState {
    weatherData: IWeatherCondition[];
    error: string | null;
    loaded: boolean;
    isSubmitting: boolean;
}

const initialState: IWeatherConditionState = {
    weatherData: [],
    error: null,
    loaded: false,
    isSubmitting: false,
};

export type UpdateWeatherConditionProps = {
    weatherId: string;
    data: Pick<IWeatherConditionDTO, 'title' | 'unit'>;
};

export type CreateWeatherConditionListTypeProps = {
    weatherId: string;
    data: Pick<IWeatherConditionDTO, 'title'>;
};

export type DeleteWeatherConditionListTypeProps = {
    weatherId: string;
    optionId: string;
};

type AddProgramToWeatherConditionPayload = {
    condition_id: string;
    data: IAddProgramToWeatherConditionDTO;
};
type RemoveProgramFmWeatherConditioPayload = {
    condition_id: string;
    data: IRemoveProgramToWeatherConditionDTO;
};

export const loadWeatherConditions = createAsyncThunk(
    'weatherConditions/loadWeatherConditions',
    async () => await getWeatherConditions()
);

export const newWeatherCondition = createAsyncThunk(
    'weatherConditions/newWeatherCondition',
    async (data: IWeatherConditionDTO) => await createWeatherCondition(data)
);

export const updateWeatherCondition = createAsyncThunk(
    'weatherConditions/updateWeatherCondition',
    async ({weatherId, data}: UpdateWeatherConditionProps) =>
        await patchWeatherCondition(weatherId, data)
);

export const deleteWeatherCondition = createAsyncThunk(
    'weatherConditions/deleteWeatherCondition',
    async (weatherId: string) => await removeWeatherCondition(weatherId)
);

export const newWeatherConditionListTypeOption = createAsyncThunk(
    'weatherConditions/newWeatherConditionListTypeOption',
    async ({weatherId, data}: CreateWeatherConditionListTypeProps) =>
        await createWeatherConditionListTypeOption(weatherId, data)
);

export const deleteWeatherConditionListTypeOption = createAsyncThunk(
    'weatherConditions/deleteWeatherConditionListTypeOption',
    async ({weatherId, optionId}: DeleteWeatherConditionListTypeProps) =>
        await removeWeatherConditionListTypeOption(weatherId, optionId)
);

export const addProgramToWeatherCondition = createAsyncThunk(
    'weatherConditions/addProgram',
    async ({condition_id, data}: AddProgramToWeatherConditionPayload, {dispatch}) => {
        try {
            dispatch(addProgram({condition_id, data}));
            return await addProgramToWeatherConditionApi(condition_id, data);
        } catch (error) {
            dispatch(removeProgram({condition_id, data}));
            throw error;
        }
    }
);

export const removeProgramFmWeatherCondition = createAsyncThunk(
    'weatherConditions/removeProgram',
    async ({condition_id, data}: RemoveProgramFmWeatherConditioPayload, {dispatch}) => {
        try {
            dispatch(removeProgram({condition_id, data}));
            return await removeProgramFmWeatherConditionApi(condition_id, data);
        } catch (error) {
            dispatch(addProgram({condition_id, data}));
            throw error;
        }
    }
);

export const weatherConditionsSlice = createSlice({
    name: 'weatherConditions',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
        addProgram: (state, action: PayloadAction<AddProgramToWeatherConditionPayload>) => {
            const {condition_id, data} = action.payload;
            const index = getArrayIndex(state.weatherData, condition_id);
            state.weatherData[index].programs.push(data.programId);
        },
        removeProgram: (state, action: PayloadAction<RemoveProgramFmWeatherConditioPayload>) => {
            const {condition_id, data} = action.payload;
            const index = getArrayIndex(state.weatherData, condition_id);
            state.weatherData[index].programs = state.weatherData[index].programs.filter(
                (p) => p !== data.programId
            );
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadWeatherConditions.fulfilled, (state, action) => {
                state.weatherData = action.payload;
                state.loaded = true;
                state.error = null;
            })
            .addCase(loadWeatherConditions.rejected, (state, action) => {
                state.loaded = true;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(newWeatherCondition.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newWeatherCondition.fulfilled, (state, action) => {
                state.isSubmitting = false;
                state.error = null;
                state.weatherData.push(action.payload);
            })
            .addCase(newWeatherCondition.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(updateWeatherCondition.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(updateWeatherCondition.fulfilled, (state, action) => {
                const {weatherId} = action.meta.arg;
                const currentIndex = getArrayIndex(state.weatherData, weatherId);

                state.isSubmitting = false;
                state.error = null;
                state.weatherData[currentIndex] = action.payload;
            })
            .addCase(updateWeatherCondition.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(deleteWeatherCondition.fulfilled, (state, action) => {
                const id = action.meta.arg;

                state.error = null;
                state.isSubmitting = false;
                state.weatherData = state.weatherData.filter((data) => data.id !== id);
            })

            .addCase(newWeatherConditionListTypeOption.pending, (state) => {
                state.isSubmitting = true;
            })
            .addCase(newWeatherConditionListTypeOption.fulfilled, (state, action) => {
                const {id} = action.payload;
                const currentIndex = getArrayIndex(state.weatherData, id);

                state.isSubmitting = false;
                state.error = null;
                state.weatherData[currentIndex] = action.payload;
            })
            .addCase(newWeatherConditionListTypeOption.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })

            .addCase(deleteWeatherConditionListTypeOption.fulfilled, (state, action) => {
                const {weatherId, optionId} = action.meta.arg;

                const currentIndex = getArrayIndex(state.weatherData, weatherId);
                const currentItemIndex = getArrayIndex(
                    state.weatherData[currentIndex].options,
                    optionId
                );

                state.error = null;
                state.isSubmitting = false;
                state.weatherData[currentIndex].options.splice(currentItemIndex, 1);
            })
            .addCase(addProgramToWeatherCondition.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            })
            .addCase(removeProgramFmWeatherCondition.rejected, (state, action) => {
                state.isSubmitting = false;
                state.error = action.error.message || 'Something went wrong';
            });
    },
});

export const {clearError, addProgram, removeProgram} = weatherConditionsSlice.actions;

export const weatherConditions = (state: RootState) => state.weatherConditions.weatherData;
export const weatherConditionsError = (state: RootState) => state.weatherConditions.error;
export const weatherConditionsLoaded = (state: RootState) => state.weatherConditions.loaded;
export const weatherConditionsIsSubmitting = (state: RootState) =>
    state.weatherConditions.isSubmitting;

export const useWeatherConditionsData = () =>
    useReduxData(weatherConditions, weatherConditionsLoaded, loadWeatherConditions);

export default weatherConditionsSlice.reducer;
