import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../store/app-hooks';
import {authError, authSubmiting, authUser, loginAction} from '../../store/auth/authSlice';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {Button} from '../../components/UI/Button';
import {TextButton} from '../../components/UI/Button/Text';
import {Icon} from '../../components/UI/Icon';
import {Input} from '../../components/UI/Input';
import s from './index.module.scss';
import {ErrorMessage} from '../../components/UI/ErrorMessage';

interface IFormInputs {
    email: string;
    password: string;
}

const initial: IFormInputs = {email: '', password: ''};

const schema = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
});

const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
        defaultValues: initial,
    });

    const error = useAppSelector(authError);
    const isSubmiting = useAppSelector(authSubmiting);
    const user = useAppSelector(authUser);
    const dispatch = useAppDispatch();

    const handleLogin = (data: IFormInputs) => {
        dispatch(loginAction(data));
    };

    useEffect(() => {
        if (user != null) {
            const savedLocation = (location.state as any)?.from;
            const from = savedLocation?.pathname
                ? `${savedLocation?.pathname}`
                : savedLocation?.search
                ? `${savedLocation.pathname}${savedLocation.search}`
                : `/programs`;
            navigate(from, {replace: true});
        }
    }, [user, navigate, location]);

    return (
        <form className={s.container} onSubmit={handleSubmit((formData) => handleLogin(formData))}>
            <Icon name="Logo" viewbox="0 0 72 72" size="160" variant="secondaryAccent" />

            <div className={s.form}>
                <Input
                    type="email"
                    label="Email"
                    required
                    error={!!errors.email}
                    errorMessage={errors.email?.message}
                    {...register('email')}
                />
                <Input
                    label="Password"
                    type="password"
                    required
                    error={!!errors.password}
                    errorMessage={errors.password?.message}
                    {...register('password')}
                />
                {error && <ErrorMessage message={error} />}
            </div>
            <div className={s.actions}>
                <Button label="Login" variant="Primary" type="submit" disabled={isSubmiting} />
                <TextButton
                    type="button"
                    label="Forgot Password?"
                    variant="Secondary"
                    onClick={() => navigate('../password-reset')}
                />
            </div>
        </form>
    );
};

export default LoginPage;
