import {FC, useState} from 'react';
import {useAppDispatch} from '../store/app-hooks';
import {logoutAction} from '../store/auth/authSlice';
import s from './index.module.scss';
import {useNavigate} from 'react-router-dom';
import {IconButton} from '../components/UI/Button/Icon';
import {MoreMenu} from '../components/UI/MoreMenu';
import {MoreMenuItem} from '../components/UI/MoreMenu/MoreMenuItem';
import ChangePasswordModal from '../components/shared/ChangePasswordModal';

interface IWrapperProps {
    children: any;
}

interface IHeaderProps {
    title: string;
    returnIcon?: boolean;
}

export const PageWrapper: FC<IWrapperProps> = ({children}) => (
    <div className={s.wrapper}>{children}</div>
);

export const PageHeader: FC<IHeaderProps> = ({title, returnIcon}) => {
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const dispach = useAppDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispach(logoutAction());
    };

    const handleChangePassword = () => {
        setIsChangePasswordModalOpen(true);
    };

    const handleCloseChangePasswordModal = () => {
        setIsChangePasswordModalOpen(false);
    };

    return (
        <>
            <div className={s.header}>
                <div className={s.returnButton}>
                    {returnIcon && <IconButton icon="ChevronLeft" onClick={() => navigate(-1)} />}
                    <h1 className={s.title}>{title}</h1>
                </div>
                <MoreMenu>
                    <MoreMenuItem label="Change Password" onClick={handleChangePassword} />
                    <MoreMenuItem label="Logout" onClick={handleLogout} />
                </MoreMenu>
            </div>
            {isChangePasswordModalOpen && (
                <ChangePasswordModal onClose={handleCloseChangePasswordModal} />
            )}
        </>
    );
};

export const PageContentWrapper: FC<IWrapperProps> = ({children}) => {
    return <div className={s.contentWrapper}>{children}</div>;
};

export const FABWrapper: FC<IWrapperProps> = ({children}) => {
    return <div className={s.FABWrapper}>{children}</div>;
};
