import {isSameDay} from 'date-fns';
import {useMemo} from 'react';
import {ICalendarItem} from '../components/UI/Calendar/Calendar';
import {useMonthReport} from '../store/dailyReport/dailyReportSlice';

export const useCalendarItems = (date: string) => {
    const [reports, loaded] = useMonthReport(date);

    const calendarItems = useMemo(() => {
        const items: ICalendarItem[] = [];

        const cleanUps = reports.clean_ups;
        const noCleaningRecords = reports.no_cleaning_records;

        cleanUps.forEach((cleanUp) => {
            const exists = items.find(
                (item) => item.type === 'CLEAN_UP' && isSameDay(item.date, new Date(cleanUp.date))
            );

            if (exists) {
                exists.value += cleanUp.count;
            } else {
                items.push({type: 'CLEAN_UP', date: new Date(cleanUp.date), value: +cleanUp.count});
            }
        });

        noCleaningRecords.forEach((record) => {
            const exists = items.find(
                (item) => item.type === 'NO_CLEANING' && isSameDay(item.date, new Date(record.date))
            );

            if (exists) {
                exists.value += record.count;
            } else {
                items.push({
                    type: 'NO_CLEANING',
                    date: new Date(record.date),
                    value: record.count,
                });
            }
        });

        return items;
    }, [reports]);

    return [calendarItems, loaded] as const;
};
