import {FC, useState} from 'react';
import {useNotification} from '../../../store/notification/NotificationContext';
import {useAppDispatch} from '../../../store/app-hooks';
import {
    addProgramToWeatherCondition,
    removeProgramFmWeatherCondition,
} from '../../../store/weatherCondition/weatherCondition';

import {IProgram} from '../../../models/program';
import {IWeatherCondition} from '../../../models/weatherCondition';
import {DataConfigCard} from '../../shared/DataConfigCard';
import WeatherConditionModal from '../../shared/WeatherConditionModal';
import {ToggleChip} from '../../UI/Chips/ToggleChip';

import {FIELD_LABELS} from '../../../constants';

const {FAIL_OPERATION} = FIELD_LABELS;

interface IProps {
    program: IProgram;
    conditions: IWeatherCondition[];
}

const ProgramWheatherConditions: FC<IProps> = ({conditions, program}) => {
    const [openModal, setOpenModal] = useState(false);
    const dispath = useAppDispatch();
    const {addNotification} = useNotification();

    const handleAddCondition = (condition_id: string) => {
        dispath(addProgramToWeatherCondition({condition_id, data: {programId: program.id}}))
            .unwrap()
            .catch(() => addNotification(FAIL_OPERATION, 'error'));
    };

    const handleRemoveCondition = (condition_id: string) => {
        dispath(removeProgramFmWeatherCondition({condition_id, data: {programId: program?.id}}))
            .unwrap()
            .catch(() => addNotification(FAIL_OPERATION, 'error'));
    };

    return (
        <>
            <DataConfigCard title="WEATHER CONDITION OPTIONS" onAddNew={() => setOpenModal(true)}>
                {conditions.map((condition) => (
                    <ToggleChip
                        key={condition.id}
                        label={condition.title}
                        selected={condition.programs.includes(program.id)}
                        onClick={() =>
                            condition.programs.includes(program.id)
                                ? handleRemoveCondition(condition.id)
                                : handleAddCondition(condition.id)
                        }
                    />
                ))}
            </DataConfigCard>
            {openModal && (
                <WeatherConditionModal
                    isNumberInput={true}
                    programId={program.id}
                    closeModal={() => setOpenModal(false)}
                />
            )}
        </>
    );
};

export default ProgramWheatherConditions;
