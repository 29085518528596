import {IProgramDto} from '../../../models/program';
import * as Yup from 'yup';

export interface IProgramsForm extends IProgramDto {}

export const initial: IProgramsForm = {name: '', site_type_id: '', id: ''};

export const validationSchema = Yup.object({
    name: Yup.string().required('Required Field'),
    site_type_id: Yup.string().required('Required Field'),
});
