import apiClient from './client';
import {IAddUserToProgramDto, IAddUserToTeamDto, IProgram, IProgramDto} from '../models/program';
import {IActivity, IActivityDTO} from '../models/activity';
import {ICost, ICostDTO} from '../models/cost';
import {ITeam, ITeamDto} from '../models/team';

export const getProgramData = async (): Promise<IProgram[]> => {
    try {
        const response = await apiClient.get<IProgram[]>('/programs');
        return response.data;
    } catch (e) {
        throw new Error('Failed to fetch data');
    }
};

export const createNewProgram = async (data: IProgramDto): Promise<IProgram> => {
    const {id, ...payLoad} = data;

    try {
        const response = await apiClient.post<IProgram>('/programs', payLoad);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchProgram = async (data: IProgramDto): Promise<IProgram> => {
    const {id, ...payLoad} = data;

    try {
        const response = await apiClient.patch<IProgram>(`/programs/${id}`, payLoad);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeProgram = async (id: string): Promise<void> => {
    try {
        await apiClient.delete<IProgram>(`/programs/${id}`);
    } catch (error) {
        throw error;
    }
};

export const createNewActivity = async (
    programId: string,
    data: IActivityDTO
): Promise<IActivity> => {
    try {
        const response = await apiClient.post<IActivity>(`/programs/${programId}/activities`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchActivity = async (
    programId: string,
    activityId: string,
    data: IActivityDTO
): Promise<IActivity> => {
    try {
        const response = await apiClient.patch<IActivity>(
            `/programs/${programId}/activities/${activityId}`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeActivity = async (programId: string, activityId: string): Promise<void> => {
    try {
        await apiClient.delete<IActivity>(`/programs/${programId}/activities/${activityId}`);
    } catch (error) {
        throw error;
    }
};

export const createNewCost = async (programId: string, data: ICostDTO): Promise<ICost> => {
    try {
        const response = await apiClient.post<ICost>(`/programs/${programId}/costs`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchCost = async (
    programId: string,
    costId: string,
    data: ICostDTO
): Promise<ICost> => {
    try {
        const response = await apiClient.patch<ICost>(
            `/programs/${programId}/costs/${costId}`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeCost = async (programId: string, costId: string): Promise<void> => {
    try {
        await apiClient.delete(`/programs/${programId}/costs/${costId}`);
    } catch (error) {
        throw error;
    }
};

export const createTeam = async (programId: string, data: ITeamDto): Promise<ITeam> => {
    try {
        const response = await apiClient.post<ITeam>(`/programs/${programId}/teams`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchTeam = async (
    programId: string,
    teamId: string,
    data: ITeamDto
): Promise<ITeam> => {
    try {
        const response = await apiClient.patch<ITeam>(
            `/programs/${programId}/teams/${teamId}`,
            data
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeTeam = async (programId: string, teamId: string): Promise<void> => {
    try {
        await apiClient.delete(`/programs/${programId}/teams/${teamId}`);
    } catch (error) {
        throw error;
    }
};

export const addUsersToProgram = async (
    programId: string,
    data: IAddUserToProgramDto
): Promise<IProgram> => {
    try {
        const result = await apiClient.post<IProgram>(`/programs/${programId}/add-user`, data);
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const removeUserFromProgram = async (
    programId: string,
    userId: string
): Promise<IProgram> => {
    try {
        const result = await apiClient.post<IProgram>(
            `/programs/${programId}/remove-user/${userId}`
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const addUsersToTeam = async (
    programId: string,
    teamId: string,
    data: IAddUserToTeamDto
): Promise<ITeam> => {
    try {
        const result = await apiClient.post<ITeam>(
            `/programs/${programId}/teams/${teamId}/add-user`,
            data
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};

export const removeUserFromTeam = async (
    programId: string,
    teamId: string,
    userId: string
): Promise<ITeam> => {
    try {
        const result = await apiClient.post<ITeam>(
            `/programs/${programId}/teams/${teamId}/remove-user/${userId}`
        );
        return result.data;
    } catch (error) {
        throw error;
    }
};
