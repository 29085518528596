import {FC, useState} from 'react';
import {useNotification} from '../../../store/notification/NotificationContext';
import {useAppDispatch} from '../../../store/app-hooks';
import {
    addProgramToAdditionalInfo,
    removeProgramFmAdditionalInfo,
} from '../../../store/additionalInfo/additionalInfoSlice';

import {IAdditionalInfo} from '../../../models/additionalInfo';
import {IProgram} from '../../../models/program';

import AdditionalInfoModal from '../../shared/AdditionalInfoModal';
import {DataConfigCard} from '../../shared/DataConfigCard';
import {ToggleChip} from '../../UI/Chips/ToggleChip';

import {FIELD_LABELS} from '../../../constants';

const {FAIL_OPERATION} = FIELD_LABELS;

interface IProps {
    program: IProgram;
    additional_info: IAdditionalInfo[];
}

const ProgramAddtionalInfo: FC<IProps> = ({program, additional_info}) => {
    const [openModal, setOpenModal] = useState(false);
    const {addNotification} = useNotification();
    const dispath = useAppDispatch();

    const handleAddOption = (option_id: string) => {
        dispath(addProgramToAdditionalInfo({option_id, data: {programId: program.id}}))
            .unwrap()
            .catch(() => addNotification(FAIL_OPERATION, 'error'));
    };

    const handleRemoveOption = (option_id: string) => {
        dispath(removeProgramFmAdditionalInfo({option_id, data: {programId: program.id}}))
            .unwrap()
            .catch(() => addNotification(FAIL_OPERATION, 'error'));
    };

    return (
        <>
            <DataConfigCard
                title="ADDITIONAL INFO FOR LOCATION"
                onAddNew={() => setOpenModal(true)}
            >
                {additional_info.map((option) => (
                    <ToggleChip
                        key={option.id}
                        label={option.title}
                        selected={option.programs.includes(program.id)}
                        onClick={() =>
                            option.programs.includes(program.id)
                                ? handleRemoveOption(option.id)
                                : handleAddOption(option.id)
                        }
                    />
                ))}
            </DataConfigCard>
            {openModal && (
                <AdditionalInfoModal
                    programId={program.id}
                    closeModal={() => setOpenModal(false)}
                />
            )}
        </>
    );
};

export default ProgramAddtionalInfo;
