import {useMemo} from 'react';
import {ICleanUp} from '../models/cleanUp';
import {useProgramsData} from '../store/programs/programsSlice';
import {convertToDms} from '../utils/cordinates_helpers';
import {durationToString} from '../utils/date_helpers';
import {BoardData} from '../components/bloc/DailyReport/CleanUp/CleanUpBoard';
import {useSubstrateOptionsData} from '../store/substrateOptions/substrateOptionSlice';

export const useCleanUpBasicInfo = (cleanUp?: ICleanUp) => {
    const [programs, programsLoaded] = useProgramsData();
    const [substrateOptions, substrateOptionsLoaded] = useSubstrateOptionsData();

    const basicInfoData: BoardData[] = useMemo(() => {
        const program = programs.find((pr) => pr.id === cleanUp?.program);
        const programActivities = program?.activities;
        const team = program?.teams.find((t) => t.id === cleanUp?.team);
        const cleanUpActivity = programActivities?.find((pr) => pr.id === cleanUp?.activity)?.name;

        const substrate = substrateOptions.find((o) => o.id === cleanUp?.substrate);

        /*  coordinates calculation */
        let coordination = '';
        if (cleanUp?.location) {
            coordination = `${convertToDms(+cleanUp.location.longitude, true)}, ${convertToDms(
                +cleanUp.location.latitude,
                false
            )}`;
        }
        /*  end of coordinates calculation */

        /*  cleanUpDuration calculation */

        const cleanUpDuration = durationToString(cleanUp?.duration) || '-';

        /* end of  cleanUpDuration calculation */

        /*  regionData calculation */
        let regionData = [
            cleanUp?.district && cleanUp.district,
            cleanUp?.municipality && cleanUp.municipality,
            cleanUp?.city_island && cleanUp.city_island,
        ]
            .filter((dt) => dt !== null)
            .join(', ');

        regionData = regionData.length > 0 ? regionData : '-';
        /*  end of regionData calculation */

        return [
            {
                label: 'Coordinates',
                info: coordination,
            },
            {
                label: 'Region',
                info: regionData,
            },
            {
                label: 'Clean Up Duration',
                info: cleanUpDuration,
            },
            {
                label: 'Team',
                info: team?.name ?? '-',
            },
            {
                label: 'Number of Participants',
                info: cleanUp?.num_of_participant,
            },
            {
                label: 'Linked Activity',
                info: cleanUpActivity,
            },
            {
                label: 'Spot Length',
                info: cleanUp?.length ? `${cleanUp.length} m` : '-',
            },
            {
                label: 'Spot Area',
                info: cleanUp?.area ? `${cleanUp.area} m^2` : '-',
            },
            {
                label: 'Substrate',
                info: substrate?.title ?? '-',
            },
        ];
    }, [substrateOptions, programs, cleanUp]);

    return [basicInfoData, programsLoaded && substrateOptionsLoaded] as const;
};
