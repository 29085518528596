import {FC} from 'react';
import {View, Text, StyleSheet} from '@react-pdf/renderer';
import Table from './Table';
import {IPDFProject} from '../../../models/pdfReport';

const styles = StyleSheet.create({
    view: {
        paddingTop: '20pt',
    },
    header: {
        fontFamily: 'Manrope',
        fontWeight: 'bold',
        fontSize: 32,
        color: '#002342',
    },
    regions: {
        fontFamily: 'Manrope',
        fontWeight: 'normal',
        fontSize: '12pt',
    },
    tableContainer: {
        marginTop: 14,
    },
});

interface IProps {
    data: IPDFProject;
}

const ProjectTotals: FC<IProps> = ({data}) => {
    const {
        name,
        districts,
        collectedWeight: {total, wasteTypes},
        usedResources,
    } = data;

    return (
        <View style={styles.view}>
            <Text style={styles.header}>{`Project: ${name}`}</Text>
            <Text style={styles.regions}>{districts.join(' | ')}</Text>
            <View style={styles.tableContainer}>
                <Table
                    title="COLLECTED WEIGHT"
                    titleInfo={`${total}kg`}
                    mode="rows"
                    rows={wasteTypes.map((type) => ({title: type.name, info: `${type.weight}kg`}))}
                />
            </View>
            <View style={styles.tableContainer}>
                <Table
                    title="USED RESOURCES"
                    mode="rows"
                    rows={usedResources.map((r) => ({title: r.name, info: `${r.items} items`}))}
                />
            </View>
        </View>
    );
};

export default ProjectTotals;
