import {FC} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {
    newTeam,
    programsError,
    programsIsSubmitting,
    updateTeam,
} from '../../../store/programs/programsSlice';

import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Modal} from '../../UI/Modal';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {Input} from '../../UI/Input';
import {Textarea} from '../../UI/Input/Textarea';
import {ErrorMessage} from '../../UI/ErrorMessage';

import {FIELD_LABELS} from '../../../constants';
import {ITeamForm} from './formModel';
import {ITeamWithUsers} from '../../../models/team';

import {validationSchema, initial} from './formModel';

const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

interface IProps {
    programId: string;
    team?: ITeamWithUsers;
    onClose: () => void;
}

const TeamModal: FC<IProps> = ({onClose, programId, team}) => {
    const defaultValues: ITeamForm = team
        ? {name: team.name, numOfVounteers: team.numOfVounteers, notes: team.notes}
        : initial;

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<ITeamForm>({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    const dispatch = useAppDispatch();
    const isSubmitting = useAppSelector(programsIsSubmitting);
    const serverError = useAppSelector(programsError);

    const onActionClick = (data: ITeamForm) => {
        const payload = {data, programId};

        const action = team
            ? dispatch(updateTeam({...payload, teamId: team.id}))
            : dispatch(newTeam(payload));

        action.unwrap().then(() => onClose());
    };

    return (
        <Modal title={team ? 'Edit Team' : 'New Team'}>
            <ModalBody>
                <Input
                    label="Team Label"
                    placeholder="Type here"
                    required
                    error={!!errors.name}
                    errorMessage={errors.name?.message}
                    {...register('name')}
                />
                <Input
                    label="Number of Volunteers"
                    placeholder="Type here"
                    type="number"
                    error={!!errors.numOfVounteers}
                    errorMessage={errors.numOfVounteers?.message}
                    {...register('numOfVounteers')}
                />
                <Textarea
                    label="Notes"
                    placeholder="Type here"
                    style={{height: '100px'}}
                    error={!!errors.notes}
                    errorMessage={errors.notes?.message}
                    {...register('notes')}
                />
                {serverError && <ErrorMessage message={serverError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleSubmit(onActionClick)}
                    label={team ? SAVE_CHANGES_LABEL : ADD_TO_LIST_LABEL}
                />
                <TextButton type="button" variant="Danger" label={CANCEL_LABEL} onClick={onClose} />
            </ModalActions>
        </Modal>
    );
};

export default TeamModal;
