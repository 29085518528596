import * as Yup from 'yup';
import {FIELD_ERRORS} from '../../../constants';
import {INoCleaningReasonDTO} from '../../../models/noCleaningReason';

export interface INoCleaningReasonForm extends INoCleaningReasonDTO {
    id: string;
}

export const initial: INoCleaningReasonForm = {id: '', name: ''};

export const validationSchema = Yup.object({
    name: Yup.string().required(FIELD_ERRORS.REQUIRED),
});
