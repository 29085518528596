import apiClient from './client';
import {ICleanUp} from '../models/cleanUp';

export const fetchCleanUById = async (id: string): Promise<ICleanUp> => {
    try {
        const response = await apiClient.get<ICleanUp>(`/clean-up/${id}`);
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const fetchCleanUByDate = async (date: string): Promise<ICleanUp[]> => {
    try {
        const response = await apiClient.get<ICleanUp[]>(`/clean-up?date=${date}`);
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const approveCleanUpSpot = async (cleanUpId: string, spotId: string): Promise<ICleanUp> => {
    try {
        const response = await apiClient.post<ICleanUp>(
            `/clean-up/${cleanUpId}/image-spots/${spotId}/approve`
        );
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const unApproveCleanUpSpot = async (
    cleanUpId: string,
    spotId: string
): Promise<ICleanUp> => {
    try {
        const response = await apiClient.post<ICleanUp>(
            `/clean-up/${cleanUpId}/image-spots/${spotId}/unapprove`
        );
        return response.data;
    } catch (e) {
        throw e;
    }
};
