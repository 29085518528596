import {ICollectedWeight} from '../models/cleanUp';
import {useCleanUpCollectedWeight} from './useCleanUpCollectedWeight';

export const useAggregateCollectedWeights = (collected_weights: ICollectedWeight[]) => {
    const aggregatedWeights = collected_weights.reduce((acc: ICollectedWeight[], val) => {
        const index = acc.findIndex((obj) => obj.waste_type_id === val.waste_type_id);

        if (index !== -1) {
            acc[index].wet += val.wet;
            acc[index].dry += val.dry;
        } else {
            acc.push({
                waste_type_id: val.waste_type_id,
                wet: val.wet,
                dry: val.dry,
            });
        }

        return acc;
    }, []);

    return useCleanUpCollectedWeight(aggregatedWeights);
};
