import * as Yup from 'yup';
import {FIELD_ERRORS} from '../../../constants';

export interface IWeatherConditionListTypeForm {
    title: string;
}

export const initial: IWeatherConditionListTypeForm = {title: ''};

export const validationSchema = Yup.object({
    title: Yup.string().required(FIELD_ERRORS.REQUIRED),
});
