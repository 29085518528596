import {FC} from 'react';
import {Link} from 'react-router-dom';
import {Icon} from '../../../components/UI/Icon';
import s from '../index.module.scss';
import {IProgram} from '../../../models/program';

interface IProps {
    program: IProgram;
}

export const ProgramCard: FC<IProps> = ({program}) => {
    return (
        <button className={s.card}>
            <Link to={`/programs/${program.id}`} className={s.link}>
                <div className={s.title}>{program.name}</div>
                <div className={s.image}>
                    <Icon name="ImagePlaceholder" viewbox="0 0 150 150" size="150" />
                </div>
            </Link>
        </button>
    );
};
