import apiClient from './client';
import {IUser, IUserDTO} from '../models/user';

export const getUsers = async (): Promise<IUser[]> => {
    try {
        const response = await apiClient.get<IUser[]>('/users');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createUser = async (data: IUserDTO): Promise<IUser> => {
    try {
        const response = await apiClient.post<IUser>('/users', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const patchUser = async (userId: string, data: Omit<IUserDTO, 'email'>): Promise<IUser> => {
    try {
        const response = await apiClient.patch<IUser>(`/users/${userId}`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeUser = async (userId: string): Promise<void> => {
    try {
        await apiClient.delete(`/users/${userId}`);
    } catch (error) {
        throw error;
    }
};

export const setUserActive = async (userId: string): Promise<void> => {
    try {
        await apiClient.patch(`/users/${userId}/activate`);
    } catch (error) {
        throw error;
    }
};

export const setUserInActive = async (userId: string): Promise<void> => {
    try {
        await apiClient.patch(`/users/${userId}/deactivate`);
    } catch (error) {
        throw error;
    }
};
