import {Outlet} from 'react-router-dom';
import {DATA_CONFIGURATION_LINKS} from '../../assets/utilities/navigation';
import {PageContentWrapper, PageHeader, PageWrapper} from '../../Layout';
import {SecondaryNav} from '../../Navbar/Secondary';

export const DataPageContent = () => (
    <PageWrapper>
        <PageHeader title="Data Configuration" />
        <SecondaryNav routes={DATA_CONFIGURATION_LINKS} />
        <PageContentWrapper>
            <Outlet />
        </PageContentWrapper>
    </PageWrapper>
);
