import {UserItem} from '../UserItem';
import s from '../index.module.scss';
import {FC} from 'react';
import {IUser} from '../../../models/user';

interface IProps {
    users?: IUser[];
    onOpenEditModal: (user: IUser) => void;
    onRemoveUser: (userId: string) => void;
    onUserActivation: (user: IUser) => void;
}

export const UsersList: FC<IProps> = ({users, onOpenEditModal, onRemoveUser, onUserActivation}) => {
    return (
        <div className={s.list}>
            {users ? (
                users.map((user) => (
                    <UserItem
                        onUserActivation={onUserActivation}
                        onRemoveUser={onRemoveUser}
                        onOpenEditModal={onOpenEditModal}
                        key={user.id}
                        user={user}
                    />
                ))
            ) : (
                <div>No users</div>
            )}
        </div>
    );
};
