import ListContainer from '../../../../components/shared/ListContainer';
import {ListItemWithChildren} from '../../../../components/shared/ListItemWithChildren';
import {IWeatherCondition} from '../../../../models/weatherCondition';
import {useState} from 'react';
import WeatherConditionModal from '../../../../components/shared/WeatherConditionModal';
import {useAppDispatch} from '../../../../store/app-hooks';
import {
    clearError,
    deleteWeatherCondition,
    deleteWeatherConditionListTypeOption,
    DeleteWeatherConditionListTypeProps,
} from '../../../../store/weatherCondition/weatherCondition';
import useConfirm from '../../../../hooks/useConfirm';
import {useNotification} from '../../../../store/notification/NotificationContext';
import {FIELD_LABELS} from '../../../../constants';
import WeatherConditionListTypeModal from '../../../../components/shared/WeatherConditionListTypeModal';

type WeatherConditionsTextConfigProps = {
    data: IWeatherCondition[];
};

export const WeatherConditionsTextConfig = ({data}: WeatherConditionsTextConfigProps) => {
    const dispatch = useAppDispatch();
    const {confirm} = useConfirm();
    const {addNotification} = useNotification();

    const [openNewModal, setOpenNewModal] = useState(false);
    const [openOptionModal, setOpenOptionModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [currentWeatherCondition, setCurrentWeatherCondition] = useState<IWeatherCondition>();

    const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION} = FIELD_LABELS;

    // const handleViewArchivedClick = () => {};

    const closeNewModal = () => {
        dispatch(clearError());
        setOpenNewModal(false);
    };

    const handleOpenEditModal = (currWeatherCond: IWeatherCondition) => {
        setOpenEditModal(true);
        setCurrentWeatherCondition(currWeatherCond);
    };

    const closeEditModal = () => {
        dispatch(clearError());
        setOpenEditModal(false);
    };

    const onDeleteWeather = async (weatherId: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result) {
            dispatch(deleteWeatherCondition(weatherId))
                .unwrap()
                .then(() => {
                    addNotification(SUCCESSFUL_DELETION, 'success');
                })
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    const handleOpenOptionModal = (currWeatherCond: IWeatherCondition) => {
        setOpenOptionModal(true);
        setCurrentWeatherCondition(currWeatherCond);
    };

    const closeOptionModal = () => {
        dispatch(clearError());
        setOpenOptionModal(false);
    };

    const handleRemoveWeatherOption = async (weatherId: string, optionId: string) => {
        const data: DeleteWeatherConditionListTypeProps = {
            weatherId,
            optionId,
        };
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result) {
            dispatch(deleteWeatherConditionListTypeOption(data))
                .unwrap()
                .then(() => addNotification(SUCCESSFUL_DELETION, 'success'))
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    return (
        <>
            <ListContainer
                onAddClick={() => setOpenNewModal(true)}
                // onViewArchivedClick={handleViewArchivedClick}
                title="List Input Types"
            >
                {data.map((category) => (
                    <ListItemWithChildren
                        key={category.id}
                        title={category.title}
                        description={`${category.options.length} options`}
                        menuActions={[
                            {label: 'Edit Info', action: () => handleOpenEditModal(category)},
                            {label: 'Add New Item', action: () => handleOpenOptionModal(category)},
                            {label: 'View Archived Items', action: () => {}},
                            {
                                label: 'Remove List Input Type',
                                action: () => onDeleteWeather(category.id),
                            },
                        ]}
                        items={category.options.map((option) => ({
                            ...option,
                            name: option.title,
                        }))}
                        listChildId={category.id}
                        onRemoveChip={handleRemoveWeatherOption}
                    />
                ))}
                {openNewModal && (
                    <WeatherConditionModal closeModal={closeNewModal} isNumberInput={false} />
                )}
                {openEditModal && (
                    <WeatherConditionModal
                        weatherCondition={currentWeatherCondition}
                        closeModal={closeEditModal}
                        isNumberInput={false}
                    />
                )}
                {openOptionModal && (
                    <WeatherConditionListTypeModal
                        weatherId={currentWeatherCondition!.id}
                        closeModal={closeOptionModal}
                    />
                )}
            </ListContainer>
        </>
    );
};
