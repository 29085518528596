import {useMemo} from 'react';
import {BoardData} from '../components/bloc/DailyReport/CleanUp/CleanUpBoard';
import {ICleanUp} from '../models/cleanUp';
import {WeatherTypes} from '../models/weatherCondition';
import {useWeatherConditionsData} from '../store/weatherCondition/weatherCondition';

const useCleanUpWeatherConditions = (cleanUp?: ICleanUp) => {
    const [weatherConditions, weatherConditionsLoaded] = useWeatherConditionsData();

    const weatherConditionData = useMemo(() => {
        return cleanUp?.weather_conditions
            .map((entry) => {
                const condition = weatherConditions.find((c) => c.id === entry.condition_id);

                if (!condition) return null;

                if (condition.type === WeatherTypes.NUMBER) {
                    return {label: condition.title, info: `${entry.value} ${condition.unit}`};
                } else {
                    const option = condition.options.find((o) => o.id === entry.option_id);
                    if (!option) return null;
                    return {label: condition.title, info: `${option?.title}`};
                }
            })
            .filter((data) => !!data) as BoardData[];
    }, [weatherConditions, cleanUp]);

    return [weatherConditionData, weatherConditionsLoaded] as const;
};

export default useCleanUpWeatherConditions;
