export enum ResourceType {
    GENERAL = 'general',
    LITTER_BAG = 'litter_bag',
}

export interface IResource {
    id: string;
    title: string;
    type: ResourceType;
    capacity: string;
    created_at: string;
    updated_at: string;
    programs: string[];
}

export interface ICreateResourceDTO {
    title: string;
    type: ResourceType;
    capacity?: string;
    programId?: string;
}

export interface IUpdateResourceDTO {
    title: string;
    capacity?: string;
}

export interface IAddProgramToResourceDTO {
    programId: string;
}

export interface IRemoveProgramFmResourceDTO extends IAddProgramToResourceDTO {}
