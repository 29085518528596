export interface ILocation {
    latitude: string;
    longitude: string;
}

export interface IGeoInfoItem {
    id: string;
    coordinates: ILocation;
}

export interface IGeoInfo {
    area?: IGeoInfoItem[];
    coastlines?: string[][];
}

export interface ICollectedWeight {
    waste_type_id: string;
    wet: number;
    dry: number;
}

export interface ICollectedItem {
    waste_category_item: string;
    amount: number;
}

export interface ICollectedItems {
    [waste_category: string]: ICollectedItem[];
}

export interface IUsedLitterBag {
    resource: string;
    half: number;
    full: number;
}

export interface IUsedResources {
    resource: string;
    amount: number;
}

export interface IWeatherCondition {
    condition_id: string;
    option_id?: string;
    value?: number;
}

export interface IDuration {
    from: {
        hour: string;
        minute: string;
    };
    to: {
        hour: string;
        minute: string;
    };
}

export interface ISpotItem {
    image_id: string | null;
    image_url: string | null;
}

export interface ISpot {
    id: string;
    before: ISpotItem;
    after: ISpotItem;
    approved: boolean;
}

export interface ICleanUp {
    id: string;
    date: string;
    location: ILocation;
    location_name: string | null;
    program: string;
    activity: string | null;
    team: string | null;
    site_types: string[];
    num_of_participant: string;
    area: number | null;
    length: number | null;
    geo_info: IGeoInfo | null;
    district: string | null;
    duration: IDuration | null;
    municipality: string | null;
    city_island: string | null;
    no_litter_found: boolean | null;
    collected_weights: ICollectedWeight[];
    collected_items: ICollectedItems;
    used_liter_bags: IUsedLitterBag[];
    used_general_resources: IUsedResources[];
    additional_location_info: string[];
    weather_conditions: IWeatherCondition[];
    images: ISpot[];
    notes: string | null;
    substrate: string | null;

    created_at: string;
    created_by: string;
    updated_at: string;
}

export const isResourceGeneral = (
    resource: IUsedResources | IUsedLitterBag
): resource is IUsedResources => (resource as IUsedResources).amount !== undefined;
