import ListContainer from '../../../../components/shared/ListContainer';
import ListItem from '../../../../components/shared/ListItem';
import {
    clearErrorMessage,
    deleteSubstrateOption,
    useSubstrateOptionsData,
} from '../../../../store/substrateOptions/substrateOptionSlice';
import {useState} from 'react';
import SubstrateOptionModal from '../../../../components/shared/SubstrateOptionsModal';
import {useAppDispatch} from '../../../../store/app-hooks';
import {ISubstrateOption} from '../../../../models/substrateOption';
import useConfirm from '../../../../hooks/useConfirm';
import {useNotification} from '../../../../store/notification/NotificationContext';
import {FIELD_LABELS} from '../../../../constants';

export const SubstrateOptionsConfig = () => {
    const dispatch = useAppDispatch();
    const {confirm} = useConfirm();
    const {addNotification} = useNotification();

    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const [currentSubstrate, setCurrentSubstrate] = useState<ISubstrateOption>();
    const [substrateOptions, isLoaded] = useSubstrateOptionsData();

    const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION} = FIELD_LABELS;

    const closeAddModal = () => {
        dispatch(clearErrorMessage());
        setShowAddModal(false);
    };

    const onEditOption = (subOption: ISubstrateOption) => {
        setShowEditModal(true);
        setCurrentSubstrate(subOption);
    };

    const onCloseEditModal = () => {
        dispatch(clearErrorMessage());
        setShowEditModal(false);
    };

    const onDelete = async (id: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result) {
            dispatch(deleteSubstrateOption(id))
                .unwrap()
                .then(() => addNotification(SUCCESSFUL_DELETION, 'success'))
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    return isLoaded ? (
        <>
            <ListContainer
                onAddClick={() => setShowAddModal(true)}
                // onViewArchivedClick={() => {}}
                title="Substrate Options"
            >
                {substrateOptions.map((subOption) => (
                    <ListItem
                        key={subOption.id}
                        title={subOption.title}
                        onDeleteClick={() => onDelete(subOption.id)}
                        onEditClick={() => onEditOption(subOption)}
                    />
                ))}
            </ListContainer>
            {showAddModal && <SubstrateOptionModal closeModal={closeAddModal} />}
            {showEditModal && (
                <SubstrateOptionModal substrate={currentSubstrate} closeModal={onCloseEditModal} />
            )}
        </>
    ) : (
        <div>Waiting for data to load...</div>
    );
};
