import { FC, InputHTMLAttributes, useState } from 'react';
import { Icon } from '../../Icon';
import s from '../index.module.scss';

interface ISearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
}

export const SearchInput: FC<ISearchInputProps> = ({ name, ...rest }) => {
  // Change icon color when focused
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <div
      className={`${s.container} ${s.search} ${rest.disabled && s.disabled}`}
    >
      <Icon
        name="MagnifyingGlass"
        size="20"
        variant={focused ? 'onBackground' : 'onDisabled'}
      />
      <input {...rest} onFocus={onFocus} onBlur={onBlur} />
    </div>
  );
};
