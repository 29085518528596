export const getArrayIndex = <T extends {id: string}>(array: T[], id: string) =>
    array.findIndex((item) => item.id === id);

export const removeDuplicates = <T extends {id: string}>(array: T[]) => {
    const uniqueIds: string[] = [];

    return array.filter((item) => {
        const isDuplicate = uniqueIds.includes(item.id);

        if (!isDuplicate) {
            uniqueIds.push(item.id);
            return true;
        }

        return false;
    });
};
