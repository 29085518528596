import * as Yup from 'yup';
import {ISiteTypeDTO} from '../../../models/siteType';
import {FIELD_ERRORS} from '../../../constants';

export interface ISiteTypeForm extends ISiteTypeDTO {}

export const initial: ISiteTypeForm = {title: '', id: ''};

export const validationSchema = Yup.object({
    title: Yup.string().required(FIELD_ERRORS.REQUIRED),
});
