import s from './index.module.scss';
import {IconButton} from '../../UI/Button/Icon';
import {ActivityStatus} from '../../../models/activity';
import ListItemDetails from './ListItemDetails';
import ListItemDescription from './ListItemDescription';
import ListItemStatus from './ListItemStatus';

type ListItemProps = {
    title: string;
    details?: string[];
    description?: string;
    status?: ActivityStatus;
    onEditClick: VoidFunction;
    onDeleteClick: VoidFunction;
};

const ListItem = ({
    title,
    details,
    description,
    status,
    onDeleteClick,
    onEditClick,
}: ListItemProps) => {
    return (
        <div className={s.listItem}>
            <div className={s.info}>
                <div>{title}</div>
                {details && <ListItemDetails details={details} />}
                {description && <ListItemDescription description={description} />}
                {status && <ListItemStatus status={status} />}
            </div>
            <div className={s.actions}>
                <IconButton onClick={onEditClick} icon="Edit" variant="Secondary" />
                <IconButton onClick={onDeleteClick} icon="Close" variant="Secondary" />
            </div>
        </div>
    );
};

export default ListItem;
