import {FC, PropsWithChildren} from 'react';

import {Navigate, useLocation} from 'react-router-dom';
import {Role} from '../../models/user';
import RedirectToLogin from '../../routes/RedirectToLogin';
import {useAppSelector} from '../../store/app-hooks';
import {authUser} from '../../store/auth/authSlice';

interface IProps extends PropsWithChildren {
    roles: Role[];
    redirectTo?: string;
}

const PrivateRoute: FC<IProps> = ({children, roles, redirectTo}) => {
    const currentUser = useAppSelector(authUser);

    const location = useLocation();

    if (!currentUser || !roles.includes(currentUser.role)) {
        return !redirectTo ? <RedirectToLogin from={location} /> : <Navigate to={redirectTo} />;
    }

    return <>{children}</>;
};

export default PrivateRoute;
