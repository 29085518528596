import {Modal} from '../../UI/Modal';
import {Button} from '../../UI/Button';
import {TextButton} from '../../UI/Button/Text';
import {Input} from '../../UI/Input';

import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {initial, ISubstrateOptionForm, validationSchema} from './formModel';
import {ISubstrateOption} from '../../../models/substrateOption';
import {
    newSubstrateOption,
    substrateOptionsError,
    substrateOptionsIsSubmitting,
    updateSubstrateOption,
} from '../../../store/substrateOptions/substrateOptionSlice';
import {FIELD_LABELS} from '../../../constants';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';

type SubstrateOptionModalProps = {
    substrate?: ISubstrateOption;
    closeModal: VoidFunction;
    programId?: string;
};

const SubstrateOptionModal = ({substrate, programId, closeModal}: SubstrateOptionModalProps) => {
    const dispatch = useAppDispatch();
    const errorMsg = useAppSelector(substrateOptionsError);

    const initialFormValue = substrate ? {title: substrate.title} : initial;

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<ISubstrateOptionForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialFormValue,
    });

    const isSubmitting = useAppSelector(substrateOptionsIsSubmitting);

    const modalTitle = !substrate ? 'New Substrate Type' : 'Edit Substrate Type';
    const actionButtonTitle = !substrate ? ADD_TO_LIST_LABEL : SAVE_CHANGES_LABEL;

    const onActionClick = (formValues: ISubstrateOptionForm) => {
        if (!substrate) {
            dispatch(newSubstrateOption({...formValues, ...(programId && {programId})}))
                .unwrap()
                .then(() => closeModal());
        } else {
            dispatch(updateSubstrateOption({option_id: substrate.id, data: {...formValues}}))
                .unwrap()
                .then(() => closeModal());
        }
    };

    return (
        <Modal title={modalTitle}>
            <ModalBody>
                <Input
                    label="Name"
                    placeholder="Type here"
                    required
                    error={!!errors.title}
                    errorMessage={errors.title?.message}
                    {...register('title')}
                />
                {errorMsg && <ErrorMessage message={errorMsg} />}
            </ModalBody>
            <ModalActions>
                <Button
                    type="submit"
                    onClick={handleSubmit(onActionClick)}
                    disabled={isSubmitting}
                    label={actionButtonTitle}
                />
                <TextButton variant="Danger" label={CANCEL_LABEL} onClick={closeModal} />
            </ModalActions>
        </Modal>
    );
};

export default SubstrateOptionModal;
