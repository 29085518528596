import {FC} from 'react';
import c from './StatusChip.module.scss';

export type StatusChipType = 'success' | 'warning';

interface IProps {
    label: string;
    type: StatusChipType;
}

const StatusChip: FC<IProps> = ({label, type}) => {
    return <div className={`${c.chip} ${c[`chip--${type}`]}`}>{label}</div>;
};

export default StatusChip;
