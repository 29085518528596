import {FC} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import useProgramFromParams from '../../hooks/useProgramFromParams';
import {useAuthData} from '../../store/auth/authSlice';
import {PROGRAM_CONFIGURATION_LINKS} from '../../assets/utilities/navigation';
import {IconButton} from '../../components/UI/Button/Icon';
import {PageContentWrapper, PageWrapper} from '../../Layout';
import {SecondaryNav} from '../../Navbar/Secondary';
import {Role} from '../../models/user';

import s from './index.module.scss';

export const ProgramConfigurationContent: FC = () => {
    const navigate = useNavigate();
    const [user] = useAuthData();
    const program = useProgramFromParams();

    return (
        <PageWrapper>
            <div className={s.header}>
                <IconButton icon="ChevronLeft" onClick={() => navigate('/')} />
                {program && program.name}
            </div>
            <SecondaryNav
                routes={
                    user?.role !== Role.director
                        ? PROGRAM_CONFIGURATION_LINKS.filter((l) => l.title !== 'COSTS')
                        : PROGRAM_CONFIGURATION_LINKS
                }
            />
            <PageContentWrapper>
                <Outlet />
            </PageContentWrapper>
        </PageWrapper>
    );
};
