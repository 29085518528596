import {format} from 'date-fns';
import {IDuration} from '../models/cleanUp';

export const convertDateToString = (currentDate: Date): string =>
    format(new Date(currentDate), 'dd/MM/yyyy');

export const setDatePickerValue = (currentDate: Date): string =>
    new Date(currentDate).toLocaleDateString('sv');

export const timeFormatter = (currHour: string, currMinutes: string) => {
    let hour = currHour;
    let minutes = currMinutes;

    if (currHour < '10') hour = '0' + currHour;

    if (currMinutes < '10') minutes = '0' + currMinutes;

    return `${hour}.${minutes}`;
};
export const dateToMonthString = (date: Date): string => format(date, 'yyyy-MM');
export const dateToString = (date: Date): string => format(date, 'yyyy-MM-dd');
export const durationToString = (duration?: IDuration | null) => {
    if (duration) {
        const fromTime = timeFormatter(duration?.from.hour, duration?.from.minute);
        const untilTime = timeFormatter(duration?.to.hour, duration?.to.minute);

        return `${fromTime} -  ${untilTime}`;
    }
};
