import ListItem from '../../../components/shared/ListItem';
import {FAB} from '../../../components/UI/Button/FAB';
import {FABWrapper} from '../../../Layout';
import s from './index.module.scss';
import useProgramFromParams from '../../../hooks/useProgramFromParams';
import {useState} from 'react';
import CostModal from '../../../components/shared/CostModal';
import {useAppDispatch} from '../../../store/app-hooks';
import {clearError, deleteCost} from '../../../store/programs/programsSlice';
import {ICost} from '../../../models/cost';
import {ICostForm} from '../../../components/shared/CostModal/formModel';
import {convertDateToString, setDatePickerValue} from '../../../utils/date_helpers';
import useConfirm from '../../../hooks/useConfirm';
import {useNotification} from '../../../store/notification/NotificationContext';
import {FIELD_LABELS} from '../../../constants';

export const ProgramCosts = () => {
    const dispatch = useAppDispatch();
    const {confirm} = useConfirm();
    const {addNotification} = useNotification();

    const program = useProgramFromParams();

    const [openNewModal, setOpenNewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [selectedCost, setSelectedCost] = useState<ICostForm>();

    const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION} = FIELD_LABELS;

    const handleCloseNewModal = () => {
        dispatch(clearError());
        setOpenNewModal(false);
    };

    const handleCloseEditModal = () => {
        dispatch(clearError());
        setOpenEditModal(false);
    };

    const handleEditModal = (currentCost: ICost, activityId: string) => {
        const selectedCostItem = {
            ...currentCost,
            activityId: activityId ?? '',
            date: setDatePickerValue(currentCost.date),
        };

        setOpenEditModal(true);
        setSelectedCost(selectedCostItem);
    };

    const onDeleteCost = async (costId: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        const payLoad = {
            costId,
            programId: program!.id,
        };

        if (result) {
            dispatch(deleteCost(payLoad))
                .unwrap()
                .then(() => {
                    addNotification(SUCCESSFUL_DELETION, 'success');
                })
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    return (
        <>
            <div className={s.container}>
                {program?.costs.map((costItem) => {
                    const {activity, date, name, amount, id} = costItem;

                    const costType = activity ? activity.name : 'General';
                    const costDate = convertDateToString(date);

                    return (
                        <ListItem
                            key={id}
                            title={name}
                            details={[`${costDate}`, `Amount: ${amount}€`, `${costType} Cost`]}
                            onDeleteClick={() => onDeleteCost(id)}
                            onEditClick={() => handleEditModal(costItem, activity?.id)}
                        />
                    );
                })}
            </div>
            <FABWrapper>
                <FAB onClick={() => setOpenNewModal(true)} />
            </FABWrapper>
            {openNewModal && <CostModal program={program!} closeModal={handleCloseNewModal} />}
            {openEditModal && (
                <CostModal
                    program={program!}
                    cost={selectedCost}
                    closeModal={handleCloseEditModal}
                />
            )}
        </>
    );
};
