import ListContainer from '../../../../components/shared/ListContainer';
import ListItem from '../../../../components/shared/ListItem';
import {IWeatherCondition} from '../../../../models/weatherCondition';
import {useState} from 'react';
import WeatherConditionModal from '../../../../components/shared/WeatherConditionModal';
import {useAppDispatch} from '../../../../store/app-hooks';
import {
    clearError,
    deleteWeatherCondition,
} from '../../../../store/weatherCondition/weatherCondition';
import useConfirm from '../../../../hooks/useConfirm';
import {useNotification} from '../../../../store/notification/NotificationContext';
import {FIELD_LABELS} from '../../../../constants';

type WeatherConditionsNumericalConfigProps = {
    data: IWeatherCondition[];
};

export const WeatherConditionsNumericalConfig = ({data}: WeatherConditionsNumericalConfigProps) => {
    const dispatch = useAppDispatch();
    const {confirm} = useConfirm();
    const {addNotification} = useNotification();

    const [openNewModal, setOpenNewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [currentWeatherCondition, setCurrentWeatherCondition] = useState<IWeatherCondition>();

    const {SUCCESSFUL_DELETION, FAIL_DELETION, DELETE_OBJECT, MODAL_CONFIRMATION} = FIELD_LABELS;

    // const handleViewArchivedClick = () => {};

    const closeNewModal = () => {
        dispatch(clearError());
        setOpenNewModal(false);
    };

    const handleOpenEditModal = (currUser: IWeatherCondition) => {
        setOpenEditModal(true);
        setCurrentWeatherCondition(currUser);
    };

    const closeEditModal = () => {
        dispatch(clearError());
        setOpenEditModal(false);
    };

    const onDeleteWeatherCondition = async (weatherId: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);

        if (result) {
            dispatch(deleteWeatherCondition(weatherId))
                .unwrap()
                .then(() => {
                    addNotification(SUCCESSFUL_DELETION, 'success');
                })
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    return (
        <>
            <ListContainer
                onAddClick={() => setOpenNewModal(true)}
                // onViewArchivedClick={handleViewArchivedClick}
                title="Numerical Input Types"
            >
                {data.map((condition) => (
                    <ListItem
                        key={condition.id}
                        title={condition.title}
                        description={`unit: ${condition.unit}`}
                        onDeleteClick={() => onDeleteWeatherCondition(condition.id)}
                        onEditClick={() => handleOpenEditModal(condition)}
                    />
                ))}
                {openNewModal && <WeatherConditionModal closeModal={closeNewModal} isNumberInput />}
                {openEditModal && (
                    <WeatherConditionModal
                        weatherCondition={currentWeatherCondition}
                        closeModal={closeEditModal}
                        isNumberInput
                    />
                )}
            </ListContainer>
        </>
    );
};
