import {FC} from 'react';
import {View, Text, StyleSheet} from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        marginTop: 24,
    },
    header: {
        fontFamily: 'Manrope',
        fontWeight: 'bold',
        fontSize: 14,
    },
    notes: {
        fontFamily: 'Manrope',
        fontSize: 12,
        textAlign: 'justify',
    },
});

interface IProps {
    notes: string | null;
}

const LocationsNotes: FC<IProps> = ({notes}) => {
    return (
        <View style={styles.container}>
            <Text style={styles.header}>NOTES</Text>
            <Text style={styles.notes}>{notes || '-'}</Text>
        </View>
    );
};

export default LocationsNotes;
