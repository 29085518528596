import {IActivityForm, initial, validationSchema} from './formModel';
import {Modal} from '../../UI/Modal';
import {ModalActions, ModalBody} from '../../UI/Modal/ModalBuildingBlocks';
import {Input} from '../../UI/Input';
import {Controller, useForm} from 'react-hook-form';
import {Dropdown} from '../../UI/Input/Dropdown';
import {ErrorMessage} from '../../UI/ErrorMessage';
import {yupResolver} from '@hookform/resolvers/yup';
import {useAppDispatch, useAppSelector} from '../../../store/app-hooks';
import {
    newActivity,
    programsError,
    programsIsSubmitting,
    updateActivity,
} from '../../../store/programs/programsSlice';
import {Button} from '../../UI/Button';
import {ACTIVITY_OPTIONS, FIELD_LABELS} from '../../../constants';
import {TextButton} from '../../UI/Button/Text';

type ActivityModalProps = {
    activity?: IActivityForm;
    programId: string;
    closeModal: () => void;
};

const ActivityModal = ({activity, programId, closeModal}: ActivityModalProps) => {
    const dispatch = useAppDispatch();

    const serverError = useAppSelector(programsError);
    const isSubmitting = useAppSelector(programsIsSubmitting);

    const {ADD_TO_LIST_LABEL, CANCEL_LABEL, SAVE_CHANGES_LABEL} = FIELD_LABELS;

    const modalTitle = !activity ? 'New Activity' : 'Edit Activity';
    const actionButtonTitle = !activity ? ADD_TO_LIST_LABEL : SAVE_CHANGES_LABEL;

    const initialFormValues = activity ? activity : initial;

    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<IActivityForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: initialFormValues,
    });

    const onActionClick = (formData: IActivityForm) => {
        const newPayLoad = {
            data: {
                name: formData.name,
                status: formData.status!,
            },
            programId,
        };

        const action = !activity
            ? newActivity(newPayLoad)
            : updateActivity({
                  ...newPayLoad,
                  activityId: activity.id,
              });

        dispatch(action)
            .unwrap()
            .then(() => closeModal())
            .catch(({message}) => console.log(message));
    };

    return (
        <Modal title={modalTitle}>
            <ModalBody>
                <Input
                    label="Name"
                    placeholder="Type here"
                    required
                    error={!!errors.name}
                    errorMessage={errors.name?.message}
                    {...register('name')}
                />
                <Controller
                    name="status"
                    control={control}
                    render={({field}) => (
                        <Dropdown
                            {...field}
                            value={initialFormValues.status}
                            error={!!errors.status}
                            errorMessage={errors.status?.message}
                            label="Status"
                            required
                            options={ACTIVITY_OPTIONS}
                        />
                    )}
                />
                {serverError && <ErrorMessage message={serverError} />}
            </ModalBody>
            <ModalActions>
                <Button
                    disabled={isSubmitting}
                    onClick={handleSubmit(onActionClick)}
                    label={actionButtonTitle}
                />
                <TextButton
                    type="button"
                    variant="Danger"
                    label={CANCEL_LABEL}
                    onClick={() => closeModal()}
                />
            </ModalActions>
        </Modal>
    );
};

export default ActivityModal;
