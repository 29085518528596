import {FC} from 'react';
import {View, Text, StyleSheet, Image} from '@react-pdf/renderer';
import {ISpot} from '../../../models/cleanUp';

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: '#ccc',
        display: 'flex',
        flexDirection: 'column',
        paddingHorizontal: 4,
        paddingVertical: 4,
        alignItems: 'stretch',
    },
    images: {
        display: 'flex',
        flexDirection: 'row',
        gap: 3,
    },
    image: {
        width: 60,
        height: 60,
    },
    text: {
        fontFamily: 'Manrope',
        fontWeight: 'semibold',
        fontSize: 9,
    },
    labels: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
});

interface IProps {
    spot: ISpot;
}

const ImageSpot: FC<IProps> = ({spot}) => {
    return (
        <View style={styles.container} wrap={false}>
            <Text style={styles.text} fixed>
                Spot 1
            </Text>
            <View style={styles.images}>
                <Image
                    style={styles.image}
                    src={spot.before.image_url || '/images/placeholder.jpg'}
                />
                <Image
                    style={styles.image}
                    src={spot.after.image_url || '/images/placeholder.jpg'}
                />
            </View>
            <View style={styles.labels}>
                <Text style={styles.text}>Before</Text>
                <Text style={styles.text}>After</Text>
            </View>
        </View>
    );
};

export default ImageSpot;
