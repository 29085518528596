import {useState} from 'react';
import {useAppDispatch} from '../../store/app-hooks';
import useConfirm from '../../hooks/useConfirm';
import {useNotification} from '../../store/notification/NotificationContext';
import {useAuthData} from '../../store/auth/authSlice';
import {
    activateUser,
    clearError,
    deActivateUser,
    deleteUser,
    useUserData,
} from '../../store/user/userSlice';
import {IUser, Role} from '../../models/user';

import {FAB} from '../../components/UI/Button/FAB';
import {Dropdown} from '../../components/UI/Input/Dropdown';
import {SearchInput} from '../../components/UI/Input/Search';
import {FABWrapper, PageContentWrapper, PageHeader, PageWrapper} from '../../Layout';
import {UsersList} from './UsersList';
import UserModal from '../../components/shared/UserModal';

import {DIRECTOR_USERS_MENU, ADMIN_USERS_MENU, FIELD_LABELS} from '../../constants';

import s from './index.module.scss';

export const UsersPage = () => {
    const dispatch = useAppDispatch();
    const {confirm} = useConfirm();
    const {addNotification} = useNotification();
    const [authUser] = useAuthData();

    const [users, usersLoaded] = useUserData();
    const [openNewModal, setOpenNewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const [currentUser, setCurrentUser] = useState<IUser>();
    const [userCategory, setUserCategory] = useState('all');
    const [userInput, setUserInput] = useState('');

    const {
        SUCCESSFUL_DELETION,
        FAIL_DELETION,
        DELETE_OBJECT,
        MODAL_CONFIRMATION,
        SUCCESSFUL_OPERATION,
        FAIL_OPERATION,
    } = FIELD_LABELS;

    const closeAddModal = () => {
        dispatch(clearError());
        setOpenNewModal(false);
    };

    const handleOpenEditModal = (currUser: IUser) => {
        setOpenEditModal(true);
        setCurrentUser(currUser);
    };

    const closeEditModal = () => {
        dispatch(clearError());
        setOpenEditModal(false);
    };

    const onRemoveUser = async (userId: string) => {
        const result = await confirm(MODAL_CONFIRMATION, DELETE_OBJECT);
        if (result) {
            dispatch(deleteUser(userId))
                .unwrap()
                .then(() => addNotification(SUCCESSFUL_DELETION, 'success'))
                .catch(() => addNotification(FAIL_DELETION, 'error'));
        }
    };

    const handleUserStatusActivation = (currentUser: IUser) => {
        const {id, is_active} = currentUser;
        const action = is_active ? deActivateUser : activateUser;

        dispatch(action(id))
            .unwrap()
            .then(() => addNotification(SUCCESSFUL_OPERATION, 'success'))
            .catch(() => addNotification(FAIL_OPERATION, 'error'));
    };

    const searchTerm = new RegExp(userInput, 'i');

    const filteredUsers = users
        .filter((item) => {
            if (userCategory === 'all') return true;
            if (userCategory === 'deactivated') return item.is_active === false;
            else return item.role === userCategory;
        })
        .filter((item) =>
            userInput === ''
                ? true
                : searchTerm.test(item.first_name) || searchTerm.test(item.last_name)
        );

    return usersLoaded ? (
        <PageWrapper>
            <PageHeader title="Users" />

            <PageContentWrapper>
                <div className={s.container}>
                    <div className={s.inputs}>
                        <SearchInput
                            onChange={(e) => setUserInput(e.target.value)}
                            placeholder="Search user"
                        />
                        <Dropdown
                            onChange={setUserCategory}
                            name="select"
                            options={
                                authUser?.role === Role.director
                                    ? DIRECTOR_USERS_MENU
                                    : ADMIN_USERS_MENU
                            }
                        />
                    </div>
                    <UsersList
                        users={filteredUsers}
                        onRemoveUser={onRemoveUser}
                        onOpenEditModal={handleOpenEditModal}
                        onUserActivation={handleUserStatusActivation}
                    />
                </div>

                <FABWrapper>
                    <FAB onClick={() => setOpenNewModal(true)} />
                </FABWrapper>
            </PageContentWrapper>
            {openNewModal && <UserModal closeModal={closeAddModal} />}
            {openEditModal && <UserModal user={currentUser} closeModal={closeEditModal} />}
        </PageWrapper>
    ) : (
        <div>Loading users...</div>
    );
};
