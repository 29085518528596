import {useMemo} from 'react';
import {BoardData} from '../components/bloc/DailyReport/CleanUp/CleanUpBoard';
import {IUsedLitterBag} from '../models/cleanUp';
import {useResources} from '../store/resource/resource';

export const useCleanUpLitterBags = (cleanUpLitterBags?: IUsedLitterBag[]) => {
    const [resources, loaded] = useResources();

    const totalResourcesData: BoardData[] = useMemo(() => {
        const totalLitterBags =
            cleanUpLitterBags?.reduce((acc, curr) => acc + (curr.full + curr.half), 0) ?? 0;

        const currentLitterBags = cleanUpLitterBags?.map((res) => ({
            label: resources.find((r) => r.id === res.resource)?.title,
            info: `${res.full + res.half}`,
        }));

        return [
            {
                label: 'Total',
                info: `${totalLitterBags}`,
            },
            ...(currentLitterBags ? currentLitterBags : []),
        ];
    }, [resources, cleanUpLitterBags]);

    return [totalResourcesData, loaded] as const;
};
